import React from "react";
import styled from "styled-components";
import AsyncSelect from "react-select/async";
import { DefaultSelectStyle } from "src/Modules/Core/Components/Form/Select/DefaultSelectStyle";
import axios from "axios";
import countries from "src/Modules/Shared/Components/Temp/countries";
import { components, OptionProps } from "react-select";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import { Option } from "src/Modules/Core/Components/Form/Select/SelectComponents";
import AccountProfileAvatar from "src/Modules/Shared/Components/Temp/AccountProfileAvatar";

const Container = styled.div`
  width: 100%;
  min-height: 48px;
  height: fit-content;

  .react-select__control {
    min-height: 48px;
    height: fit-content;
  }

  .react-select__value-container {
    flex-wrap: wrap;
    height: fit-content;
    display: flex;
  }

  div[class$="container"] {
    height: fit-content;
  }

  .react-select__container {
    height: fit-content;
    background: red;
    margin: 20px;
  }
`;

const CustomOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const CompanyName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;
const CustomOption = (props: OptionProps) => {
  let data = props.data;

  return (
    <components.Option {...props}>
      <CustomOptionContainer>
        <AccountProfileAvatar
          name={data.firstName}
          profilePictureUrl={data.publicProfilePictureUrl}
        />
        <CompanyName>
          {data.firstName} {data.lastName}
        </CompanyName>
      </CustomOptionContainer>
    </components.Option>
  );
};

const loadOptions = (
  inputValue: string,
  callback: (options: any[]) => void
) => {
  axios
    .get("/api/backoffice/v1/rep-search", { params: { q: inputValue } })
    .then((response) => {
      let data = response.data;
      if (Array.isArray(data)) {
        let options = data.map((i) => {
          return { ...i, value: i.id, label: i.firstName + " " + i.lastName };
        });
        callback(options);
      }
    });
};

const RepresentativeSelectField: React.FC = (props) => {
  return (
    <Container>
      <AsyncSelect
        components={{ Option: CustomOption }}
        styles={DefaultSelectStyle}
        loadOptions={loadOptions}
        {...props.input}
      />
    </Container>
  );
};

export default RepresentativeSelectField;
