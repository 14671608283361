import React, { ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div``;

const Label = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 8px;
`;

const Value = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  gap: 8px;

  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    height: 26px;

    background: #f8faff;
    border-radius: 6px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #105cf7;
  }
`;

type Props = {
  label: string;
  value: [];
  icon: ReactNode;
};

const markets = [
  { label: "Analytics", value: "Analytics" },
  {
    label: "Finance and Accounting",
    value: "Finance and Accounting",
  },
  { label: "Human Resources", value: "Human Resources" },
  { label: "Infrastructure", value: "Infrastructure" },
  { label: "Legal", value: "Legal" },
  { label: "Office Management", value: "Office Management" },
  { label: "Operations", value: "Operations" },
  { label: "Productivity", value: "Productivity" },
  {
    label: "Recruiting and Talent",
    value: "Recruiting and Talent",
  },
  { label: "Retail", value: "Retail" },
  { label: "Sales & Marketing", value: "Sales & Marketing" },
  { label: "Security", value: "Security" },
  {
    label: "Supply Chain & logistics",
    value: "Supply Chain & logistics",
  },
  { label: "Education", value: "Education" },
  {
    label: "Apparel and Cosmetics",
    value: "Apparel and Cosmetics",
  },
  {
    label: "Consumer Electronics",
    value: "Consumer Electronics",
  },
  { label: "Content", value: "Content" },
  { label: "Food and Beverage", value: "Food and Beverage" },
  { label: "Gaming", value: "Gaming" },
  { label: "Home and Personal", value: "Home and Personal" },
  {
    label: "Job and Career Services",
    value: "Job and Career Services",
  },
  { label: "Social Media", value: "Social Media" },
  { label: "Transportation", value: "Transportation" },
  { label: "Travel", value: "Travel" },
  { label: "VR / AR", value: "VR / AR" },
  { label: "Asset Management", value: "Asset Management" },
  { label: "Banking", value: "Banking" },
  { label: "Consumer Finance", value: "Consumer Finance" },
  { label: "Credit & lending", value: "Credit & lending" },
  { label: "Insurance", value: "Insurance" },
  { label: "Payments", value: "Payments" },
  {
    label: "Health and Wellness",
    value: "Health and Wellness",
  },
  { label: "Diagnostics", value: "Diagnostics" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Construction", value: "Construction" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Agriculture", value: "Agriculture" },
  { label: "Automotive", value: "Automotive" },
  {
    label: "Aviation and Space",
    value: "Aviation and Space",
  },
  { label: "Climate", value: "Climate" },
  { label: "Drones", value: "Drones" },
  { label: "Energy", value: "Energy" },
  {
    label: "Manufacturing & Robotics",
    value: "Manufacturing & Robotics",
  },
  {
    label: "Sports & Entertainment",
    value: "Sports & Entertainment",
  },
  {
    label: "Crypto / Blockchain",
    value: "Crypto / Blockchain",
  },
  { label: "Big Data", value: "Big Data" },
  { label: "Collaboration", value: "Collaboration" },
  { label: "Developer tools", value: "Developer tools" },
  { label: "Communication", value: "Communication" },
  { label: "On-demand", value: "On-demand" },
  {
    label: "Artificial Intelligence (AI)",
    value: "Artificial Intelligence (AI)",
  },
  { label: "SEO", value: "SEO" },
  {
    label: "Business Intelligence",
    value: "Business Intelligence",
  },
  { label: "Digital media", value: "Digital media" },
];

const CompanyMarketDetail: React.FC<Props> = (props) => {
  if (props.value == undefined) {
    return null;
  }

  if (props.value.length == 0) {
    return null;
  }

  if (props.value.length == 1 && props.value[0] == null) {
    return null;
  }

  return (
    <Container>
      <Label>{props.label}</Label>
      <Value>
        {props.value.map((i) => {
          return (
            <span>{markets.find((option) => i == option.value)?.label}</span>
          );
        })}
      </Value>
    </Container>
  );
};

export default CompanyMarketDetail;
