import React from "react";
import AppLayout from "src/Modules/Shared/Components/Layout/AppLayout";
import { Route, Routes } from "react-router-dom";
import { sidebarLinkListStyle } from "src/Modules/Shared/Components/Layout/sidebar.css";
import SidebarNavLink from "src/Modules/Shared/Components/Layout/SidebarNavLink";
import CustomerJobs from "src/Modules/Admin/Components/Customers/CustomerJobs";
import JobShortList from "src/Modules/Admin/Components/Pages/JobShortList";
import OverviewPage from "src/Modules/Admin/Components/Pages/Overview/OverviewPage";
import CompaniesList from "src/Modules/Admin/Components/Pages/Companies/CompaniesList";
import CandidateProfilePage from "src/Modules/Customer/Pages/CandidateProfilePage";
import LogoutSectionSidebar from "src/Modules/Customer/Components/LogoutSectionSidebar";
import InviteCodesPage from "src/Modules/Admin/Pages/InviteCodes/InviteCodesPage";
import User2FillIcon from "remixicon-react/User2FillIcon";
import OrganizationPage from "src/Modules/Admin/Pages/Organization/OrganizationPage";
import OrganizationRepsListPage from "src/Modules/Admin/Pages/OrganizationReps/OrganizationRepsListPage";
import TeamPage from "src/Modules/Admin/Pages/Organization/TeamPage";
import JobsListPage from "src/Modules/Admin/Pages/Jobs/JobsListPage";
import SingleJobEditPage from "src/Modules/Admin/Pages/Jobs/SingleJobEditPage";
import JobView from "src/Modules/Customer/Pages/Jobs/JobView";
import AccountsList from "src/Modules/Admin/Components/Pages/Accounts/AccountsList";
import PageNotFound from "src/Modules/Shared/Pages/PageNotFound";
import HiresPage from "src/Modules/Admin/Pages/Organization/HiresPage";
import HiresSinglePage from "src/Modules/Admin/Pages/Organization/HiresSinglePage";

const Content = () => {
  return (
    <Routes>
      <Route path="/" element={<OverviewPage />} />
      <Route path="/customers" element={<CompaniesList />} />
      <Route path="/customers/:jobId/jobs" element={<CustomerJobs />} />
      <Route path="/jobs/:jobId" element={<SingleJobEditPage />} />
      <Route path="/jobs/:jobId/shortlist" element={<JobShortList />}></Route>
      <Route path="/jobs/:jobId/preview" element={<JobView />}></Route>
      <Route path="/jobs" element={<JobsListPage />} />
      <Route path={"/accounts"} element={<AccountsList />} />
      <Route path="/organizations/:id" element={<OrganizationPage />}>
        <Route path="/organizations/:id/team" element={<TeamPage />} />
        <Route path="/organizations/:id/jobs" element={<></>} />

        <Route path="/organizations/:id/hires" element={<HiresPage />} />
        <Route
          path="/organizations/:id/hires/:hireId"
          element={<HiresPage />}
        />
      </Route>
      <Route path="/invite-codes" element={<InviteCodesPage />} />
      <Route path="/organization-reps" element={<OrganizationRepsListPage />} />
      <Route
        path="/candidates/:candidateId"
        element={<CandidateProfilePage />}
      />
      <Route path={"*"} element={<PageNotFound />} />
    </Routes>
  );
};

const Navigation = () => {
  return (
    <div className={sidebarLinkListStyle}>
      <SidebarNavLink label="Overview" to="/" />
      <SidebarNavLink label="Customers" to="/customers" />
      <SidebarNavLink label="Jobs" to="/jobs" />
      <SidebarNavLink label={"Invite Codes"} to={"/invite-codes"} />
      <SidebarNavLink label={"Accounts"} to={"/accounts"} />
      <SidebarNavLink
        label="Reps"
        icon={<User2FillIcon size={18} color="current" />}
        to={"/organization-reps"}
      />
      <LogoutSectionSidebar />
    </div>
  );
};

const AdminApp: React.FC = (props) => {
  return <AppLayout content={Content()} navigation={Navigation()} />;
};

export default AdminApp;
