import React from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import { Column } from "react-table";
import {
  PageTitle,
  PageTitleLine,
} from "src/Modules/Customer/Components/CommonCustoemerLib";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import PaginationControlsSingle from "src/Modules/Admin/Components/AdminTable/PaginationControlsSingle";
import AdminTable from "src/Modules/Admin/Components/AdminTable/AdminTable";
import AddCompanyAction from "src/Modules/Admin/Components/Pages/Companies/AddCompanyAction";
import CreateInviteCodeAction from "src/Modules/Admin/Pages/InviteCodes/CreateInviteCodeAction";
import DeleteInviteCodeAction from "src/Modules/Admin/Pages/InviteCodes/DeleteInviteCodeAction";

const Card = styled.div`
  display: flex;
  flex-direction: column;

  background: white;
  padding: 24px;
  border-radius: 12px;

  width: 100%;
  min-height: 200px;

  margin-bottom: 32px;

  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const Action = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: 0.3s;

  color: #9fa4b3;

  &:hover {
    color: #4f87f9;
    background: #fafafa;
    border-radius: 5px;
  }
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StageFilterContainer = styled.div`
  width: 200px;
  margin-left: auto;
`;

const Nav = styled.div`
  margin-left: auto;
  width: 400px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 12px 0 12px auto;
  align-items: center;
`;

const Code = styled.div`
  background: #155e75;
  color: white;

  padding: 6px 12px;
  letter-spacing: 5px;
  font-weight: bold;
  text-transform: uppercase;

  border-radius: 6px;

  width: fit-content;
  font-size: 14px;
`;

const UsesLeft = styled.div`
  font-size: 20px;
  font-weight: 400;
  font-family: Monospace;
`;

const Actions = styled.div``;

const InviteCodesPage: React.FC = (props) => {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
  });

  let query = useQuery(
    BackofficeApi.organizations.inviteCodes.list(searchParams)
  );

  const Columns: Column[] = [
    {
      Header: "Code",
      accessor: (row) => <Code>{row.code}</Code>,
    },
    {
      Header: "Uses Left",
      accessor: (row) => <UsesLeft>{row.usesLeft}</UsesLeft>,
    },
    {
      Header: "Actions",
      accessor: (row) => (
        <Actions>
          <DeleteInviteCodeAction id={row.id} data={row} />
        </Actions>
      ),
    },
  ];

  return (
    <FluidContentContainer>
      <PageTitle>Invite Codes</PageTitle>

      <PageTitleLine />
      <FlexRow>
        <CreateInviteCodeAction />
      </FlexRow>
      <Card>
        <Title>
          <span>{query.data?.data.totalResults} Codes</span>
          <Nav>
            <PaginationControlsSingle
              searchParams={searchParams}
              totalResults={query.data?.data.totalResults}
            />
          </Nav>
        </Title>
        {!query.isLoading && (
          <AdminTable data={query.data?.data.results} columns={Columns} />
        )}
      </Card>
    </FluidContentContainer>
  );
};

export default InviteCodesPage;
