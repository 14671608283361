import React, { ReactNode } from "react";
import styled from "styled-components";
import { isArray } from "util";

const Container = styled.div``;

const Label = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Value = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  gap: 8px;
`;

type Props = {
  label: string;
  value: string;
  icon: ReactNode;
};

const CompanyDetail: React.FC<Props> = (props) => {
  if (props.value == undefined) {
    return null;
  }

  let result = "";

  let renderedLabel = null;

  if (Array.isArray(props.label)) {
    if (props.label.length == 3 && props.label[2] == null) {
      renderedLabel = props.label[0];
    } else {
      renderedLabel = props.label;
    }
  } else {
    renderedLabel = props.label;
  }

  if (props.value?.value) {
    result = props.value.value;
  } else {
    result = props.value;
  }

  return (
    <Container>
      <Label>{renderedLabel}</Label>
      <Value>
        {props.icon}
        {result}
      </Value>
    </Container>
  );
};

export default CompanyDetail;
