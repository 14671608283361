import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Link,
  NavLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import {
  PageTitle,
  PageTitleLine,
} from "src/Modules/Customer/Components/CommonCustoemerLib";
import axios from "axios";
import AdminTable from "src/Modules/Admin/Components/AdminTable/AdminTable";
import { Column } from "react-table";
import CompanyCell from "src/Modules/Admin/Components/AdminTable/CompanyCell";
import ShortlistCell from "src/Modules/Admin/Components/AdminTable/ShortlistCell";
import JobStage from "src/Modules/Admin/Components/AdminTable/JobStage";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import PencilFillIcon from "remixicon-react/PencilFillIcon";
import PencilRuler2LineIcon from "remixicon-react/PencilRuler2LineIcon";
import Edit2FillIcon from "remixicon-react/Edit2FillIcon";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import FileCopyFillIcon from "remixicon-react/FileCopyFillIcon";
import JobDuplicateAction from "src/Modules/Customer/Components/Jobs/JobDuplicateAction";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import PaginationControls from "src/Modules/Admin/Components/AdminTable/PaginationControls";
import PaginationControlsSingle from "src/Modules/Admin/Components/AdminTable/PaginationControlsSingle";
import { de } from "date-fns/locale";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import AddBoxFillIcon from "remixicon-react/AddBoxFillIcon";
import AddLineIcon from "remixicon-react/AddLineIcon";
import AttachJobToOrganizationAction from "src/Modules/Admin/Components/Pages/Jobs/AttachJobToOrganizationAction";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import JobDeleteAction from "src/Modules/Customer/Components/Jobs/JobDeleteAction";
import JobStatus from "src/Modules/Customer/Components/Jobs/JobStatus";
import JobStatusEdit from "src/Modules/Admin/Components/Pages/Jobs/JobStatusEdit";

const Card = styled.div`
  display: flex;
  flex-direction: column;

  background: white;
  padding: 24px;
  border-radius: 12px;

  width: 100%;
  min-height: 200px;

  margin-bottom: 32px;

  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const Action = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: 0.3s;

  &:hover {
    background: #fafafa;
    border-radius: 5px;
  }
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ActionDanger = styled(Action)`
  &:hover {
    color: red;
  }
`;

const StageFilterContainer = styled.div`
  width: 200px;
  margin-left: auto;
`;

const Nav = styled.div`
  margin-left: auto;
  width: 400px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 12px 0 12px auto;
`;

const JobsListPage: React.FC = (props) => {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
  });

  const navigate = useNavigate();

  const navigator = useNavigate();
  let [creatingJob, setCreatingJob] = useState(false);

  let [filterStage, setFilterStage] = useState(searchParams.get("stage"));
  const queryClient = useQueryClient();

  const createJob = useMutation({
    mutationFn: BackofficeApi.jobs.create,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["jobs"]);
      navigate(`/jobs/${data.data.id}`);
    },
  });

  const info = useQuery(BackofficeApi.jobs.list(searchParams));

  const data = info.data?.data.data;

  const Columns: Column[] = [
    {
      Header: "Title",
      accessor: "jobDetails.title",
      width: 120,
    },
    {
      Header: "Company",
      accessor: (row) => {
        if (row.organization == undefined) {
          return <AttachJobToOrganizationAction jobId={row.id} />;
        } else {
          return (
            <CompanyCell
              name={row.organization?.details?.name}
              url={row.organization?.details?.logoUrl}
            />
          );
        }
      },
    },
    {
      Header: "Status",
      accessor: (row) => {
        return <JobStatusEdit jobId={row.id} stage={row.stage} />;
      },
    },
    {
      Header: "Stage",
      accessor: (row) => {
        if (row.status == "Open") {
          return <JobStage stage={"Matching"} />;
        }
        if (row.status == "Closed") {
          return <JobStage stage={"Activity Ended"} />;
        }
        if (row.status == "Paused") {
          return <JobStage stage={"On-Hold"} />;
        }
        return <JobStage stage={row.stage} />;
      },
    },
    {
      Header: "Shortlist",
      accessor: (row) => <ShortlistCell data={row} />,
    },
    {
      Header: "Actions",
      accessor: (row) => (
        <Row>
          <Link to={`/jobs/${row.id}`}>
            <Action>
              <Edit2LineIcon color={"lightgray"} size={18} />
            </Action>
          </Link>
          <ActionDanger
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <JobDeleteAction afterDuplicate={() => {}} data={row} />
          </ActionDanger>
          <JobDuplicateAction
            data={row}
            afterDuplicate={(job) => {
              queryClient.invalidateQueries(["jobs", "list"]);
            }}
          />
        </Row>
      ),
    },
  ];

  return (
    <FluidContentContainer>
      <PageTitle>Jobs</PageTitle>
      <PageTitleLine />
      <FlexRow>
        <Button
          variant="outline"
          size="medium"
          onClick={() => {
            createJob.mutate();
          }}
        >
          {createJob.isLoading ? (
            <>
              <span>Creating...</span>
            </>
          ) : (
            <>
              <AddLineIcon size={18} /> Create job
            </>
          )}
        </Button>
      </FlexRow>
      <Card>
        <Title>
          <span>{info.data?.data.totalResults} Jobs</span>
          <StageFilterContainer>
            <DefaultSelectField
              input={{
                value: filterStage,
                onChange: (v) => {
                  setFilterStage(v);
                  if (v !== "All") {
                    setSearchParams({
                      page: "1",
                      limit: searchParams.get("limit"),
                      stage: v,
                    });
                  } else {
                    setSearchParams({
                      page: "1",
                      limit: searchParams.get("limit"),
                    });
                  }
                },
              }}
              meta={{}}
              options={[
                { value: "All", label: "All" },
                { value: "In Review", label: "In Review" },
                { value: "Incomplete", label: "Incomplete" },
                { value: "Matching", label: "Matching" },
              ]}
            />
          </StageFilterContainer>
          <Nav>
            <PaginationControlsSingle
              searchParams={searchParams}
              totalResults={info.data?.data.totalResults}
            />
          </Nav>
        </Title>
        {!info.isLoading && (
          <AdminTable data={info.data?.data.results} columns={Columns} />
        )}
      </Card>
    </FluidContentContainer>
  );
};

export default JobsListPage;
