import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.img`
  width: 172px;
  height: 96px;
  border-radius: 6px;
  background: white;
`;

const ProjectImage: React.FC = (props) => {
  let [loading, setLoading] = useState(true);
  let [found, setFound] = useState(false);
  let [image, setImage] = useState<HTMLImageElement | null>(null);

  useEffect(() => {
    if (props.src == undefined) {
      return;
    }

    let timeoutHandler = window.setTimeout(() => {
      if (props.src == undefined) {
        return;
      }

      let image = new Image();

      image.onload = () => {
        setLoading(false);
        setFound(true);
        setImage(image);
      };

      image.onerror = () => {
        setLoading(false);
        setFound(false);
      };

      image.src = props.src;

      setImage(image);
    }, 800);
  }, [props.src]);

  let src = props.src;

  if (loading || !found) {
    return null;
  }

  return <Container src={src}></Container>;
};

export default ProjectImage;
