import React from "react";
import styled from "styled-components";
import Card from "src/Modules/Recruiter/Pages/Home/Card";
import AreaInputField from "src/Modules/Shared/Components/Temp/AreaInputField";
import { Field } from "react-final-form";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import CustomerSignupCompanyField from "src/Modules/Shared/Components/Temp/CustomerSignupCompanyField";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }

  &.column {
    flex-direction: column;
  }
`;

const RowLines = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  position: relative;
`;

const RowLineSpacer = styled.div`
  height: 8px;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 0;

  &.grow2 {
    flex: 2 1 0;
  }

  @media (min-width: 600px) {
    width: 48%;
  }
`;

export const FormLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 8px;

  span.optional {
    margin-left: 4px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.03em;
    color: #b8bcc7;
  }
`;

const FieldSpacer = styled.div`
  width: 16px;
  height: 16px;
`;

const ProfileEditForm: React.FC = (props) => {
  return (
    <FormContainer>
      <Row>
        <FormField>
          <FormLabel>First Name</FormLabel>
          <Field
            name="firstName"
            component={TextInputField}
            placeholder="Your first name"
          />
        </FormField>
        <FieldSpacer />
        <FormField>
          <FormLabel>Last Name</FormLabel>
          <Field
            name="lastName"
            component={TextInputField}
            placeholder="Your last name"
          />
        </FormField>
      </Row>

      <Row>
        <FormField>
          <FormLabel>Bio</FormLabel>
          <Field
            name="bio"
            component={AreaInputField}
            placeholder="Provide a summary for companies"
          />
        </FormField>
      </Row>
    </FormContainer>
  );
};

export default ProfileEditForm;
