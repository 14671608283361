import React, { useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { CustomerApi } from "src/Modules/Customer/Services/CustomerApi";
import { Field, Form, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import JobStatusDropdown from "src/Modules/Customer/Components/Jobs/JobStatusDropdown";

const Container = styled.div``;

const ContainerSelect = styled.div`
  margin-left: 24px;
  max-width: 130px;
`;
const JobStatusEdit: React.FC = (props) => {
  let jobId = props.jobId;
  let stage = props.stage;

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let [hover, setHover] = useState(false);

  let updateStatusMutation = useMutation({
    mutationFn: CustomerApi.jobs.id(jobId).updateStatus,
    onSuccess: () => {
      queryClient.invalidateQueries(["jobs"]);
    },
  });

  let valid = ["Matching", "On-Hold", "Job Ended"].includes(stage);

  if (!valid) {
    return null;
  }

  return (
    <ContainerSelect>
      <Form
        mutators={{ ...arrayMutators }}
        onSubmit={(values) => {
          updateStatusMutation.mutate(values);
        }}
        initialValues={{ stage: stage }}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="stage"
              component={JobStatusDropdown}
              options={[
                { value: "Matching", label: "Open" },
                { value: "On-Hold", label: "Paused" },
                { value: "Job Ended", label: "Closed" },
              ]}
            />
            <FormSpy
              subscription={{ values: true, dirtyFields: true }}
              onChange={(props) => {
                if (props.dirtyFields?.stage == true) {
                  form.submit();
                }
              }}
            ></FormSpy>
          </form>
        )}
      />
    </ContainerSelect>
  );
};

export default JobStatusEdit;
