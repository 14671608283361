import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  PageTitle,
  PageTitleLine,
} from "src/Modules/Customer/Components/CommonCustoemerLib";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import JobListingItem from "src/Modules/Customer/Components/Jobs/JobListingItem";
import JobItem from "src/Modules/Admin/Components/Customers/JobItem";

const Card = styled.div`
  display: flex;
  flex-direction: column;

  background: white;
  padding: 24px;
  border-radius: 12px;

  width: 100%;
  min-height: 200px;
`;
const CustomerJobs: React.FC = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { jobId } = useParams();

  useEffect(() => {
    axios.get("/api/backoffice/customers").then((response) => {
      setData(response.data.data);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return null;
  }

  let d = null;

  for (const c of data) {
    if (c.id == jobId) {
      d = c;
    }
  }

  return (
    <FluidContentContainer>
      <PageTitle>{d.data.company_name} • Jobs</PageTitle>
      <PageTitleLine />
      <PageTitle></PageTitle>
      <Card>
        <PageTitle>{d.data.company_namea}</PageTitle>
        {d.jobs.map((i) => {
          return <JobItem data={i} />;
        })}
      </Card>
    </FluidContentContainer>
  );
};

export default CustomerJobs;
