import React from "react";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import MainAndSideColumnsLayoutB, {
  MainColumn,
} from "src/Modules/Shared/Components/Layout/MainAndSideColumnsLayoutB";
import { Field, Form } from "react-final-form";
import Dropzone from "react-dropzone";
import CurrentUserProfilePicture from "src/Modules/Shared/Components/Temp/CurrentUserProfilePicture";
import { FormLabel } from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import { timezoneOptions } from "src/Modules/Shared/Components/Temp/timezones";
import PhoneNumberField from "src/Modules/Shared/Components/Temp/PhoneNumberField";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import axiosInstance from "src/Modules/Core/Service/Api";

const Card = styled.div`
  display: flex;
  width: 760px;
  padding: 32px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid var(--notch-neutral-100, #ededf0);
  background: #fff;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const UploadLogoButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 8px;
  height: 32px;

  border: 1px solid #105cf7;
  border-radius: 6px;
  background: white;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const RemoveAction = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #9fa4b3;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

const CenterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

const NamePictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const NameAndTitle = styled.div`
  color: var(--notch-neutral-900, #1c274a);

  /* Notch/Body/M - Bold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.42px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const GrayDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 999px;
  background: #d2d4db;
  margin-top: 2px;
`;

const PictureActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 18px;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  flex: 1 1 0;

  &.grow2 {
    flex: 2 1 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  gap: 12px;
`;

const Container = styled.div``;

const ProfilePage: React.FC = (props) => {
  let queryClient = useQueryClient();

  const authQuery = useQuery({
    queryKey: ["auth"],
    queryFn: () => axiosInstance.get("/api/auth/me"),
    staleTime: Infinity,
    retry: false,
  });

  const mutation = useMutation({
    mutationFn: (values: Record<string, any>) =>
      axios.post("/api/security/v1/me/profile", values),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["auth"]);
      queryClient.invalidateQueries(["organization", "members"]);
    },
  });

  const pictureMutation = useMutation({
    mutationFn: (values) =>
      axios.post("/api/security/v1/me/picture", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["auth"]);
    },
  });

  const pictureDeleteMutation = useMutation({
    mutationFn: (values) => axios.delete("/api/security/v1/me/picture"),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["auth"]);
    },
  });

  if (authQuery.isLoading) {
    return null;
  }

  let data = authQuery.data?.data;

  let initialData = data;

  return (
    <MainAndSideColumnsLayoutB>
      <MainColumn>
        <Card>
          <Form
            onSubmit={(values) => {
              mutation.mutate(values);
            }}
            initialValues={data}
            render={({ handleSubmit, submitting, values }) => (
              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <FormContainer>
                  <Row>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        let fileReader = new FileReader();

                        let data = new FormData();
                        data.append("file", acceptedFiles[0]);

                        pictureMutation.mutate(data);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <CenterRow>
                          <UploadContainer {...getRootProps()}>
                            <CurrentUserProfilePicture size={64} />
                            <NamePictureContainer>
                              <NameAndTitle>
                                <span>{data.name}</span>
                                {data.title && (
                                  <>
                                    <GrayDot />
                                    <span>{data.title}</span>
                                  </>
                                )}
                              </NameAndTitle>
                              <PictureActionContainer>
                                <UploadLogoButton
                                  onClick={(event) => {
                                    event.preventDefault();
                                  }}
                                >
                                  Upload Picture
                                </UploadLogoButton>
                                {data.profilePictureUrl && (
                                  <RemoveAction
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      pictureDeleteMutation.mutate();
                                    }}
                                  >
                                    Remove Picture
                                  </RemoveAction>
                                )}
                                <input {...getInputProps()} />
                              </PictureActionContainer>
                            </NamePictureContainer>
                          </UploadContainer>
                        </CenterRow>
                      )}
                    </Dropzone>
                  </Row>
                  <Row>
                    <FormField>
                      <FormLabel>First Name</FormLabel>
                      <Field name={"firstName"} component={TextInputField} />
                    </FormField>

                    <FormField>
                      <FormLabel>Last Name</FormLabel>
                      <Field name={"lastName"} component={TextInputField} />
                    </FormField>
                  </Row>

                  <Row>
                    <FormField>
                      <FormLabel>Default Time Zone</FormLabel>
                      <Field
                        name={"timezone"}
                        component={DefaultSelectField}
                        options={timezoneOptions}
                      />
                    </FormField>
                  </Row>
                  <Row>
                    <FormField>
                      <FormLabel>Phone Number</FormLabel>
                      <Field name="phoneNumber" component={PhoneNumberField} />
                    </FormField>
                  </Row>
                  <Row>
                    <FormField>
                      <FormLabel>Email</FormLabel>
                      <Field
                        name={"email"}
                        component={TextInputField}
                        disabled={true}
                        placeholder="Your company location"
                      />
                    </FormField>
                  </Row>
                  <ButtonGroup>
                    <Button
                      variant="primary"
                      size="large"
                      disabled={mutation.isLoading}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                </FormContainer>
              </form>
            )}
          />
        </Card>
      </MainColumn>
    </MainAndSideColumnsLayoutB>
  );
};

export default ProfilePage;
