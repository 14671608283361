import React from "react";
import styled from "styled-components";
import { PageTitleLine } from "src/Modules/Customer/Components/CommonCustoemerLib";
import { NavLink } from "react-router-dom";

const Container = styled.div`
  background: white;
  width: 100%;

  padding: 16px 24px;

  margin-bottom: 32px;
  height: auto;
  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
  gap: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CandidatesNum = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #858b9d;
`;

const ActionLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
  margin-left: 0;
  grap: 18px;

  span {
    margin-left: 8px;
  }

  svg {
    fill: red;
  }
`;

const TagGray = styled.div`
  padding: 4px 12px;

  height: 24px;
  background: #fafafb;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #6a7288;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const JobItem: React.FC<any> = (props) => {
  return (
    <Container>
      <Row>
        <Title>{props.data.title}</Title>
        <TagGray>Incomplete</TagGray>
      </Row>
      <Row>
        <ActionLink to={`/customer/jobs///shortlist`}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_4_9855)">
              <path
                d="M16.6667 1.66669C17.1267 1.66669 17.5 2.04002 17.5 2.50002V5.63085L15.8333 7.29752V3.33335H4.16667V16.6667H15.8333V14.3684L17.5 12.7017V17.5C17.5 17.96 17.1267 18.3334 16.6667 18.3334H3.33333C2.87333 18.3334 2.5 17.96 2.5 17.5V2.50002C2.5 2.04002 2.87333 1.66669 3.33333 1.66669H16.6667ZM18.1483 7.34002L19.3267 8.51835L12.845 15L11.665 14.9984L11.6667 13.8217L18.1483 7.34002ZM10.8333 10V11.6667H6.66667V10H10.8333ZM13.3333 6.66669V8.33335H6.66667V6.66669H13.3333Z"
                fill="#1C274A"
              />
            </g>
            <defs>
              <clipPath id="clip0_4_9855">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Shortlist</span>
        </ActionLink>
        <CandidatesNum>0 Total candidates • 0 Hidden candidates</CandidatesNum>
      </Row>

      <Row></Row>
    </Container>
  );
};

export default JobItem;
