import React from "react";
import styled from "styled-components";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import AdminTable from "src/Modules/Admin/Components/AdminTable/AdminTable";
import ShortlistDraftEdit from "src/Modules/Admin/Components/Pages/ShortList/Components/ShortlistDraftEdit";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CompanyJobInfoLine from "src/Modules/Admin/Components/Pages/ShortList/Components/CompanyJobInfoLine";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";

const Card = styled.div`
  display: flex;
  flex-direction: column;

  background: white;
  padding: 24px;
  border-radius: 12px;

  width: 100%;
  min-height: 200px;

  margin-bottom: 32px;

  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const Action = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 24px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  margin: 20px 0 20px auto;
`;

export const PrimaryButton = styled.button`
  height: 48px;
  width: max-content;
  padding: 12px 32px;
  background: #105cf7;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    fill: #ffffff;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: #ededf0;
    color: #9fa4b3;
    cursor: default;
    border: none;
    svg {
      fill: #9fa4b3;
    }
  }
`;

const ShortlistEdit: React.FC = (props) => {
  const job = props.jobData;
  let data = props.data;
  let queryClient = useQueryClient();

  let shortlist = job.shortlistedCandidates;
  shortlist = shortlist.filter((i) => i.status != "Draft");
  return (
    <div>
      <ButtonGroup>
        <div style={{ marginLeft: "auto" }} />
        <PrimaryButton
          disabled={false}
          onClick={() => {
            axios
              .post(`/api/backoffice/jobs/${job.id}/shortlist/publish`)
              .then((response) => {
                queryClient.invalidateQueries(["jobs", "details", job.id]);
              });
          }}
        >
          Publish changes
        </PrimaryButton>
      </ButtonGroup>

      <Card>
        <Title>{shortlist.length ?? 0} Drafts</Title>
        {shortlist.map((i) => {
          return <ShortlistDraftEdit key={i.code} data={i} />;
        })}
      </Card>
    </div>
  );
};

export default ShortlistEdit;
