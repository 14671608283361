import React, { HTMLProps, useState } from "react";
import styled from "styled-components";

const Container = styled.img<HTMLProps<HTMLImageElement>>`
  width: 56px;
  height: 56px;
  border: 1px solid #ededf0;
  border-radius: 6px;
`;

type Props = {
  src: string;
};

const CompanyLogo: React.FC<Props> = (props) => {
  return (
    <Container
      onLoad={(event) => {}}
      onError={(event) => {
        //setHasError(true);
      }}
      src={props.src}
      width={56}
      height={56}
    ></Container>
  );
};

export default CompanyLogo;
