import React from "react";
import styled from "styled-components";
import HomePageGreeting from "src/Modules/Customer/Pages/Home/HomePageGreeting";
import { PageTitleLine } from "src/Modules/Customer/Components/CommonCustoemerLib";
import MainAndSideColumnsLayout, {
  MainColumn,
  SideColumn,
} from "src/Modules/Shared/Components/Layout/MainAndSideColumnsLayout";
import Card from "src/Modules/Recruiter/Pages/Home/Card";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import JobEntry from "src/Modules/Recruiter/Pages/Jobs/JobEntry";

const HomeTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #105cf7;
  margin: 0;
`;

const Title = styled.div`
  color: var(--notch-neutral-900, #1c274a);

  /* Notch/Label/Large */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 177.778% */
  letter-spacing: -0.54px;
`;

const NotVerifiedMessage = styled.div`
  color: #ef4444;
  font-family: Inter;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;
  padding: 30px;
  width: 100%;
  text-align: center;
`;

const JobsPage: React.FC = (props) => {
  const queryClient = useQueryClient();
  const profileQuery = useQuery({
    queryKey: ["recruiter", "profile"],
    queryFn: () => axios.get("/api/recruiters/profile"),
  });

  const jobQuery = useQuery({
    queryKey: ["recruiter", "jobs"],
    queryFn: () => axios.get("/api/recruiters/jobs"),
  });

  if (profileQuery.isLoading) {
    return null;
  }

  if (jobQuery.isLoading) {
    return null;
  }

  let profileData = profileQuery.data?.data;

  let jobData = jobQuery.data?.data;

  if (profileData.status == "Unverified") {
    return (
      <FluidContentContainer>
        <HomeTitle>Jobs</HomeTitle>
        <PageTitleLine />
        <NotVerifiedMessage>
          Whoa whoa whoa, you have to verify first!
        </NotVerifiedMessage>
      </FluidContentContainer>
    );
  }

  return (
    <FluidContentContainer>
      <HomeTitle>Jobs</HomeTitle>
      <PageTitleLine />
      <MainAndSideColumnsLayout>
        <MainColumn>
          <Card>
            <Title>Active jobs looking for a recruiter</Title>
            {jobData.map((i: unknown) => {
              return <JobEntry data={i.data} />;
            })}
          </Card>
        </MainColumn>
        <SideColumn></SideColumn>
      </MainAndSideColumnsLayout>
    </FluidContentContainer>
  );
};

export default JobsPage;
