import React, { useState } from "react";
import styled from "styled-components";
import ShortlistCandidateStatus from "src/Modules/Admin/Components/Pages/ShortList/Components/ShortlistCandidateStatus";
import Calendar2LineIcon from "remixicon-react/Calendar2LineIcon";
import ChatDeleteLineIcon from "remixicon-react/ChatDeleteLineIcon";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const HoverBox = styled.div`
  min-width: 360px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.03em;

  color: white;
  padding: 8px 12px;
  background: #1c274a;
  border-radius: 6px;

  left: 18px;
  bottom: -20px;
  position: absolute;

  z-index: 1000;

  height: fit-content;
  text-wrap: wrap;
  line-break: anywhere;

  ul {
    margin-left: 12px;
    margin: 8px 0 8px 12px;
    font-size: 14px;
  }

  .comment {
    font-size: 14px;
    margin-top: 8px;
  }
`;

const Details = styled.div`
  position: relative;
`;

const DetailsIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;
`;

const CandidateShortlistsStatusWithDetails: React.FC = (props) => {
  let [hover, setHover] = useState<boolean>(false);

  let status = props.data.status;

  return (
    <Container>
      <ShortlistCandidateStatus status={props.data.status} />

      {status == "Interviewing" && (
        <Details
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {hover && (
            <HoverBox>
              Calendly Link:
              <br /> {props.data.calendlyLink}
            </HoverBox>
          )}
          <DetailsIcon>
            <Calendar2LineIcon size={18} />
          </DetailsIcon>
        </Details>
      )}

      {status == "Not Interested" && (
        <Details
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {hover && (
            <HoverBox>
              Decline reasons:
              <ul>
                {props.data.declineReasons?.tags?.map((i) => {
                  return <li>{i}</li>;
                })}
              </ul>
              <br />
              Additional Comments:
              <br />
              <div className={"comment"}>
                {props.data.declineReasons.comment}
              </div>
            </HoverBox>
          )}
          <DetailsIcon>
            <ChatDeleteLineIcon size={18} />
          </DetailsIcon>
        </Details>
      )}
    </Container>
  );
};

export default CandidateShortlistsStatusWithDetails;
