import React, { useState } from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { CircleFlag } from "react-circle-flags";
import countries from "src/Modules/Shared/Components/Temp/countries";
import RemainingLocations from "src/Modules/Customer/Pages/Jobs/RemainingLocations";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

type Props = {
  locations: [];
};

const FlagList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-right: 3px;
`;

const Label = styled.div`
  color: var(--notch-neutral-900, #1c274a);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;
`;

const ShowMore = styled.div`
  width: auto;
  position: relative;
  color: var(--notch-neutral-900, #1c274a);
  cursor: pointer;

  /* Notch/Body/M - Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;
`;

const FlagItem = styled.div`
  width: 16px;
  height: 16px;
  margin-right: -6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: auto;
  border-radius: 6px;
  padding: 0 8px;
  height: 24px;
  border: 1px solid var(--notch-neutral-100, #ededf0);
`;

const RegionFlags = {
  Europe: ["es", "fr", "it"],
  "North America": ["us", "ca"],
  "South America": ["br", "ar", "co"],
  Africa: ["za", "ke", "ng"],
  Oceania: ["au", "nz"],
  Caribbean: ["jm", "cu", "do"],
  "Middle East": ["ir", "eg", "tr"],
  "Central America": ["cr", "pa", "ni"],
  Asia: ["cn", "in", "jp"],
};

const JobViewLocationList: React.FC<Props> = (props) => {
  let info = useQuery({
    queryKey: ["options", "regions"],
    queryFn: () => axios.get("/api/options/regions"),
  });

  let [showAll, setShowAll] = useState(false);

  if (info.isLoading) {
    return null;
  }

  let regions = info.data?.data;

  let countryListFlattened = regions.flatMap((region: any) => {
    return region.options;
  });

  let setOfSelectedCountryCode = new Set(props.locations);

  let allSelectedCountries = countryListFlattened.filter((country) => {
    return setOfSelectedCountryCode.has(country.value);
  });

  let selectedRegions = new Array();

  for (const region of regions) {
    let selectedCountriesByRegions = allSelectedCountries.filter((country) => {
      return country.group == region.label;
    });

    if (region.options.length == selectedCountriesByRegions.length) {
      selectedRegions.push(region.label);
    }
  }

  let selectedCounties = allSelectedCountries.filter((country) => {
    return !selectedRegions.includes(country.group);
  });

  let moreCount = selectedRegions.length + selectedCounties.length - 7;

  let countriesToShow = selectedCounties;

  if (moreCount < 0 || showAll) {
    countriesToShow = selectedCounties;
  } else {
    countriesToShow = selectedCounties.slice(0, 7 - selectedRegions.length);
  }

  return (
    <Container>
      {selectedRegions.map((region) => {
        let flags = RegionFlags[region];
        return (
          <Tag>
            <FlagList>
              {flags.map((i) => {
                return (
                  <FlagItem>
                    <CircleFlag countryCode={i} height={16} />
                  </FlagItem>
                );
              })}
            </FlagList>
            <Label>{region}</Label>
          </Tag>
        );
      })}
      {countriesToShow.map((country) => {
        return (
          <Tag>
            <FlagList>
              <FlagItem>
                <CircleFlag countryCode={country.value} height={16} />
              </FlagItem>
            </FlagList>
            <Label>{country.label}</Label>
          </Tag>
        );
      })}

      {moreCount > 0 && !showAll && (
        <ShowMore onClick={() => setShowAll(true)}>+{moreCount} more</ShowMore>
      )}
    </Container>
  );

  let displayFlags = null;
  let displayText = "";
  let displayMore = null;
  let displayOnly = false;

  // If there is a region
  if (selectedRegions.length > 0) {
    let subRegion = allSelectedCountries.filter((country) => {
      return country.group == selectedRegions[0];
    });

    subRegion = subRegion.slice(0, 3);

    let flags = RegionFlags[selectedRegions[0]];

    displayFlags = (
      <FlagList>
        {flags.map((i) => {
          return (
            <FlagItem>
              <CircleFlag countryCode={i} height={16} />
            </FlagItem>
          );
        })}
        {(moreCount > 0 || selectedRegions.length > 0) && (
          <FlagItem>
            <svg
              className="moreFlag"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_255_36544)">
                <rect width="16" height="16" rx="8" fill="#EDEDF0" />
                <path
                  d="M3.33333 6.66699C2.6 6.66699 2 7.26699 2 8.00033C2 8.73366 2.6 9.33366 3.33333 9.33366C4.06667 9.33366 4.66667 8.73366 4.66667 8.00033C4.66667 7.26699 4.06667 6.66699 3.33333 6.66699ZM12.6667 6.66699C11.9333 6.66699 11.3333 7.26699 11.3333 8.00033C11.3333 8.73366 11.9333 9.33366 12.6667 9.33366C13.4 9.33366 14 8.73366 14 8.00033C14 7.26699 13.4 6.66699 12.6667 6.66699ZM8 6.66699C7.26667 6.66699 6.66667 7.26699 6.66667 8.00033C6.66667 8.73366 7.26667 9.33366 8 9.33366C8.73333 9.33366 9.33333 8.73366 9.33333 8.00033C9.33333 7.26699 8.73333 6.66699 8 6.66699Z"
                  fill="#B8BCC7"
                />
              </g>
              <defs>
                <clipPath id="clip0_255_36544">
                  <rect width="16" height="16" rx="8" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </FlagItem>
        )}
      </FlagList>
    );
  } else {
    let subCountries = allSelectedCountries.slice(0, 3);
    displayFlags = (
      <FlagList>
        {subCountries.map((i) => {
          return (
            <FlagItem>
              <CircleFlag countryCode={i.value} height={16} />
            </FlagItem>
          );
        })}
        {moreCount > 0 && (
          <FlagItem>
            <svg
              className="moreFlag"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_255_36544)">
                <rect width="16" height="16" rx="8" fill="#EDEDF0" />
                <path
                  d="M3.33333 6.66699C2.6 6.66699 2 7.26699 2 8.00033C2 8.73366 2.6 9.33366 3.33333 9.33366C4.06667 9.33366 4.66667 8.73366 4.66667 8.00033C4.66667 7.26699 4.06667 6.66699 3.33333 6.66699ZM12.6667 6.66699C11.9333 6.66699 11.3333 7.26699 11.3333 8.00033C11.3333 8.73366 11.9333 9.33366 12.6667 9.33366C13.4 9.33366 14 8.73366 14 8.00033C14 7.26699 13.4 6.66699 12.6667 6.66699ZM8 6.66699C7.26667 6.66699 6.66667 7.26699 6.66667 8.00033C6.66667 8.73366 7.26667 9.33366 8 9.33366C8.73333 9.33366 9.33333 8.73366 9.33333 8.00033C9.33333 7.26699 8.73333 6.66699 8 6.66699Z"
                  fill="#B8BCC7"
                />
              </g>
              <defs>
                <clipPath id="clip0_255_36544">
                  <rect width="16" height="16" rx="8" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </FlagItem>
        )}
      </FlagList>
    );
  }

  let moreText = "";

  if (selectedRegions.length >= 3) {
    displayText = selectedRegions.slice(0, 3).join(", ");
  } else if (selectedRegions.length < 3 && selectedRegions.length > 0) {
    displayText =
      selectedRegions.join(", ") +
      (selectedCounties.length > 1 ? ", " : "") +
      selectedCounties
        .slice(0, 3 - selectedRegions.length)
        .map((i) => i.label)
        .join(", ");
  } else {
    displayText = selectedCounties
      .slice(0, 3)
      .map((i) => i.label)
      .join(", ");
  }

  if (selectedRegions.length > 3) {
    moreText = selectedRegions.slice(3).join(", ");

    if (selectedCounties.length > 1) {
      moreText += ", " + selectedCounties.map((i) => i.label).join(", ");
    }
  } else if (selectedRegions.length <= 3) {
    moreText = selectedCounties
      .map((i) => i.label)
      .slice(3 - selectedRegions.length)
      .join(",");
  }

  // More Text

  if (allSelectedCountries.length == 1) {
    displayOnly = true;
  }

  displayMore = <RemainingLocations text={moreText} count={moreCount} />;

  return (
    <Container>
      {displayFlags}

      <Label>
        {displayText} {displayOnly && " only"}
      </Label>
      <ShowMore>{moreCount > 0 && displayMore}</ShowMore>
    </Container>
  );
};

export default JobViewLocationList;
