import React from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import { useParams } from "react-router-dom";
import ChangeRepAction from "src/Modules/Admin/Pages/Organization/ChangeRepAction";
import AccountProfileAvatar from "src/Modules/Shared/Components/Temp/AccountProfileAvatar";

const Container = styled.div``;

const Card = styled.div`
  display: flex;
  width: 760px;
  padding: 32px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid var(--notch-neutral-100, #ededf0);
  background: #fff;
`;

const Title = styled.div`
  font-family: Inter;
  font-size: 14px;
  color: black;
`;

const EmptyProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  width: 32px;
  aspect-ratio: 1;
  border: 1px dashed gray;
`;

const RepRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const RepName = styled.div`
  font-family: Inter;
  color: var(--notch-neutral-900, #1c274a);

  /* Notch/Body/M - Bold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.42px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const TeamPage: React.FC = (props) => {
  let { id } = useParams();

  let query = useQuery(BackofficeApi.organizations.get(id).single());

  if (query.isLoading) {
    return null;
  }
  let data = query.data?.data;
  return (
    <Card>
      <Title>Representative</Title>
      <RepRow>
        {data.representative == null && (
          <>
            <EmptyProfile />
            <RepName>No rep selected</RepName>
            <ChangeRepAction organizationId={data.id} />
          </>
        )}
        {data.representative != null && (
          <>
            <AccountProfileAvatar
              name={data.representative.firstName}
              profilePictureUrl={data.representative.publicProfilePictureUrl}
            />
            <RepName>
              {data.representative.firstName} {data.representative.lastName}
            </RepName>
            <ChangeRepAction organizationId={data.id} />
          </>
        )}
      </RepRow>
    </Card>
  );
};

export default TeamPage;
