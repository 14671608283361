import React, { useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import { useDebounce } from "@uidotdev/usehooks";
import { CustomerApiQuery } from "src/Modules/Customer/Services/CustomerApi";
import { PageTitleLine } from "src/Modules/Customer/Components/CommonCustoemerLib";
import { Field, Form, FormSpy } from "react-final-form";
import Dropzone from "react-dropzone";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import { FormLabel } from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import TextareaField from "src/Modules/Shared/Components/Util/TextareaField";
import LocationInputField from "src/Modules/Shared/Components/Temp/LocationInputField";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import TextInputWithIconAny from "src/Modules/Customer/Components/TextInputWithIconAny";
import OrganizationDetailsFormContent from "src/Modules/Customer/Components/Details/OrganizationDetailsFormContent";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";

const Card = styled.div`
  width: 760px;
  max-width: 94vw;

  padding: 24px;

  background: white;

  border-radius: 12px;

  position: relative;

  gap: 12px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 18px;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  flex: 1 1 0;

  &.grow2 {
    flex: 2 1 0;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;

  width: 100%;

  /* Notch/Neutral/50 */

  background: #fafafb;
  border-radius: 12px;
`;

const InputIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 26px;
  height: 26px;

  margin-right: 8px;
`;

const CancelButton = styled.button`
  height: 48px;
  padding: 12px 32px;
  background: none;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
`;

const SaveButton = styled.button`
  height: 48px;
  padding: 12px 32px;
  background: #105cf7;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    fill: #ffffff;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: #ededf0;
    color: #9fa4b3;
    cursor: default;
    border: none;
    svg {
      fill: #9fa4b3;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

const CloseIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 24px;
  right: 24px;

  cursor: pointer;
`;

const TestBox = styled.div`
  width: 200px;
  height: 100px;
  background: red;
`;

const UploadLogoButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 8px;
  height: 32px;

  border: 1px solid #105cf7;
  border-radius: 6px;
  background: white;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const RemoveAction = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #9fa4b3;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

const CenterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
const OrganizationEditPopup: React.FC = (props) => {
  let data = props.data;
  let details = data.details;

  const [displayedLogoUrl, setDisplayedLogoUrl] = useState(details?.logoUrl);
  const [currentWebsiteUrl, setCurrentWebsiteUrl] = useState(
    details?.websiteUrl
  );

  const mutation = useMutation({
    mutationFn: BackofficeApi.organizations.get(props.id).details.set,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["organizations"]);
      // queryClient.setQueryData(["organization", "details"], () => data);
      props.onClose();
    },
  });

  const logoMutation = useMutation({
    mutationFn: BackofficeApi.organizations.get(props.id).details.uploadLogo,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["organizations"]);
      setDisplayedLogoUrl(data.data.newLogoUrl);
    },
  });

  const logoDeleteMutation = useMutation({
    mutationFn: BackofficeApi.organizations.get(props.id).details.removeLogo,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["organizations"]);
      setDisplayedLogoUrl("https://logo.clearbit.com/" + currentWebsiteUrl);
    },
  });

  const debouncedLogoUrl = useDebounce(displayedLogoUrl, 1500);

  const queryClient = useQueryClient();

  return (
    <Card>
      <CloseIcon
        onClick={() => {
          props.onClose();
        }}
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_36_4546)">
            <path
              d="M16.0001 14.8217L20.1251 10.6967L21.3034 11.875L17.1784 16L21.3034 20.125L20.1251 21.3033L16.0001 17.1783L11.8751 21.3033L10.6968 20.125L14.8218 16L10.6968 11.875L11.8751 10.6967L16.0001 14.8217Z"
              fill="#B8BCC7"
            />
          </g>
          <defs>
            <clipPath id="clip0_36_4546">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(6 6)"
              />
            </clipPath>
          </defs>
        </svg>
      </CloseIcon>
      <Row>
        <Title>Edit Company Details</Title>
      </Row>
      <PageTitleLine />

      <Form
        onSubmit={(values) => {
          mutation.mutate(values);
        }}
        initialValues={details}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <FormContainer>
              <Dropzone
                accept={{
                  "image/png": [".png"],
                  "image/jpeg": [".jpg", ".jpeg"],
                }}
                onDrop={(acceptedFiles) => {
                  let fileReader = new FileReader();

                  let data = new FormData();
                  data.append("file", acceptedFiles[0]);

                  logoMutation.mutate(data);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <CenterRow>
                    <UploadContainer {...getRootProps()}>
                      <CompanyLogo size={52} src={displayedLogoUrl} />
                      <UploadLogoButton
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                      >
                        Upload Logo
                      </UploadLogoButton>
                    </UploadContainer>
                    {!debouncedLogoUrl?.startsWith("https://logo.clearbit") &&
                      debouncedLogoUrl != "" &&
                      debouncedLogoUrl != null && (
                        <RemoveAction
                          onClick={() => {
                            logoDeleteMutation.mutate();
                          }}
                        >
                          Delete
                        </RemoveAction>
                      )}
                    <input {...getInputProps()} />
                  </CenterRow>
                )}
              </Dropzone>
              <FormSpy
                subscription={{ values: true, dirtyFields: true }}
                onChange={(props) => {
                  if (props.dirtyFields.websiteUrl != undefined || true) {
                    setCurrentWebsiteUrl(props.values.websiteUrl);

                    if (
                      displayedLogoUrl?.startsWith("https://logo.clearbit") ||
                      displayedLogoUrl == null
                    ) {
                      setDisplayedLogoUrl(
                        "https://logo.clearbit.com/" + props.values.websiteUrl
                      );
                    }
                  }
                }}
              ></FormSpy>

              <OrganizationDetailsFormContent />

              <PageTitleLine />

              <ButtonGroup>
                <CancelButton
                  onClick={(event) => {
                    event.preventDefault();
                    props.onClose();
                  }}
                >
                  Cancel
                </CancelButton>
                <SaveButton type="submit" disabled={mutation.isLoading}>
                  Save
                </SaveButton>
              </ButtonGroup>
            </FormContainer>
          </form>
        )}
      />
    </Card>
  );
};

export default OrganizationEditPopup;
