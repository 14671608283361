import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;

  width: 100%;
  max-width: 100%;

  height: auto;
  background: #ffffff;

  border: 1px solid #ededf0;
  border-radius: 12px;

  position: relative;
`;

const Card: React.FC = (props) => {
  return <Container>{props.children}</Container>;
};

export default Card;
