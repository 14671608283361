import React from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import LogoutCircleRLineIcon from "remixicon-react/LogoutCircleRLineIcon";
import CurrentUserProfilePicture from "src/Modules/Shared/Components/Temp/CurrentUserProfilePicture";
import axiosInstance from "src/Modules/Core/Service/Api";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-top: auto;
`;

const Name = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const Icon = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  transition: 0.3s;

  &:hover {
    transform: translateX(4px);
  }

  svg {
    color: #1c274a;
  }

  &:hover svg {
    color: #105cf7;
  }
`;

const LogoutSectionSidebar: React.FC = (props) => {
  const authQuery = useQuery({
    queryKey: ["auth"],
    queryFn: () => axiosInstance.get("/api/auth/me"),
    staleTime: Infinity,
    retry: false,
  });

  if (authQuery.isLoading) {
    return null;
  }

  let data = authQuery.data?.data;

  return (
    <Container>
      <CurrentUserProfilePicture size={32} />
      <Name>{data.name}</Name>
      <Icon
        onClick={() => {
          axios.post(`/api/security/v1/logout`).then((response) => {
            window.location.reload();
          });
        }}
      >
        <LogoutCircleRLineIcon size={22} />
      </Icon>
    </Container>
  );
};

export default LogoutSectionSidebar;
