import React from "react";
import styled from "styled-components";
import AsRelativeTime from "src/Modules/Shared/Components/AsRelativeTime";
import { format, isDate } from "date-fns";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
`;

const DateTimeValue = styled.div`
  color: var(--notch-neutral-900, #1c274a);
  text-align: left;

  /* Notch/Body/M - Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.42px;
  margin: 0;
  padding: 0;
`;
const RelativeTimeValue = styled.div`
  color: var(--notch-neutral-900, #1c274a);
  text-align: left;

  /* Notch/Body/M - Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.42px;
  margin-top: -6px;
`;

type Props = {
  format?: string;
  data?: string;
};

const DateTimeWithRelativeInfo: React.FC<Props> = (props) => {
  let data = props.data;

  if (data == null) {
    return null;
  }

  let formatString = props.format ?? "PPP";

  let time = new Date(data);

  if (!isDate(time)) {
    return (
      <Container>
        <DateTimeValue>-</DateTimeValue>
      </Container>
    );
  }

  return (
    <Container>
      <DateTimeValue>{format(time, formatString)}</DateTimeValue>

      <RelativeTimeValue>
        <AsRelativeTime>{time}</AsRelativeTime>
      </RelativeTimeValue>
    </Container>
  );
};

export default DateTimeWithRelativeInfo;
