import React from "react";
import styled from "styled-components";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const GrayDot = styled.div`
  background: #d2d4db;
  width: 6px;
  height: 6px;
  border-radius: 222px;
`;

const Name = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.03em;

  /* Grays/Gray 900 */

  color: #1c274a;
`;

const JobTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.03em;

  /* Grays/Gray 900 */

  color: #1c274a;
`;

const CompanyJobInfoLine: React.FC = (props) => {
  let job = props.job;

  let orgQuery = useQuery(
    BackofficeApi.organizations.get(props.job.organization.id).single()
  );

  if (orgQuery.isLoading) {
    return null;
  }

  let data = orgQuery.data?.data;

  return (
    <Container>
      <CompanyLogo size={32} src={data.details?.logoUrl} />
      <Name>{data.details?.name}</Name>
      <GrayDot />
      <JobTitle>{job.jobDetails.title}</JobTitle>
    </Container>
  );
};

export default CompanyJobInfoLine;
