import React, { useState } from "react";
import styled from "styled-components";
import { format, parseISO } from "date-fns";
import StickyNoteLineIcon from "remixicon-react/StickyNoteLineIcon";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Popup = styled.div`
  width: 256px;
  background: white;
  min-height: 100px;
  border-radius: 12px;
  position: absolute;
  z-index: 1000;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  top: 12px;
  left: 20px;

  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
`;

const GrayLine = styled.div`
  width: 100%;
  height: 1px;
  background: #e2e8f0;
`;

const Title = styled.div`
  /* Notch/Body/M - Bold */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */
  color: #1c274a;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Dot = styled.div`
  width: 12px;
  aspect-ratio: 1;
  border-radius: 99px;
`;

const DotWorkedHours = styled(Dot)`
  background: #105cf7;
`;

const DotOffHours = styled(Dot)`
  background: #e6eefe;
`;

const Text = styled.div`
  /* Time-off: 0h */

  /* Notch/Body/M - Bold */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */
  color: #1c274a;
`;

const Info = styled.div`
  /* Notch/Body/M - Regular */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */
  color: #1c274a;
`;

const TimeOffReason = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;

  letter-spacing: -0.03em;

  color: #1c274a;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 2px 6px;
  border-radius: 6px;
  background: #e6eefe;
`;

const TimeValuePopup: React.FC = (props) => {
  const [show, setShow] = useState(false);
  let data = props.data;

  let title = format(parseISO(data.date), "MMM d");

  return (
    <Container
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      onClick={() => setShow(!show)}
    >
      {props.children}
      {show && (
        <Popup
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Title>{title}</Title>
          <GrayLine />
          <Line>
            <DotWorkedHours />
            <Text>Worked Hours: {data.workedHours ?? 0}h</Text>
          </Line>

          <Line>
            <DotOffHours />
            <Text>Time-Off: {data.timeOffHours ?? 0}h</Text>
            {data.timeoffReason != undefined && (
              <TimeOffReason>{data.timeoffReason}</TimeOffReason>
            )}
          </Line>
          {data.info != "" && (
            <>
              <GrayLine />
              <Line>
                <StickyNoteLineIcon size={14} color={"#105cf7"} />
                <Text>Notes</Text>
              </Line>
              <Info>{data.info ?? "-"}</Info>
            </>
          )}
        </Popup>
      )}
    </Container>
  );
};

export default TimeValuePopup;
