import React, { useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import PopupOverlay from "src/Modules/Core/Components/Experimental/PopupOverlay";
import DeleteBin2LineIcon from "remixicon-react/DeleteBin2LineIcon";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import axios from "axios/index";
import Edit2FillIcon from "remixicon-react/Edit2FillIcon";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import CandidateStatusTag from "src/Modules/Shared/Components/Shortlist/CandidateCard/CandidateStatusTag";
import ShortlistCandidateStatus from "src/Modules/Admin/Components/Pages/ShortList/Components/ShortlistCandidateStatus";

const Card = styled.div`
  width: 544px;
  max-width: 96vw;

  padding: 24px;

  background: white;

  border-radius: 12px;

  position: relative;

  gap: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Action = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: 0.3s;

  &:hover {
    color: red;
  }
`;

const Icon = styled.div`
  width: 48px;
  aspect-ratio: 1;
`;

const Label = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1c274a;
  width: 100%;
`;

const JobTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #105cf7;

  padding: 4px 16px;

  background: #f8faff;
  border-radius: 6px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

const Grouping = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 22px;
`;

const CandidateStatusChangeAction: React.FC = (props) => {
  const [popup, setPopup] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const changeMutation = useMutation({
    mutationFn: BackofficeApi.shortlistCandidates.id(props.id).updateStatus,
    onSuccess: () => {
      queryClient.invalidateQueries(["jobs"]).then(() => {
        setPopup(false);
      });
    },
  });

  return (
    <>
      <PopupOverlay active={popup} onClose={() => setPopup(false)}>
        {({ onClose }) => (
          <Card>
            <Icon>
              <Edit2FillIcon size={42} color={"blue"} />
            </Icon>
            <Label>Change status for Candidate</Label>
            {!changeMutation.isLoading && (
              <Grouping>
                <div
                  onClick={() => {
                    changeMutation.mutate({ status: "Not Viewed" });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <ShortlistCandidateStatus status="Not Viewed" />
                </div>
                <div
                  onClick={() => {
                    changeMutation.mutate({ status: "Interviewing" });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <ShortlistCandidateStatus status="Interviewing" />
                </div>
                <div
                  onClick={() => {
                    changeMutation.mutate({ status: "Not Interested" });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <ShortlistCandidateStatus status="Not Interested" />
                </div>
              </Grouping>
            )}
          </Card>
        )}
      </PopupOverlay>

      <Action onClick={() => setPopup(true)}>
        <Edit2FillIcon size={18} />
      </Action>
    </>
  );
};

export default CandidateStatusChangeAction;
