import React from "react";
import styled from "styled-components";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import HomePageGreeting from "src/Modules/Customer/Pages/Home/HomePageGreeting";
import { PageTitleLine } from "src/Modules/Customer/Components/CommonCustoemerLib";
import MainAndSideColumnsLayout, {
  MainColumn,
  SideColumn,
} from "src/Modules/Shared/Components/Layout/MainAndSideColumnsLayout";
import HomeJobCard from "src/Modules/Customer/Components/Home/HomeJobCard";
import CustomerBenefitsCard from "src/Modules/Customer/Components/Home/CustomerBenefitsCard";
import CompanyHomeCard from "src/Modules/Customer/Components/CompanyHomeCard";
import Card from "src/Modules/Recruiter/Pages/Home/Card";

const Container = styled.div``;

const HomeTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #105cf7;
  margin: 0;
`;

const HomePage: React.FC = (props) => {
  return (
    <Container>
      <FluidContentContainer>
        <HomeTitle>Home</HomeTitle>
        <HomePageGreeting />
        <PageTitleLine />
        <MainAndSideColumnsLayout>
          <MainColumn>
            <Card>
              <h1>hi</h1>
            </Card>
          </MainColumn>
          <SideColumn></SideColumn>
        </MainAndSideColumnsLayout>
      </FluidContentContainer>
    </Container>
  );
};

export default HomePage;
