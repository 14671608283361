import React, { useState } from "react";
import styled from "styled-components";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import PopupOverlay from "src/Modules/Core/Components/Experimental/PopupOverlay";
import { PageTitleLine } from "src/Modules/Customer/Components/CommonCustoemerLib";
import { Field, Form } from "react-final-form";
import {
  ButtonGroup,
  FormField,
  Label,
} from "src/Modules/Talent/Pages/TalentProfile/TalentProfileComponents";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import DatePickerField from "src/Modules/Shared/Components/Temp/DatePickerField";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Column } from "react-table";
import AccountProfileAvatar from "src/Modules/Shared/Components/Temp/AccountProfileAvatar";
import AdminTable from "src/Modules/Admin/Components/AdminTable/AdminTable";
import DateTimeWithRelativeInfo from "src/Modules/Admin/Components/AdminTable/DateTimeWithRelativeInfo";
import OrganizationTimeSheet from "src/Modules/Admin/Pages/Organization/OrganizationTimeSheet";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Card = styled.div`
  width: 760px;
  max-width: 94vw;

  padding: 24px;

  background: white;

  border-radius: 12px;

  position: relative;

  gap: 12px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 18px;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;

  width: 100%;

  background: #fafafb;
  border-radius: 12px;
`;

const CloseIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 24px;
  right: 24px;

  cursor: pointer;
`;

const List = styled.div`
  background: white;
  border-radius: 12px;
  padding: 24px;
`;

const AddHireSelection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const AddHireType = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  height: 32px;
  padding-bottom: 8px;
  &.active {
    color: #105cf7;
    border-bottom: 1px solid #105cf7;
  }
`;

const NotImplementedInfo = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  flex: 1;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 280px;
`;

const HiresPage: React.FC = (props) => {
  const [popup, setPopup] = useState<boolean>(false);
  const [hireType, setHireType] = useState("Nikabot");
  let { id, hireId } = useParams();

  const queryClient = useQueryClient();
  const createMutation = useMutation({
    mutationFn: (values) =>
      axios.post(`/api/organizations/v1/${id}/hires`, values),
    onSuccess: () => {
      setPopup(false);
      queryClient.invalidateQueries(["organizations", id, "hires"]);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (hireId) =>
      axios.delete(`/api/organizations/v1/${id}/hires/${hireId}`),
    onSuccess: () => {
      queryClient.invalidateQueries(["organizations", id, "hires"]);
    },
  });

  const indexQuery = useQuery({
    queryFn: () => axios.get(`/api/organizations/v1/${id}/hires`),
    queryKey: ["organizations", id, "hires", "list"],
  });

  if (indexQuery.isLoading) {
    return null;
  }

  let items = indexQuery.data?.data;

  const Columns: Column[] = [
    {
      Header: "Avatar",
      width: 20,
      accessor: (row) => (
        <AccountProfileAvatar
          name={row.nikabotUserDetails.name}
          profilePictureUrl={null}
        />
      ),
    },
    {
      Header: "Name",
      accessor: "nikabotUserDetails.name",
    },
    {
      Header: "User ID",
      accessor: "nikabotUserId",
    },
    {
      Header: "Project ID",
      accessor: "nikabotProjectId",
    },
    {
      Header: "Start Date",
      accessor: (row) => {
        return <DateTimeWithRelativeInfo format="PP" data={row.startDate} />;
      },
    },
    {
      Header: "Actions",
      accessor: (row) => {
        return (
          <Button
            variant="danger"
            size="small"
            disabled={deleteMutation.isPending}
            onClick={() => {
              deleteMutation.mutate(row.id);
            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  return (
    <Container>
      <PopupOverlay active={popup} onClose={() => setPopup(false)}>
        {({ onClose }) => (
          <Card>
            <CloseIcon
              onClick={() => {
                setPopup(false);
              }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_36_4546)">
                  <path
                    d="M16.0001 14.8217L20.1251 10.6967L21.3034 11.875L17.1784 16L21.3034 20.125L20.1251 21.3033L16.0001 17.1783L11.8751 21.3033L10.6968 20.125L14.8218 16L10.6968 11.875L11.8751 10.6967L16.0001 14.8217Z"
                    fill="#B8BCC7"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_36_4546">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(6 6)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </CloseIcon>
            <Title>Add a Hire</Title>
            <PageTitleLine />
            <AddHireSelection>
              <AddHireType
                className={hireType == "Nikabot" ? "active" : ""}
                onClick={() => {
                  setHireType("Nikabot");
                }}
              >
                Nikabot Identifier
              </AddHireType>
              <AddHireType
                className={hireType == "Talent" ? "active" : ""}
                onClick={() => {
                  setHireType("Talent");
                }}
              >
                Talent Profile
              </AddHireType>
            </AddHireSelection>
            {hireType == "Nikabot" && (
              <Form
                onSubmit={(values) => {
                  let dateValue = new Date(values.startDate);
                  let sendDate = { ...values };
                  sendDate.startDate = format(dateValue, "yyyy-MM-dd");
                  createMutation.mutate(sendDate);
                }}
                initialValues={{}}
                render={({ handleSubmit, submitting, values }) => (
                  <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <FormContainer>
                      <FormField style={{ width: "100%" }}>
                        <Label>Nikabot User ID</Label>
                        <Field
                          name={"nikabotUserId"}
                          component={TextInputField}
                        />
                      </FormField>
                      <FormField style={{ width: "100%" }}>
                        <Label>Nikabot Project ID</Label>
                        <Field
                          name={"nikabotProjectId"}
                          component={TextInputField}
                        />
                      </FormField>
                      <FormField style={{ width: "100%" }}>
                        <Label>Profile Picture Url</Label>
                        <Field
                          name={"profilePictureUrl"}
                          component={TextInputField}
                        />
                      </FormField>
                      <FormField style={{ width: "100%" }}>
                        <Label>Start date</Label>
                        <Field name={"startDate"} component={DatePickerField} />
                      </FormField>
                    </FormContainer>
                    <PageTitleLine />
                    <ButtonGroup>
                      <Button
                        size="medium"
                        variant="outline"
                        onClick={() => onClose()}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        size="medium"
                        style={{ width: 120, marginLeft: "auto" }}
                        onClick={() => null}
                      >
                        Add
                      </Button>
                    </ButtonGroup>
                  </form>
                )}
              />
            )}
            {hireType == "Talent" && (
              <FormContainer>
                <NotImplementedInfo>Not available</NotImplementedInfo>
              </FormContainer>
            )}
          </Card>
        )}
      </PopupOverlay>
      <Button
        variant="primary"
        size="medium"
        style={{ width: 120, marginLeft: "auto" }}
        onClick={() => setPopup(true)}
      >
        Add hire
      </Button>
      <List>
        <AdminTable data={items} columns={Columns} />
      </List>
      <OrganizationTimeSheet
        organizationId={id}
        hires={items}
        selectedHire={hireId}
      />
    </Container>
  );
};

export default HiresPage;
