import React, { useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import PopupOverlay from "src/Modules/Core/Components/Experimental/PopupOverlay";
import { PageTitleLine } from "src/Modules/Customer/Components/CommonCustoemerLib";
import { Field, Form } from "react-final-form";
import { FormLabel } from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import DeleteBack2LineIcon from "remixicon-react/DeleteBack2LineIcon";
import DeleteBin3LineIcon from "remixicon-react/DeleteBin3LineIcon";

const Card = styled.div`
  width: 760px;
  max-width: 96vw;

  padding: 24px;

  background: white;

  border-radius: 12px;

  position: relative;

  gap: 12px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 18px;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  flex: 1 1 0;

  &.grow2 {
    flex: 2 1 0;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px;
  gap: 24px;

  width: 100%;

  /* Notch/Neutral/50 */

  background: #fafafb;
  border-radius: 12px;
`;

const InputIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 26px;
  height: 26px;

  margin-right: 8px;
`;

const CancelButton = styled.button`
  height: 48px;
  padding: 12px 32px;
  background: none;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
`;

const SaveButton = styled.button`
  height: 48px;
  padding: 12px 32px;
  background: #105cf7;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    fill: #ffffff;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: #ededf0;
    color: #9fa4b3;
    cursor: default;
    border: none;
    svg {
      fill: #9fa4b3;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

const CloseIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 24px;
  right: 24px;

  cursor: pointer;
`;

const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    svg {
      //stroke: red;
      fill: red;
      color: red;
    }
    color: red;
  }
`;

const Tag = styled.div`
  background: #155e75;
  color: white;

  padding: 12px 20px;
  letter-spacing: 8px;
  font-weight: bold;
  text-transform: uppercase;

  border-radius: 6px;

  font-size: 20px;

  margin: 0 auto;
`;

const Text = styled.div`
  font-size: 14px;
  font-family: Inter;
  line-height: 1.5;

  margin: 0 auto;
`;

const DeleteInviteCodeAction: React.FC = (props) => {
  let [popup, setPopup] = useState(false);
  let queryClient = useQueryClient();

  let mutation = useMutation({
    mutationFn: BackofficeApi.organizations.inviteCodes.delete,
    onSuccess: () => {
      queryClient.invalidateQueries(["organizations-invite-codes"]);
      setPopup(false);
    },
  });

  return (
    <>
      <PopupOverlay active={popup} onClose={() => setPopup(false)}>
        {({ onClose }) => (
          <Card
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <CloseIcon
              onClick={() => {
                setPopup(false);
              }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_36_4546)">
                  <path
                    d="M16.0001 14.8217L20.1251 10.6967L21.3034 11.875L17.1784 16L21.3034 20.125L20.1251 21.3033L16.0001 17.1783L11.8751 21.3033L10.6968 20.125L14.8218 16L10.6968 11.875L11.8751 10.6967L16.0001 14.8217Z"
                    fill="#B8BCC7"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_36_4546">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(6 6)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </CloseIcon>
            <Row>
              <Title>Delete Code?</Title>
            </Row>
            <PageTitleLine />
            <Form
              onSubmit={(values) => {
                mutation.mutate(props.id);
              }}
              initialValues={{}}
              render={({ handleSubmit, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <FormContainer>
                    <PageTitleLine />

                    <Tag>{props.data.code}</Tag>

                    <Text>
                      The code will be deactivated and no new invites will be
                      accepted!
                    </Text>

                    <ButtonGroup>
                      <CancelButton
                        onClick={(event) => {
                          event.preventDefault();
                          setPopup(false);
                        }}
                      >
                        Keep it
                      </CancelButton>
                      <Button variant="danger" size="large" type="submit">
                        Bye!
                      </Button>
                    </ButtonGroup>
                  </FormContainer>
                </form>
              )}
            />
          </Card>
        )}
      </PopupOverlay>
      <Action>
        <DeleteBin3LineIcon
          color={"current"}
          size={18}
          onClick={() => setPopup(true)}
        />
      </Action>
    </>
  );
};

export default DeleteInviteCodeAction;
