import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AppLayout from "src/Modules/Shared/Components/Layout/AppLayout";
import { sidebarLinkListStyle } from "src/Modules/Shared/Components/Layout/sidebar.css";
import SidebarNavLink from "src/Modules/Shared/Components/Layout/SidebarNavLink";
import LogoutSectionSidebar from "src/Modules/Customer/Components/LogoutSectionSidebar";
import AccountCircleFillIcon from "remixicon-react/AccountCircleFillIcon";
import HomePage from "src/Modules/Recruiter/Pages/Home/HomePage";
import ProfilePage from "src/Modules/Recruiter/Pages/Profile/ProfilePage";
import JobsPage from "src/Modules/Recruiter/Pages/Jobs/JobsPage";
type Props = {};

const Content = () => {
  return (
    <Routes>
      <Route path={"/"} element={<HomePage />} />
      <Route path={"/profile"} element={<ProfilePage />} />
      <Route path={"/jobs"} element={<JobsPage />} />
    </Routes>
  );
};

const Navigation = () => {
  return (
    <div className={sidebarLinkListStyle}>
      <SidebarNavLink
        label={"Home"}
        to={"/"}
        icon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="current"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_117_6883)">
              <path
                d="M16.6666 16.6666C16.6666 16.8877 16.5789 17.0996 16.4226 17.2559C16.2663 17.4122 16.0543 17.5 15.8333 17.5H4.16665C3.94563 17.5 3.73367 17.4122 3.57739 17.2559C3.42111 17.0996 3.33331 16.8877 3.33331 16.6666V9.16664H0.833313L9.43915 1.34331C9.59257 1.20371 9.79255 1.12634 9.99998 1.12634C10.2074 1.12634 10.4074 1.20371 10.5608 1.34331L19.1666 9.16664H16.6666V16.6666ZM6.24998 10.8333C6.24998 11.8279 6.64507 12.7817 7.34833 13.485C8.05159 14.1882 9.00542 14.5833 9.99998 14.5833C10.9945 14.5833 11.9484 14.1882 12.6516 13.485C13.3549 12.7817 13.75 11.8279 13.75 10.8333H12.0833C12.0833 11.3858 11.8638 11.9157 11.4731 12.3064C11.0824 12.6971 10.5525 12.9166 9.99998 12.9166C9.44745 12.9166 8.91754 12.6971 8.52684 12.3064C8.13614 11.9157 7.91665 11.3858 7.91665 10.8333H6.24998Z"
                fill="current"
              />
            </g>
            <defs>
              <clipPath id="clip0_117_6883">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        }
      />
      <SidebarNavLink
        label="Profile"
        icon={<AccountCircleFillIcon color={null} size={18} />}
        to="/profile"
      />
      <SidebarNavLink
        label="Jobs"
        icon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="current"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.83329 4.16668V1.66668C5.83329 1.44566 5.92109 1.2337 6.07737 1.07742C6.23365 0.921141 6.44561 0.833344 6.66663 0.833344H13.3333C13.5543 0.833344 13.7663 0.921141 13.9225 1.07742C14.0788 1.2337 14.1666 1.44566 14.1666 1.66668V4.16668H17.5C17.721 4.16668 17.9329 4.25447 18.0892 4.41075C18.2455 4.56703 18.3333 4.779 18.3333 5.00001V16.6667C18.3333 16.8877 18.2455 17.0997 18.0892 17.2559C17.9329 17.4122 17.721 17.5 17.5 17.5H2.49996C2.27895 17.5 2.06698 17.4122 1.9107 17.2559C1.75442 17.0997 1.66663 16.8877 1.66663 16.6667V5.00001C1.66663 4.779 1.75442 4.56703 1.9107 4.41075C2.06698 4.25447 2.27895 4.16668 2.49996 4.16668H5.83329ZM7.49996 10.8333H3.33329V15.8333H16.6666V10.8333H12.5V13.3333H7.49996V10.8333ZM16.6666 5.83334H3.33329V9.16668H7.49996V7.50001H12.5V9.16668H16.6666V5.83334ZM9.16663 9.16668V11.6667H10.8333V9.16668H9.16663ZM7.49996 2.50001V4.16668H12.5V2.50001H7.49996Z"
              fill="current"
            />
          </svg>
        }
        to="/jobs"
      />
      <LogoutSectionSidebar />
    </div>
  );
};

const RecruiterApp: React.FC<Props> = (props) => {
  return null;
  return <AppLayout content={Content()} navigation={Navigation()} />;
};

export default RecruiterApp;
