import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;

  transition: 0.3s;

  &:hover {
    background: #f1f5f9;
    border-radius: 8px;
  }
`;

const InterestedCandidates = styled.div`
  color: #105cf7;
`;

const TotalCandidates = styled.div`
  color: #1c274a;
`;

const ShortlistCell: React.FC = (props) => {
  const data = props.data;

  let hasShortlist = false;

  let interestedCandidatesCount = data.candidateCountStats.interviewingCount;
  let totalCount = data.shortlistedCandidatesCount;

  if (["Matching", "On-Hold", "Job Ended"].includes(data.stage)) {
    hasShortlist = true;
  }

  if (!hasShortlist) {
    return <Container>Not available</Container>;
  }

  if (hasShortlist) {
    return (
      <Container>
        <Link to={`/jobs/${data.id}/shortlist`}>
          <TotalCandidates>{totalCount} Candidates</TotalCandidates>
          <InterestedCandidates>
            {interestedCandidatesCount} Interested
          </InterestedCandidates>
        </Link>
      </Container>
    );
  }

  return <Container>Shortlist - i</Container>;
};

export default ShortlistCell;
