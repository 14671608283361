import { StateCreator } from "zustand";

export interface AuthSlice {
  isAuthenticated: boolean;
  userID: string | null;
  roles: string[];
}

const createAuthSlice: StateCreator<AuthSlice, [], [], AuthSlice> = (
  set,
  get
) => ({
  isAuthenticated: false,
  userID: null,
  roles: [],
});

export { createAuthSlice };
