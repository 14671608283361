import React from "react";
import styled from "styled-components";
import { Field, FieldRenderProps } from "react-final-form";
import { FormLabel } from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import YearlySalaryInputField from "src/Modules/Shared/Components/Temp/YearlySalaryInputField";
import TextInputWithIcon from "src/Modules/Customer/Components/Jobs/TextInputWithIcon";
import SalaryInputWithIcon from "src/Modules/Customer/Components/Jobs/SalaryInputWithIcon";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";
import ToggleSwitchField from "src/Modules/Shared/Components/Temp/ToggleSwitchField";

const Container = styled.div`
  position: relative;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
  }
`;
const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  flex-grow: 1;
`;

const GrayDash = styled.div`
  width: 20px;
  margin: 0 6px;
  height: 20px;
  border-top: none;

  @media screen and (min-width: 600px) {
    border-top: 1px solid #9fa4b3;
    height: 1px;
  }
`;

const SeparateContainer = styled.div`
  width: 200px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #858b9d;
    margin-right: 8px;
  }
`;

const MiniLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #858b9d;
`;

const Spacer = styled.div`
  width: 1px;
  height: 12px;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  background: #f8faff;
  border-radius: 999px;
  margin-right: 8px;

  color: #105cf7;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  flex: 0 0 auto;
`;

const Input = styled.input;

type Props = FieldRenderProps<string, any>;

const JobSalary: React.FC<Props> = (props) => {
  let data = props.input.value;

  const intl = new Intl.NumberFormat("en-US", { minimumFractionDigits: 0 });

  return (
    <Container>
      <SeparateContainer>
        <span>Separate US and International</span>
        <Field
          // type="checkbox"
          name="salary.separateSalaries"
          component={ToggleSwitchField}
        />
      </SeparateContainer>
      <FormLabel>Salary Range</FormLabel>
      {data.separateSalaries && <MiniLabel>International Salary</MiniLabel>}
      <Row>
        <FormField>
          <Field
            name={"salary.min"}
            component={SalaryInputWithIcon}
            placeholder={"Min. per year"}
            icon={<Icon>$</Icon>}
            parse={(value) => value.replace(",", "")}
            format={(value) => intl.format(value)}
          />
        </FormField>
        <GrayDash />
        <FormField>
          <Field
            name={"salary.max"}
            component={SalaryInputWithIcon}
            placeholder={"Max. per year"}
            icon={<Icon>$</Icon>}
            parse={(value) => value.replace(",", "")}
            format={(value) => intl.format(value)}
          />
        </FormField>
      </Row>

      {data.separateSalaries && (
        <>
          <Spacer />
          <MiniLabel>US Salary</MiniLabel>
          <Row>
            <FormField>
              <Field
                name={"salary.us_min"}
                component={SalaryInputWithIcon}
                placeholder={"Min. per year"}
                icon={<Icon>$</Icon>}
                parse={(value) => value.replace(",", "")}
                format={(value) => {
                  return intl.format(value);
                }}
              />
            </FormField>
            <GrayDash />
            <FormField>
              <Field
                name={"salary.us_max"}
                component={SalaryInputWithIcon}
                placeholder={"Max. per year"}
                icon={<Icon>$</Icon>}
                parse={(value) => value.replace(",", "")}
                format={(value) => intl.format(value)}
              />
            </FormField>
          </Row>
        </>
      )}
    </Container>
  );
};

export default JobSalary;
