import { useEffect, useState } from "react";
import reactLogo from "./assets/react.svg";
import "./App.css";
import { Routes, Route, NavLink } from "react-router-dom";
import { boxStyle, catFactStyle, containerStyle } from "@design/styles.css";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import AppLayout from "src/Modules/Shared/Components/Layout/AppLayout";
import UnauthenticatedApp from "src/Modules/Shared/UnauthenticatedApp";
import useStore from "src/Modules/Shared/Slice/useStore";
import AppInitialLoadingPage from "src/Modules/Shared/Pages/AppInitialLoadingPage";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";
import AdminApp from "src/Modules/Admin/Components/AdminApp";
import TalentApplicationFlowPage from "src/Modules/Shared/Pages/TalentApplicationFlowPage";
import React from "react";
import CustomerApp from "src/Modules/Customer/CustomerApp";
import RecruiterApp from "src/Modules/Recruiter/RecruiterApp";
import axiosInstance from "src/Modules/Core/Service/Api";
import OrgRepApp from "src/Modules/OrgRep/OrgRepApp";
const Xd23 = React.lazy(() => import("src/Modules/Shared/TalentApp"));
const LazyCustomerApp = React.lazy(
  () => import("src/Modules/Customer/CustomerApp")
);
import * as Sentry from "@sentry/react";

function App() {
  // We check if we have an ongoing session.
  // const auth = useStore();
  const response = useQuery({
    queryKey: ["auth"],
    queryFn: () => axiosInstance.get("/api/auth/me"),
    staleTime: Infinity,
    retry: false,
  });

  let auth = response.data?.data ?? { isAuthenticated: false };

  if (response.isError) {
    auth = { isAuthenticated: false };
  }
  Sentry.setUser(null);
  Sentry.setUser({ id: auth.id, email: auth.email, username: auth.email });

  // If authenticated load the correct dashboard depending on roles.
  if (auth?.isAuthenticated) {
    if (auth.roles.includes("admin")) {
      return <AdminApp />;
    }

    if (auth.roles.includes("talent")) {
      return (
        <React.Suspense>
          <Xd23 />
        </React.Suspense>
      );
    }

    if (auth.roles.includes("recruiter")) {
      return (
        <React.Suspense>
          <RecruiterApp />
        </React.Suspense>
      );
    }

    if (auth.roles.includes("orgrep")) {
      return (
        <React.Suspense>
          <OrgRepApp />
        </React.Suspense>
      );
    }

    if (auth.roles.includes("customer")) {
      return (
        <React.Suspense>
          <LazyCustomerApp roles={auth.roles} />
        </React.Suspense>
      );
    }

    return <h1>Authenticated as {JSON.stringify(auth.roles)}</h1>;
  }

  // If unauthenticated and loading data wait for data.
  if (!auth.isAuthenticated && !response.isSuccess) {
    return <h1>Not auth, loading...</h1>;
  }

  // If unauthenticated and loaded state show sign in screen.
  if (!auth.isAuthenticated && response.isSuccess) {
    return <UnauthenticatedApp />;
  }

  return <h1>Not matched</h1>;
  //---------------------------------
}

export default App;
