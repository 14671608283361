import React, { useState } from "react";
import styled from "styled-components";
import {
  FormLabel,
  Spacer,
} from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import { Field, FormSpy } from "react-final-form";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import CommonMultipleSkillsSelect from "src/Modules/Shared/Components/Temp/CommonMultipleSkillsSelect";
import SelectField from "src/Modules/Shared/Components/Temp/SelectField";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import skills from "src/Modules/Shared/Components/Temp/skillOptions";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";
import skillOptions from "src/Modules/Shared/Components/Temp/skillOptions";
import JobSalary from "src/Modules/Customer/Components/Jobs/JobSalary";
import JobEquity from "src/Modules/Customer/Components/Jobs/JobEquity";
import HiringModelField from "src/Modules/Customer/Components/Jobs/HiringModelField";
import CountriesGroupedByRegionSelect from "src/Modules/Core/Components/Form/Select/CountriesGroupedByRegionSelect/CountriesGroupedByRegionSelect";
import JobTimezoneField from "src/Modules/Customer/Components/Jobs/JobTimezoneField";
import { timezoneOptions } from "src/Modules/Shared/Components/Temp/timezones";
import ToggleSwitchField from "src/Modules/Shared/Components/Temp/ToggleSwitchField";
import AreaInputField from "src/Modules/Shared/Components/Temp/AreaInputField";
import { FieldArray } from "react-final-form-arrays";
import { PageTitleLine } from "src/Modules/Customer/Components/CommonCustoemerLib";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }

  &.column {
    flex-direction: column;
  }
`;

const RowLines = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`;

const RowLineSpacer = styled.div`
  height: 8px;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 0;

  &.grow2 {
    flex: 2 1 0;
  }

  @media (min-width: 600px) {
    width: 48%;
  }
`;

const SimpleToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #1c274a;
    margin-left: 8px;
  }

  .label {
    color: #64748b;
  }

  .label.active {
    color: #0f172a;
  }
`;

const FieldSpacer = styled.div`
  width: 16px;
  height: 16px;
`;

const getFocusByRole = (role: string) => {
  switch (role) {
    case "Backend":
      return [
        {
          label: "API development & integration",
          value: "API development & integration",
        },
        {
          label: "Database management & optimization",
          value: "Database management & optimization",
        },
        { label: "Serverless architecture", value: "Serverless architecture" },
        { label: "Web frameworks", value: "Web frameworks" },
        { label: "Data modeling & design", value: "Data modeling & design" },
        { label: "Authentication systems", value: "Authentication systems" },
        { label: "API security management", value: "API security management" },
        {
          label: "Performance & cache optimization",
          value: "Performance & cache optimization",
        },
        {
          label: "Message queue systems (eg. RabbitMQ, Kafka)",
          value: "Message queue systems (eg. RabbitMQ, Kafka)",
        },
        {
          label: "Microservices architecture",
          value: "Microservices architecture",
        },
        { label: "Testing frameworks", value: "Testing frameworks" },
        {
          label: "Server-side rendering (SSR)",
          value: "Server-side rendering (SSR)",
        },
        {
          label: "Search engines & indexing",
          value: "Search engines & indexing",
        },
        {
          label: "Monitoring & logging systems",
          value: "Monitoring & logging systems",
        },
        {
          label: "Scalability & distributed systems",
          value: "Scalability & distributed systems",
        },
        {
          label: "Cloud infrastructure & deployment",
          value: "Cloud infrastructure & deployment",
        },
        {
          label: "Asynchronous programming",
          value: "Asynchronous programming",
        },
      ];
    case "Fullstack":
      return [
        { label: "Frontend development", value: "Frontend development" },
        { label: "Backend development", value: "Backend development" },
        { label: "Database management", value: "Database management" },
        { label: "API development", value: "API development" },
        { label: "Mobile application", value: "Mobile application" },
        { label: "Cloud infrastructure", value: "Cloud infrastructure" },
        { label: "CI/CD pipelines", value: "CI/CD pipelines" },
        {
          label: "UI/UX design & implementation",
          value: "UI/UX design & implementation",
        },
        { label: "Testing & QA", value: "Testing & QA" },
        { label: "Security management", value: "Security management" },
        {
          label: "Scalability & Performance optimization",
          value: "Scalability & Performance optimization",
        },
        {
          label: "Data modeling & architecture",
          value: "Data modeling & architecture",
        },
        {
          label: "Fullstack architecture & system integration",
          value: "Fullstack architecture & system integration",
        },
        {
          label: "Cross-platform development",
          value: "Cross-platform development",
        },
        {
          label: "Microservices architecture",
          value: "Microservices architecture",
        },
        {
          label: "Real-time applications & websocket",
          value: "Real-time applications & websocket",
        },
      ];
    case "Frontend":
      return [
        {
          label: "UI/UX design integration",
          value: "UI/UX design integration",
        },
        { label: "Responsive development", value: "Responsive development" },
        {
          label: "Single-page applications (SPAs)",
          value: "Single-page applications (SPAs)",
        },
        { label: "Web accessibility", value: "Web accessibility" },
        {
          label: "Performance optimization",
          value: "Performance optimization",
        },
        {
          label: "Cross-browser compatibility",
          value: "Cross-browser compatibility",
        },
        {
          label: "CSS pre-processors (eg. SAAS, LESS)",
          value: "CSS pre-processors (eg. SAAS, LESS)",
        },
        {
          label: "Frontend build tools & bundlers",
          value: "Frontend build tools & bundlers",
        },
        {
          label: "Progressive web applications",
          value: "Progressive web applications",
        },
        {
          label: "Testing frameworks (eg. Jest, Cypress)",
          value: "Testing frameworks (eg. Jest, Cypress)",
        },
        {
          label: "Architecture & component-based development",
          value: "Architecture & component-based development",
        },
        {
          label: "Search engine optimization (SEO)",
          value: "Search engine optimization (SEO)",
        },
        {
          label: "Animations & transitions",
          value: "Animations & transitions",
        },
        { label: "Security", value: "Security" },
      ];
    case "Mobile":
      return [
        {
          label: "API development & integration",
          value: "API development & integration",
        },
        {
          label: "Database management & optimization",
          value: "Database management & optimization",
        },
        { label: "Serverless architecture", value: "Serverless architecture" },
        { label: "Web frameworks", value: "Web frameworks" },
        { label: "Data modeling & design", value: "Data modeling & design" },
        { label: "Authentication systems", value: "Authentication systems" },
        { label: "API security management", value: "API security management" },
        {
          label: "Performance & cache optimization",
          value: "Performance & cache optimization",
        },
        {
          label: "Message queue systems (eg. RabbitMQ, Kafka)",
          value: "Message queue systems (eg. RabbitMQ, Kafka)",
        },
        {
          label: "Microservices architecture",
          value: "Microservices architecture",
        },
        { label: "Testing frameworks", value: "Testing frameworks" },
        {
          label: "Server-side rendering (SSR)",
          value: "Server-side rendering (SSR)",
        },
        {
          label: "Search engines & indexing",
          value: "Search engines & indexing",
        },
        {
          label: "Monitoring & logging systems",
          value: "Monitoring & logging systems",
        },
        {
          label: "Scalability & distributed systems",
          value: "Scalability & distributed systems",
        },
        {
          label: "Cloud infrastructure & deployment",
          value: "Cloud infrastructure & deployment",
        },
        {
          label: "Asynchronous programming",
          value: "Asynchronous programming",
        },
      ];
    case "DevOps":
      return [
        { label: "CI / CD", value: "CI / CD" },
        {
          label: "Infrastructure as code (IaC)",
          value: "Infrastructure as code (IaC)",
        },
        { label: "Cloud platforms", value: "Cloud platforms" },
        {
          label: "Containerization & orchestration",
          value: "Containerization & orchestration",
        },
        { label: "Automation & scripting", value: "Automation & scripting" },
        {
          label: "Configuration management",
          value: "Configuration management",
        },
        { label: "Monitoring & alerting", value: "Monitoring & alerting" },
        {
          label: "System Architecture & design",
          value: "System Architecture & design",
        },
        {
          label: "DevOps toolchain management",
          value: "DevOps toolchain management",
        },
        { label: "Release management", value: "Release management" },
        {
          label: "Performance optimization",
          value: "Performance optimization",
        },
        { label: "Security & compliance", value: "Security & compliance" },
        { label: "Version control", value: "Version control" },
        {
          label: "Application deployment / scaling",
          value: "Application deployment / scaling",
        },
        { label: "Cloud security", value: "Cloud security" },
        { label: "Serverless architecture", value: "Serverless architecture" },
        {
          label: "Application monitoring & logging",
          value: "Application monitoring & logging",
        },
        {
          label: "Environment provisioning",
          value: "Environment provisioning",
        },
        {
          label: "Performance testing / tuning",
          value: "Performance testing / tuning",
        },
      ];
    case "Data":
      return [
        { label: "Data warehousing", value: "Data warehousing" },
        { label: "ETL", value: "ETL" },
        { label: "Big data", value: "Big data" },
        {
          label: "Database design / optimization",
          value: "Database design / optimization",
        },
        { label: "Data modeling", value: "Data modeling" },
        { label: "Data integration", value: "Data integration" },
        { label: "Data pipelines", value: "Data pipelines" },
        { label: "Security", value: "Security" },
        { label: "Data analytics", value: "Data analytics" },
        { label: "Data visualization", value: "Data visualization" },
        {
          label: "Data mining / exploration",
          value: "Data mining / exploration",
        },
        { label: "Stream processing", value: "Stream processing" },
        { label: "Cloud data solutions", value: "Cloud data solutions" },
        { label: "Data storage", value: "Data storage" },
        { label: "Data migration", value: "Data migration" },
      ];
  }

  return [];
};

const TimezoneFieldContainer = styled.div`
  position: absolute;
  top: -42px;
  z-index: 200;
  right: 0;
  width: 400px;
  height: 30px;
`;

const OverlappingHoursOption = [
  { value: "0", label: "12:00 am" },
  { value: "1", label: "1:00 am" },
  { value: "2", label: "2:00 am" },
  { value: "3", label: "3:00 am" },
  { value: "4", label: "4:00 am" },
  { value: "5", label: "5:00 am" },
  { value: "6", label: "6:00 am" },
  { value: "7", label: "7:00 am" },
  { value: "8", label: "8:00 am" },
  { value: "9", label: "9:00 am" },
  { value: "10", label: "10:00 am" },
  { value: "11", label: "11:00 am" },
  { value: "12", label: "12:00 pm" },
  { value: "13", label: "1:00 pm" },
  { value: "14", label: "2:00 pm" },
  { value: "15", label: "3:00 pm" },
  { value: "16", label: "4:00 pm" },
  { value: "17", label: "5:00 pm" },
  { value: "18", label: "6:00 pm" },
  { value: "19", label: "7:00 pm" },
  { value: "20", label: "8:00 pm" },
  { value: "21", label: "9:00 pm" },
  { value: "22", label: "10:00 pm" },
  { value: "23", label: "11:00 pm" },
];

const OverlappingHoursType = [
  {
    value: "anytime_8",
    label: "8+ hours anytime between",
    hours: 8,
    type: "anytime",
  },
  {
    value: "specific_8",
    label: "8 hours specifically from",
    hours: 8,
    type: "specific",
  },
  {
    value: "anytime_7",
    label: "7+ hours anytime between",
    hours: 7,
    type: "anytime",
  },
  {
    value: "specific_7",
    label: "7 hours specifically from",
    hours: 7,
    type: "specific",
  },
  {
    value: "anytime_6",
    label: "6+ hours anytime between",
    hours: 6,
    type: "anytime",
  },
  {
    value: "specific_6",
    label: "6 hours specifically from",
    hours: 6,
    type: "specific",
  },
  {
    value: "anytime_5",
    label: "5+ hours anytime between",
    hours: 5,
    type: "anytime",
  },
  {
    value: "specific_5",
    label: "5 hours specifically from",
    hours: 5,
    type: "specific",
  },
  {
    value: "anytime_4",
    label: "4+ hours anytime between",
    hours: 4,
    type: "anytime",
  },
  {
    value: "specific_4",
    label: "4 hours specifically from",
    hours: 4,
    type: "specific",
  },
  {
    value: "anytime_3",
    label: "3+ hours anytime between",
    hours: 3,
    type: "anytime",
  },
  {
    value: "specific_3",
    label: "3 hours specifically from",
    hours: 3,
    type: "specific",
  },
  {
    value: "anytime_2",
    label: "2+ hours anytime between",
    hours: 2,
    type: "anytime",
  },
  {
    value: "specific_2",
    label: "2 hours specifically from",
    hours: 2,
    type: "specific",
  },
  {
    value: "anytime_1",
    label: "1+ hour anytime between",
    hours: 1,
    type: "anytime",
  },
  {
    value: "specific_1",
    label: "1 hour specifically from",
    hours: 1,
    type: "specific",
  },
];

function getCurrentOverlappingHoursType(value, options) {
  return options.find((i) => {
    return i.value == value;
  });
}

function OverlappingHoursStartTimeOptions(selection) {
  if (selection == null) {
    return OverlappingHoursOption;
  }

  return OverlappingHoursOption;
}

function OverlappingHoursEndTimeOption(selection, startTime) {
  if (selection == null) {
    return OverlappingHoursOption;
  }

  if (startTime == null) {
    return OverlappingHoursOption;
  }

  if (selection.type == "anytime") {
    const startHour = startTime.value;
    const hours = selection.hours;
    const endHour = (startHour + hours) % 24;

    let remainingOptions = [];

    if (startHour < endHour || true) {
      const split = OverlappingHoursOption.slice(
        endHour,
        Math.min(endHour + 8, 24)
      );
      //remainingOptions += split;
      remainingOptions = remainingOptions.concat(split);
      const remainingSlots = 8 - split.length;
      remainingOptions = remainingOptions.concat(
        OverlappingHoursOption.slice(0, remainingSlots)
      );
    }

    return remainingOptions;
  }

  return OverlappingHoursOption;
}

function getValueForEndTime(selection, startTime) {
  if (selection == null) {
    return null;
  }

  if (selection.type == "anytime") {
    return null;
  }

  if (startTime == null) {
    return false;
  }

  const startHour = startTime.value;
  const hours = selection.hours;
  const endHour = (startHour + hours) % 24;

  if (selection.type == "anytime") {
    return false;
  }

  return endHour;
}

const Pre = styled.pre`
  width: 100%;
  height: 200px;
  overflow-y: auto;
  background: #1c274a;
`;

const NewLineInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  width: 100%;
  height: 48px;

  background: #ffffff;
  /* Notch/Neutral/100 */

  border: 1px solid #ededf0;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  &:focus {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }

  &::placeholder {
    color: #9fa4b3;
  }
`;

const PrivacyLine = styled.div`
  margin: 24px 0;
  width: 100%;
  border-top: 1px solid #ededf0;
`;

const LockContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  background: #858b9d;
  border-radius: 6px;

  margin-right: 8px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.03em;
    color: #1c274a;
    margin-bottom: 0;
  }

  .body {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.03em;
    color: #858b9d;
  }
`;

const PrivateSection = styled.div`
  height: 56px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ededf0;
  border-radius: 6px;
  margin-bottom: 8px;

  color: #1c274a;
  cursor: pointer;
`;

const PrivacyTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 100%;

  margin-bottom: 24px;

  svg {
    margin-left: 8px;
  }
`;

const ClosePrivateSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;
`;

const OpenedPrivateSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ededf0;
  border-radius: 6px;
  margin-bottom: 8px;
  padding: 16px;
`;

function skillOptionFiltered(toRemoveSkills) {
  const rev = toRemoveSkills ?? [];

  if (toRemoveSkills == undefined) {
    return skillOptions;
  }

  const finalOptions = skillOptions.filter((i: any) => {
    if (
      rev.find((j) => {
        return j == i.value;
      })
    ) {
      return false;
    } else {
      return true;
    }
  });

  return finalOptions;
}

const hasEmptyLine = (values: Array<any>) => {
  if (values == undefined) {
    return false;
  }

  for (const [key, value] of Object.entries(values)) {
    if (value == "") {
      return true;
    }
  }

  return false;
};

const RequiredStar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  color: #105cf7;
  font-size: 16px;
  font-weight: bold;
`;

const isRequiredStar = (values: unknown) => {
  if (values?.jobLink == null || values?.jobLink == "") {
    return <RequiredStar>*</RequiredStar>;
  }

  return null;
};

const JobEditForm: React.FC = (props) => {
  const values = props.values;
  const form = props.form;

  const [sectionDiversity, setSectionDiversity] = useState(false);
  const [sectionExperience, setSectionExperience] = useState(false);
  const [sectionEducation, setSectionEducation] = useState(false);

  return (
    <Container>
      <FormSpy
        subscription={{ values: true, dirtyFields: true }}
        onChange={(props) => {
          if (props.dirtyFields.overlappingTimeNotRequired != undefined) {
            if (props.values.overlappingTimeNotRequired == true) {
              form.change("overlappingTimeStart", null);
              form.change("overlappingTimeTotalHours", null);
              form.change("overlappingTimeEnd", null);
            }
          }
        }}
      ></FormSpy>
      <Row>
        <FormField>
          <FormLabel>Job title{isRequiredStar(values)}</FormLabel>
          <Field
            name={"title"}
            component={TextInputField}
            placeholder="e.g. Senior Software Engineer"
          />
        </FormField>
      </Row>

      <Row>
        <FormField>
          <FormLabel>Required skills{isRequiredStar(values)}</FormLabel>
          <Field
            toRemove={[]}
            name={"requiredSkills"}
            options={skills}
            isMulti={true}
            component={DefaultSelectField}
            placeholder="Select skills"
          />
        </FormField>
      </Row>

      <Row>
        <FormField>
          <FormLabel>Nice to have skills</FormLabel>
          <Field
            options={skillOptionFiltered(values?.requiredSkills)}
            isMulti={true}
            name={"niceToHaveSkills"}
            component={DefaultSelectField}
            placeholder="Select skills"
          />
        </FormField>
      </Row>

      <Row>
        <FormField>
          <FormLabel>Role{isRequiredStar(values)}</FormLabel>
          <Field
            name="role"
            placeholder="Select role"
            component={DefaultSelectField}
            options={[
              { label: "Fullstack", value: "Fullstack" },
              { label: "Frontend", value: "Frontend" },
              { label: "Backend", value: "Backend" },
              { label: "Mobile", value: "Mobile" },
              { label: "DevOps", value: "DevOps" },
              { label: "Data", value: "Data" },
            ]}
          />
        </FormField>
        <FieldSpacer />
        <FormField>
          <FormLabel>Focus</FormLabel>
          <Field
            name="roleFocus"
            placeholder={values.role ? "Select focus" : "Select role first"}
            disabled={!values.role}
            isMulti={true}
            static={"MobileOnly"}
            component={DefaultSelectField}
            options={getFocusByRole(values.role)}
          />
        </FormField>
      </Row>
      <Row>
        <FormField>
          <FormLabel>Years of Experience{isRequiredStar(values)}</FormLabel>
          <Field
            name="totalExperienceYears"
            placeholder="Select minimum experience"
            component={DefaultSelectField}
            options={[
              { label: "1+ years", value: "1" },
              { label: "2+ years", value: "2" },
              { label: "3+ years", value: "3" },
              { label: "4+ years", value: "4" },
              { label: "5+ years", value: "5" },
              { label: "6+ years", value: "6" },
              { label: "7+ years", value: "7" },
              { label: "8+ years", value: "8" },
              { label: "9+ years", value: "9" },
              { label: "10+ years", value: "10" },
              { label: "11+ years", value: "11" },
              { label: "12+ years", value: "12" },
              { label: "13+ years", value: "13" },
              { label: "14+ years", value: "14" },
              { label: "15+ years", value: "15" },
            ]}
          />
        </FormField>
        <FieldSpacer />
        <FormField>
          <FormLabel>Hours{isRequiredStar(values)}</FormLabel>
          <Field
            name="hours"
            placeholder="Select hours"
            component={DefaultSelectField}
            options={[
              { label: "Full-time", value: "Full-time" },
              { label: "Part-time", value: "Part-time" },
              { label: "Open to either", value: "Open to either" },
            ]}
          />
        </FormField>
      </Row>
      <Row>
        <FormField>
          <Field name="salary" component={JobSalary} />
        </FormField>
      </Row>

      <Row>
        <FormField>
          <Field name="equity" component={JobEquity} />
        </FormField>
      </Row>

      <Row>
        <FormField>
          <FormLabel>Job Type{isRequiredStar(values)}</FormLabel>
          <Field
            name="jobType"
            placeholder="Select job type"
            component={DefaultSelectField}
            options={[
              { label: "Employee", value: "Employee" },
              { label: "Contractor", value: "Contractor" },
              { label: "Open to either", value: "Open to either" },
            ]}
          />
        </FormField>
        <FieldSpacer />
        {values.jobType == "Employee" && (
          <FormField>
            <FormLabel>Hiring Model</FormLabel>
            <Field
              name="hiringModel"
              placeholder="Select hiring model"
              component={HiringModelField}
              options={[
                {
                  label: "Direct Hire",
                  value: "Direct Hire",
                  description:
                    "Developer joins company directly and your team handles payroll, compliance, and benefits ",
                },
                {
                  label: "Cloud Hire",
                  value: "Cloud Hire",
                  description:
                    "Hire long-term team members while Notch handles payroll, compliance, and everything else.",
                },
                {
                  label: "Open to either",
                  value: "Open to either",
                  description: "Open to either Direct Hire or Cloud Hire",
                },
              ]}
              input={{ value: "Direct Hire" }}
              disabled={true}
            />
          </FormField>
        )}
        {values.jobType != "Employee" && (
          <FormField>
            <FormLabel>Hiring Model{isRequiredStar(values)}</FormLabel>
            <Field
              name="hiringModel"
              force={values.job_type}
              placeholder="Select hiring model"
              component={HiringModelField}
              options={[
                {
                  label: "Direct Hire",
                  value: "Direct Hire",
                  description:
                    "Developer joins company directly and your team handles payroll, compliance, and benefits ",
                },
                {
                  label: "Cloud Hire",
                  value: "Cloud Hire",
                  description:
                    "Hire long-term team members while Notch handles payroll, compliance, and everything else.",
                },
                {
                  label: "Open to either",
                  value: "Open to either",
                  description: "Open to either Direct Hire or Cloud Hire",
                },
              ]}
            />
          </FormField>
        )}
      </Row>
      <Row>
        <FormField>
          <FormLabel>Hiring Locations{isRequiredStar(values)}</FormLabel>
          <Field
            name={"hiringLocations"}
            component={CountriesGroupedByRegionSelect}
            placeholder="Select regions and countries"
            disabled={values.noRequiredLocations}
            isMulti={true}
          />
        </FormField>
      </Row>
      <Row style={{ marginTop: -16 }}>
        <SimpleToggleContainer>
          <Field
            name="noRequiredLocations"
            placeholder=""
            component={ToggleSwitchField}
          />
          <span
            className={[
              "label",
              values.noRequiredLocations ? "active" : "",
            ].join(" ")}
          >
            Open to hiring anywhere
          </span>
        </SimpleToggleContainer>
      </Row>

      <Row className="column">
        <Row className="column">
          <FormLabel>Timezone{isRequiredStar(values)}</FormLabel>
          <Row>
            <TimezoneFieldContainer>
              <Field
                name="overlappingTimeTimezone"
                placeholder="Select Timezone"
                component={JobTimezoneField}
                options={timezoneOptions}
              />
            </TimezoneFieldContainer>
            <FormField className="grow2">
              <Field
                disabled={values.overlappingTimeNotRequired}
                name="overlappingTimeTotalHours"
                placeholder="Select overlap hours"
                component={DefaultSelectField}
                options={OverlappingHoursType}
              />
            </FormField>
            <FieldSpacer />
            <FormField>
              <Field
                disabled={values.overlappingTimeNotRequired}
                name="overlappingTimeStart"
                placeholder="Start-time"
                component={DefaultSelectField}
                options={OverlappingHoursOption}
              />
            </FormField>
            <FieldSpacer />
            <FormField>
              <Field
                disabled={
                  values.overlappingTimeNotRequired ||
                  getCurrentOverlappingHoursType(
                    values.overlappingTimeTotalHours,
                    OverlappingHoursType
                  )?.type == "specific"
                }
                name="overlappingTimeEnd"
                placeholder="End-time"
                component={DefaultSelectField}
                input={{
                  value:
                    getValueForEndTime(
                      getCurrentOverlappingHoursType(
                        values.overlappingTimeTotalHours,
                        OverlappingHoursType
                      ),
                      getCurrentOverlappingHoursType(
                        values.overlappingTimeStart,
                        OverlappingHoursOption
                      )
                    ) ?? values.overlappingTimeEnd,
                  onChange: (value) => {
                    form.change("overlappingTimeEnd", value);
                  },
                }}
                options={OverlappingHoursEndTimeOption(
                  getCurrentOverlappingHoursType(
                    values.overlapping_hours,
                    OverlappingHoursType
                  ),
                  getCurrentOverlappingHoursType(
                    values.overlapping_hours_start,
                    OverlappingHoursOption
                  )
                )}
              />
            </FormField>
          </Row>
        </Row>

        <Row>
          <SimpleToggleContainer>
            <Field
              name="overlappingTimeNotRequired"
              placeholder=""
              component={ToggleSwitchField}
            />
            <span
              className={[
                "label",
                values.overlappingTimeNotRequired ? "active" : "",
              ].join(" ")}
            >
              No required overlap
            </span>
          </SimpleToggleContainer>
        </Row>
      </Row>

      <Row>
        <FormField>
          <FormLabel>Number of Openings{isRequiredStar(values)}</FormLabel>
          <Field
            name="openingsNumber"
            placeholder="Select number of openings"
            component={DefaultSelectField}
            options={[
              { value: "1", label: "1" },
              { value: "2", label: "2" },
              { value: "3", label: "3" },
              { value: "4", label: "4" },
              { value: "5", label: "5" },
              { value: "6", label: "6" },
              { value: "7", label: "7" },
              { value: "8", label: "8" },
              { value: "9", label: "9" },
              { value: "10", label: "10" },
              { value: "11", label: "11" },
              { value: "12", label: "12" },
              { value: "13", label: "13" },
              { value: "14", label: "14" },
              { value: "15", label: "15" },
              { value: "16", label: "16" },
              { value: "17", label: "17" },
              { value: "18", label: "18" },
              { value: "19", label: "19" },
              { value: "20", label: "20" },
            ]}
          />
        </FormField>
        <FieldSpacer />
        <FormField>
          <FormLabel>Visa Sponsorship{isRequiredStar(values)}</FormLabel>
          <Field
            name="visaSponsorship"
            placeholder="Select sponsorship"
            component={DefaultSelectField}
            options={[
              { value: "Yes", label: "Yes" },
              { value: "Not currently", label: "Not currently" },
              { value: "Will consider", label: "Will consider" },
            ]}
          />
        </FormField>
      </Row>
      <Row>
        <FormField>
          <FormLabel>Overview{isRequiredStar(values)}</FormLabel>
          <Field
            name="overview"
            component={AreaInputField}
            placeholder="Write an overview about the role"
          />
        </FormField>
      </Row>
      <Row>
        <FormField>
          <FormLabel>Responsibilities{isRequiredStar(values)}</FormLabel>
          <FieldArray name={"responsibilities"}>
            {({ fields }) => (
              <RowLines>
                {fields?.map((name, index) => {
                  return (
                    <>
                      <Field
                        onFocus={(event: FocusEvent) => {
                          //remove all empty fields but this
                          values.responsibilities?.forEach(
                            (i: any, ii: any) => {
                              if (i == undefined && index != ii) {
                                form.mutators.remove("responsibilities", ii);
                              }
                            }
                          );
                        }}
                        id={`respo${index}`}
                        name={`${name}`}
                        component={TextInputField}
                        placeholder={`Responsibility #${index + 1}`}
                      />
                      <RowLineSpacer />
                    </>
                  );
                })}
                {!hasEmptyLine(values.responsibilities) && (
                  <NewLineInput
                    onFocus={(event: FocusEvent) => {
                      //remove all empty fields but this
                      values.responsibilities?.forEach((i: any, ii: any) => {
                        if (i == undefined) {
                          form.mutators.remove("responsibilities", ii);
                        }
                      });
                    }}
                    placeholder="Describe responsibilities for the role"
                    onChange={(event) => {
                      event.target.disabled = true;
                      const index = values.responsibilities
                        ? values.responsibilities.length
                        : 0;
                      form.mutators.insert(
                        "responsibilities",
                        index,
                        event.target.value
                      );
                      event.target.value = "";
                      setTimeout(() => {
                        const el = document.getElementById("respo" + index);
                        el.focus();
                        event.target.disabled = false;
                      });
                    }}
                  />
                )}
              </RowLines>
            )}
          </FieldArray>
        </FormField>
      </Row>

      <Row>
        <FormField>
          <FormLabel>Requirements{isRequiredStar(values)}</FormLabel>
          <FieldArray name={"requirements"}>
            {({ fields }) => (
              <RowLines>
                {fields.map((name, index) => {
                  return (
                    <>
                      <Field
                        onFocus={(event: FocusEvent) => {
                          //remove all empty fields but this
                          values.requirements?.forEach((i: any, ii: any) => {
                            if (i == undefined && index != ii) {
                              form.mutators.remove("requirements", ii);
                            }
                          });
                        }}
                        id={`requirements_${index}`}
                        name={`${name}`}
                        component={TextInputField}
                        placeholder={`Requirement #${index + 1}`}
                      />
                      <RowLineSpacer />
                    </>
                  );
                })}
                {!hasEmptyLine(values.requirements) && (
                  <NewLineInput
                    onFocus={(event: FocusEvent) => {
                      //remove all empty fields but this
                      values.requirements?.forEach((i: any, ii: any) => {
                        if (i == undefined) {
                          form.mutators.remove("requirements", ii);
                        }
                      });
                    }}
                    placeholder={`Provide candidate requirements`}
                    onChange={(event) => {
                      event.target.disabled = true;
                      const index = values.requirements
                        ? values.requirements.length
                        : 0;
                      form.mutators.insert(
                        "requirements",
                        index,
                        event.target.value
                      );
                      event.target.value = "";
                      setTimeout(() => {
                        const el = document.getElementById(
                          "requirements_" + index
                        );
                        el.focus();
                        event.target.disabled = false;
                      });
                    }}
                  />
                )}
              </RowLines>
            )}
          </FieldArray>
        </FormField>
      </Row>

      <Row>
        <FormField>
          <FormLabel>Nice to haves</FormLabel>
          <FieldArray name={"bonusPoints"}>
            {({ fields }) => (
              <RowLines>
                {fields.map((name, index) => {
                  return (
                    <>
                      <Field
                        onFocus={(event: FocusEvent) => {
                          //remove all empty fields but this
                          values.bonusPoints?.forEach((i: any, ii: any) => {
                            if (i == undefined && index != ii) {
                              form.mutators.remove("bonusPoints", ii);
                            }
                          });
                        }}
                        id={`bonusPoints_${index}`}
                        name={`${name}`}
                        component={TextInputField}
                        placeholder={`Bonus #${index + 1}`}
                      />
                      <RowLineSpacer />
                    </>
                  );
                })}
                {!hasEmptyLine(values.requirements) && (
                  <NewLineInput
                    onFocus={(event: FocusEvent) => {
                      //remove all empty fields but this
                      values.bonusPoints?.forEach((i: any, ii: any) => {
                        if (i == undefined) {
                          form.mutators.remove("bonusPoints", ii);
                        }
                      });
                    }}
                    placeholder={`Provide anything that gives bonus points`}
                    onChange={(event) => {
                      event.target.disabled = true;
                      const index = values.bonusPoints
                        ? values.bonusPoints.length
                        : 0;
                      form.mutators.insert(
                        "bonusPoints",
                        index,
                        event.target.value
                      );
                      event.target.value = "";
                      setTimeout(() => {
                        const el = document.getElementById(
                          "bonusPoints_" + index
                        );
                        el.focus();
                        event.target.disabled = false;
                      });
                    }}
                  />
                )}
              </RowLines>
            )}
          </FieldArray>
        </FormField>
      </Row>

      <Row>
        <FormField>
          <FormLabel>Benefits</FormLabel>
          <Field
            name="benefits"
            component={AreaInputField}
            placeholder="Share any benefits you offer for this role"
          />
        </FormField>
      </Row>

      <Row>
        <FormField>
          <FormLabel>Additional Details</FormLabel>
          <Field
            name="additionalDetails"
            component={AreaInputField}
            placeholder="Share anything else about the role, ideal candidate, or the company"
          />
        </FormField>
      </Row>

      <Row>
        <FormField>
          <FormLabel>Interview Process</FormLabel>
          <Field
            name="interviewProcess"
            component={AreaInputField}
            placeholder="Provide a short overview of the interview process"
          />
        </FormField>
      </Row>

      {/*

      <PrivacyLine />

      <Row>
        <LockContainer>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_167_15858)">
              <path
                d="M15 6.66683H16.6667C16.8877 6.66683 17.0996 6.75463 17.2559 6.91091C17.4122 7.06719 17.5 7.27915 17.5 7.50016V17.5002C17.5 17.7212 17.4122 17.9331 17.2559 18.0894C17.0996 18.2457 16.8877 18.3335 16.6667 18.3335H3.33333C3.11232 18.3335 2.90036 18.2457 2.74408 18.0894C2.5878 17.9331 2.5 17.7212 2.5 17.5002V7.50016C2.5 7.27915 2.5878 7.06719 2.74408 6.91091C2.90036 6.75463 3.11232 6.66683 3.33333 6.66683H5V5.8335C5 4.50741 5.52678 3.23564 6.46447 2.29796C7.40215 1.36028 8.67392 0.833496 10 0.833496C11.3261 0.833496 12.5979 1.36028 13.5355 2.29796C14.4732 3.23564 15 4.50741 15 5.8335V6.66683ZM9.16667 13.1102V15.0002H10.8333V13.1102C11.1511 12.9267 11.3994 12.6436 11.5398 12.3046C11.6802 11.9656 11.7048 11.5898 11.6099 11.2354C11.5149 10.881 11.3057 10.5679 11.0146 10.3445C10.7235 10.1212 10.3669 10.0001 10 10.0001C9.63311 10.0001 9.27647 10.1212 8.9854 10.3445C8.69432 10.5679 8.48508 10.881 8.39012 11.2354C8.29517 11.5898 8.3198 11.9656 8.4602 12.3046C8.6006 12.6436 8.84893 12.9267 9.16667 13.1102ZM13.3333 6.66683V5.8335C13.3333 4.94944 12.9821 4.10159 12.357 3.47647C11.7319 2.85135 10.8841 2.50016 10 2.50016C9.11594 2.50016 8.2681 2.85135 7.64298 3.47647C7.01786 4.10159 6.66667 4.94944 6.66667 5.8335V6.66683H13.3333Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_167_15858">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </LockContainer>
        <Text>
          <div className="title">
            The info below will not be shown on your job description
          </div>
          <div className="body">
            It will only be used internally to increase our matching accuracy
          </div>
        </Text>
      </Row>

      {sectionDiversity && (
        <OpenedPrivateSection>
          <PrivacyTitle>
            Diversity
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_147_14591)">
                <path
                  d="M15 6.66683H16.6667C16.8877 6.66683 17.0996 6.75463 17.2559 6.91091C17.4122 7.06719 17.5 7.27915 17.5 7.50016V17.5002C17.5 17.7212 17.4122 17.9331 17.2559 18.0894C17.0996 18.2457 16.8877 18.3335 16.6667 18.3335H3.33333C3.11232 18.3335 2.90036 18.2457 2.74408 18.0894C2.5878 17.9331 2.5 17.7212 2.5 17.5002V7.50016C2.5 7.27915 2.5878 7.06719 2.74408 6.91091C2.90036 6.75463 3.11232 6.66683 3.33333 6.66683H5V5.8335C5 4.50741 5.52678 3.23564 6.46447 2.29796C7.40215 1.36028 8.67392 0.833496 10 0.833496C11.3261 0.833496 12.5979 1.36028 13.5355 2.29796C14.4732 3.23564 15 4.50741 15 5.8335V6.66683ZM9.16667 13.1102V15.0002H10.8333V13.1102C11.1511 12.9267 11.3994 12.6436 11.5398 12.3046C11.6802 11.9656 11.7048 11.5898 11.6099 11.2354C11.5149 10.881 11.3057 10.5679 11.0146 10.3445C10.7235 10.1212 10.3669 10.0001 10 10.0001C9.63311 10.0001 9.27647 10.1212 8.9854 10.3445C8.69432 10.5679 8.48508 10.881 8.39012 11.2354C8.29517 11.5898 8.3198 11.9656 8.4602 12.3046C8.6006 12.6436 8.84893 12.9267 9.16667 13.1102ZM13.3333 6.66683V5.8335C13.3333 4.94944 12.9821 4.10159 12.357 3.47647C11.7319 2.85135 10.8841 2.50016 10 2.50016C9.11594 2.50016 8.2681 2.85135 7.64298 3.47647C7.01786 4.10159 6.66667 4.94944 6.66667 5.8335V6.66683H13.3333Z"
                  fill="#B8BCC7"
                />
              </g>
              <defs>
                <clipPath id="clip0_147_14591">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <ClosePrivateSection
              onClick={() => {
                setSectionDiversity(false);
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_147_14618)">
                  <path
                    d="M9.99999 10.9766L14.125 6.85156L15.3033 8.0299L9.99999 13.3332L4.69666 8.0299L5.87499 6.85156L9.99999 10.9766Z"
                    fill="#9FA4B3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_147_14618">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </ClosePrivateSection>
          </PrivacyTitle>
          <Row>
            <FormField>
              <FormLabel>Race / Ethnicity</FormLabel>
              <Field
                name="race"
                component={DefaultSelectField}
                options={[
                  { v: 1, label: "Option A" },
                  { v: 2, label: "Option B" },
                  { v: 3, label: "Option C" },
                ]}
              />
            </FormField>
            <FieldSpacer />
            <FormField>
              <FormLabel>Gender Identity</FormLabel>
              <Field
                name="identity"
                component={DefaultSelectField}
                options={[
                  { v: 1, label: "Option A" },
                  { v: 2, label: "Option B" },
                  { v: 3, label: "Option C" },
                ]}
              />
            </FormField>
          </Row>
        </OpenedPrivateSection>
      )}

      {!sectionDiversity && (
        <PrivateSection
          onClick={() => {
            setSectionDiversity(true);
          }}
        >
          <span>Diversity</span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_147_14357)">
              <path
                d="M9.16669 9.1665V4.1665H10.8334V9.1665H15.8334V10.8332H10.8334V15.8332H9.16669V10.8332H4.16669V9.1665H9.16669Z"
                fill="#9FA4B3"
              />
            </g>
            <defs>
              <clipPath id="clip0_147_14357">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </PrivateSection>
      )}

      {sectionExperience && (
        <OpenedPrivateSection>
          <PrivacyTitle>
            Experience
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_147_14591)">
                <path
                  d="M15 6.66683H16.6667C16.8877 6.66683 17.0996 6.75463 17.2559 6.91091C17.4122 7.06719 17.5 7.27915 17.5 7.50016V17.5002C17.5 17.7212 17.4122 17.9331 17.2559 18.0894C17.0996 18.2457 16.8877 18.3335 16.6667 18.3335H3.33333C3.11232 18.3335 2.90036 18.2457 2.74408 18.0894C2.5878 17.9331 2.5 17.7212 2.5 17.5002V7.50016C2.5 7.27915 2.5878 7.06719 2.74408 6.91091C2.90036 6.75463 3.11232 6.66683 3.33333 6.66683H5V5.8335C5 4.50741 5.52678 3.23564 6.46447 2.29796C7.40215 1.36028 8.67392 0.833496 10 0.833496C11.3261 0.833496 12.5979 1.36028 13.5355 2.29796C14.4732 3.23564 15 4.50741 15 5.8335V6.66683ZM9.16667 13.1102V15.0002H10.8333V13.1102C11.1511 12.9267 11.3994 12.6436 11.5398 12.3046C11.6802 11.9656 11.7048 11.5898 11.6099 11.2354C11.5149 10.881 11.3057 10.5679 11.0146 10.3445C10.7235 10.1212 10.3669 10.0001 10 10.0001C9.63311 10.0001 9.27647 10.1212 8.9854 10.3445C8.69432 10.5679 8.48508 10.881 8.39012 11.2354C8.29517 11.5898 8.3198 11.9656 8.4602 12.3046C8.6006 12.6436 8.84893 12.9267 9.16667 13.1102ZM13.3333 6.66683V5.8335C13.3333 4.94944 12.9821 4.10159 12.357 3.47647C11.7319 2.85135 10.8841 2.50016 10 2.50016C9.11594 2.50016 8.2681 2.85135 7.64298 3.47647C7.01786 4.10159 6.66667 4.94944 6.66667 5.8335V6.66683H13.3333Z"
                  fill="#B8BCC7"
                />
              </g>
              <defs>
                <clipPath id="clip0_147_14591">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <ClosePrivateSection
              onClick={() => {
                setSectionExperience(false);
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_147_14618)">
                  <path
                    d="M9.99999 10.9766L14.125 6.85156L15.3033 8.0299L9.99999 13.3332L4.69666 8.0299L5.87499 6.85156L9.99999 10.9766Z"
                    fill="#9FA4B3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_147_14618">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </ClosePrivateSection>
          </PrivacyTitle>
          <Row>
            <FormField>
              <FormLabel>Types of companies worked for</FormLabel>
              <Field
                name="ex4"
                isMulti={true}
                component={DefaultSelectField}
                options={[
                  { v: 1, label: "Option A" },
                  { v: 2, label: "Option B" },
                  { v: 3, label: "Option C" },
                ]}
              />
            </FormField>
          </Row>

          <Row>
            <FormField>
              <FormLabel>Type of product built</FormLabel>
              <Field
                name="ex5"
                isMulti={true}
                component={DefaultSelectField}
                options={[
                  { v: 1, label: "Option A" },
                  { v: 2, label: "Option B" },
                  { v: 3, label: "Option C" },
                ]}
              />
            </FormField>
          </Row>

          <Row>
            <FormField>
              <FormLabel>Size of companies worked for</FormLabel>
              <Field
                name="ex6"
                isMulti={true}
                component={DefaultSelectField}
                options={[
                  { v: 1, label: "Option A" },
                  { v: 2, label: "Option B" },
                  { v: 3, label: "Option C" },
                ]}
              />
            </FormField>
          </Row>

          <Row>
            <FormField>
              <FormLabel>Types of teams worked on</FormLabel>
              <Field
                name="ex7"
                isMulti={true}
                component={DefaultSelectField}
                options={[
                  { v: 1, label: "Option A" },
                  { v: 2, label: "Option B" },
                  { v: 3, label: "Option C" },
                ]}
              />
            </FormField>
          </Row>

          <Row>
            <FormField>
              <FormLabel>Stage of companies worked for</FormLabel>
              <Field
                name="ex8"
                isMulti={true}
                component={DefaultSelectField}
                options={[
                  { v: 1, label: "Option A" },
                  { v: 2, label: "Option B" },
                  { v: 3, label: "Option C" },
                ]}
              />
            </FormField>
          </Row>

          <Row>
            <FormField>
              <FormLabel>Responsibilities</FormLabel>
              <Field
                name="ex9"
                isMulti={true}
                component={DefaultSelectField}
                options={[
                  { v: 1, label: "Option A" },
                  { v: 2, label: "Option B" },
                  { v: 3, label: "Option C" },
                ]}
              />
            </FormField>
          </Row>

          <Row>
            <FormField>
              <FormLabel>Preferred Companies</FormLabel>
              <Field
                name="ex10"
                isMulti={true}
                component={DefaultSelectField}
                options={[
                  { v: 1, label: "Option A" },
                  { v: 2, label: "Option B" },
                  { v: 3, label: "Option C" },
                ]}
              />
            </FormField>
          </Row>
        </OpenedPrivateSection>
      )}

      {!sectionExperience && (
        <PrivateSection
          onClick={() => {
            setSectionExperience(true);
          }}
        >
          <span>Experience</span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_147_14357)">
              <path
                d="M9.16669 9.1665V4.1665H10.8334V9.1665H15.8334V10.8332H10.8334V15.8332H9.16669V10.8332H4.16669V9.1665H9.16669Z"
                fill="#9FA4B3"
              />
            </g>
            <defs>
              <clipPath id="clip0_147_14357">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </PrivateSection>
      )}

      {sectionEducation && (
        <OpenedPrivateSection>
          <PrivacyTitle>
            Education
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_147_14591)">
                <path
                  d="M15 6.66683H16.6667C16.8877 6.66683 17.0996 6.75463 17.2559 6.91091C17.4122 7.06719 17.5 7.27915 17.5 7.50016V17.5002C17.5 17.7212 17.4122 17.9331 17.2559 18.0894C17.0996 18.2457 16.8877 18.3335 16.6667 18.3335H3.33333C3.11232 18.3335 2.90036 18.2457 2.74408 18.0894C2.5878 17.9331 2.5 17.7212 2.5 17.5002V7.50016C2.5 7.27915 2.5878 7.06719 2.74408 6.91091C2.90036 6.75463 3.11232 6.66683 3.33333 6.66683H5V5.8335C5 4.50741 5.52678 3.23564 6.46447 2.29796C7.40215 1.36028 8.67392 0.833496 10 0.833496C11.3261 0.833496 12.5979 1.36028 13.5355 2.29796C14.4732 3.23564 15 4.50741 15 5.8335V6.66683ZM9.16667 13.1102V15.0002H10.8333V13.1102C11.1511 12.9267 11.3994 12.6436 11.5398 12.3046C11.6802 11.9656 11.7048 11.5898 11.6099 11.2354C11.5149 10.881 11.3057 10.5679 11.0146 10.3445C10.7235 10.1212 10.3669 10.0001 10 10.0001C9.63311 10.0001 9.27647 10.1212 8.9854 10.3445C8.69432 10.5679 8.48508 10.881 8.39012 11.2354C8.29517 11.5898 8.3198 11.9656 8.4602 12.3046C8.6006 12.6436 8.84893 12.9267 9.16667 13.1102ZM13.3333 6.66683V5.8335C13.3333 4.94944 12.9821 4.10159 12.357 3.47647C11.7319 2.85135 10.8841 2.50016 10 2.50016C9.11594 2.50016 8.2681 2.85135 7.64298 3.47647C7.01786 4.10159 6.66667 4.94944 6.66667 5.8335V6.66683H13.3333Z"
                  fill="#B8BCC7"
                />
              </g>
              <defs>
                <clipPath id="clip0_147_14591">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <ClosePrivateSection
              onClick={() => {
                setSectionEducation(false);
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_147_14618)">
                  <path
                    d="M9.99999 10.9766L14.125 6.85156L15.3033 8.0299L9.99999 13.3332L4.69666 8.0299L5.87499 6.85156L9.99999 10.9766Z"
                    fill="#9FA4B3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_147_14618">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </ClosePrivateSection>
          </PrivacyTitle>
          <Row>
            <FormField></FormField>
          </Row>
        </OpenedPrivateSection>
      )}

      {!sectionEducation && (
        <PrivateSection
          onClick={() => {
            setSectionEducation(true);
          }}
        >
          <span>Education</span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_147_14357)">
              <path
                d="M9.16669 9.1665V4.1665H10.8334V9.1665H15.8334V10.8332H10.8334V15.8332H9.16669V10.8332H4.16669V9.1665H9.16669Z"
                fill="#9FA4B3"
              />
            </g>
            <defs>
              <clipPath id="clip0_147_14357">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </PrivateSection>
      )}
      */}
    </Container>
  );

  return (
    <Container>
      <FieldSpacer />
    </Container>
  );
};

export default JobEditForm;
