import React from "react";
import styled from "styled-components";
import { Column, useTable } from "react-table";

const Styles = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1c274a;

  box-sizing: border-box;

  table {
    border-collapse: collapse;
    width: 100%;

    tbody {
      tr {
        border-bottom: 1px solid #ededf0;

        &:last-child {
          border-bottom: none;
          td {
          }
        }

        td {
          height: 72px;
          padding: 0 8px;
          text-align: left;
        }
      }
    }

    thead {
      th {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #6a7288;
        height: 32px;
        text-align: left;

        padding: 0 8px;
      }
    }
  }
`;

type Props = {
  data: [];
  columns: Column[];
};

const AdminTable: React.FC<Props> = ({ data, columns }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <Styles>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.original?.id}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Styles>
  );
};

export default AdminTable;
