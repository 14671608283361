import React, { useState } from "react";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardTitle,
} from "src/Modules/Customer/Components/TalentProfile/TalentProfileCommon";

const Container = styled.div``;

const ShowMore = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #9fa4b3;
  margin: 20px 0 0 auto;

  cursor: pointer;
`;

const Summary: React.FC = (props) => {
  let p = props.data;

  let [full, setFull] = useState(false);

  let summary = p.Bio;

  let needsCut = summary.length > 80 * 5;

  if (!full && needsCut) {
    summary = summary.slice(0, 80 * 5);
  }

  return (
    <Card id="summary-card">
      <CardTitle>Summary</CardTitle>
      <CardBody>
        {summary}
        {needsCut && !full && <span>...</span>}
      </CardBody>
      {needsCut && (
        <>
          {full ? (
            <ShowMore onClick={() => setFull(false)}>...Show less</ShowMore>
          ) : (
            <ShowMore onClick={() => setFull(true)}>...Show more</ShowMore>
          )}
        </>
      )}
    </Card>
  );
};

export default Summary;
