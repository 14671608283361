import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 12px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  width: max-content;

  border-radius: 6px;
`;

const Incomplete = styled(Container)`
  background: #fafafb;
  color: #6a7288;
`;

const Published = styled(Container)`
  background: #fafafb;
  color: #105cf7;
`;

const InReview = styled(Container)`
  background: #f4fbfd;
  color: #1d7c93;
`;

const Matching = styled(Container)`
  background: #f8faff;
  color: #105cf7;
`;

const OnHold = styled(Container)`
  background: #fff8e2;
  color: #fbb32b;
`;

const Undefined = styled(Container)`
  background: black;
  color: red;
`;

type Props = {
  stage: string;
};

const JobStage: React.FC<Props> = (props) => {
  const stage = props.stage ?? "?";

  switch (stage) {
    case "Incomplete":
      return <Incomplete>Incomplete</Incomplete>;
    case "Published":
      return <Published>Published</Published>;
    case "In Review":
      return <InReview>In Review</InReview>;
    case "Matching":
      return <Matching>Matching</Matching>;
    case "On-Hold":
      return <OnHold>On-Hold</OnHold>;
    case "Job Ended":
      return <Incomplete>Job Ended</Incomplete>;
    default:
      return <Incomplete>Incomplete</Incomplete>;
  }
};

export default JobStage;
