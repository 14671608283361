import React from "react";
import useStore from "src/Modules/Shared/Slice/useStore";
import {
  Routes,
  Route,
  useParams,
  useSearchParams,
  redirect,
  Navigate,
} from "react-router-dom";
import TalentSignUpPage from "src/Modules/Shared/Pages/TalentSignUpPage";
import TalentApplicationFlowPage from "src/Modules/Shared/Pages/TalentApplicationFlowPage";
import CustomerSignUpPage from "src/Modules/Customer/Pages/OrganicSignupFlow/CustomerSignUpPage";
import CustomerFlowPage from "src/Modules/Shared/Pages/CustomerFlowPage";
import SignInPage from "src/Modules/Shared/Pages/SignInPage";
import UpdateForgottenPasswordPage from "src/Modules/Shared/Pages/UpdateForgottenPasswordPage";
import RecruiterRegistrationPage from "src/Modules/Recruiter/Pages/SignUpFlow/RecruiterRegistrationPage";
import CustomerInvitationPage from "src/Modules/Customer/Pages/OrganicSignupFlow/CustomerInvitationPage";
import CustomerInvitedPage from "src/Modules/Customer/Pages/OrganicSignupFlow/CustomerInvitedPage";
import CustomerMemberInvitePage from "src/Modules/Customer/Pages/OrganicSignupFlow/CustomerMemberInvitedPage";

const UnauthenticatedApp: React.FC = (props) => {
  let auth = null;
  auth = useStore();

  return (
    <>
      <Routes>
        <Route path="/" element={<SignInPage />} />
        <Route path="/join-customer" element={<CustomerSignUpPage />} />
        <Route path="/invitation/:token" element={<CustomerInvitedPage />} />
        <Route
          path="/invitation-m/:token"
          element={<CustomerMemberInvitePage />}
        />

        <Route path="/customer-survey" element={<CustomerFlowPage />} />
        <Route
          path="/password-reset/:token"
          element={<UpdateForgottenPasswordPage />}
        />
        <Route path="/*" element={<Navigate to={"/"} />} />
      </Routes>
    </>
  );
};

export default UnauthenticatedApp;
