import React from "react";
import styled from "styled-components";
import CompanyLogo from "src/Modules/Customer/Components/CompanyLogo";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CompanyName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  text-align: left;
  min-width: 130px;
`;

const LogoContainer = styled.div`
  width: 32px;
  height: 32px;

  margin-right: 12px;

  * {
    width: 32px;
    height: 32px;
  }
`;

type Props = {
  name: string;
  url: string;
};

const CompanyCell: React.FC<Props> = (props) => {
  return (
    <Container>
      <LogoContainer>
        <CompanyLogo src={props.url} />
      </LogoContainer>
      <CompanyName>{props.name}</CompanyName>
    </Container>
  );
};

export default CompanyCell;
