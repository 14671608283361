import React from "react";
import styled from "styled-components";
import { Field, FieldRenderProps } from "react-final-form";
import { FormLabel } from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import YearlySalaryInputField from "src/Modules/Shared/Components/Temp/YearlySalaryInputField";
import TextInputWithIcon from "src/Modules/Customer/Components/Jobs/TextInputWithIcon";
import EquityInputWithIcon from "src/Modules/Customer/Components/Jobs/EquityInputWithIcon";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";

const Container = styled.div`
  position: relative;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
  }
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  flex-grow: 1;
`;

const GrayDash = styled.div`
  width: 20px;
  margin: 0 6px;
  height: 20px;
  border-top: none;

  @media screen and (min-width: 600px) {
    border-top: 1px solid #9fa4b3;
    height: 1px;
  }
`;

const SeparateContainer = styled.div`
  width: 200px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #858b9d;
    margin-right: 8px;
  }
`;

const MiniLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #858b9d;
`;

const Spacer = styled.div`
  width: 1px;
  height: 12px;
`;

const Input = styled.input;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  background: #fafafb;
  border-radius: 999px;
  margin-right: 8px;

  color: #565e78;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  flex: 0 0 auto;
`;

type Props = FieldRenderProps<string, any>;

const JobEquity: React.FC<Props> = (props) => {
  const data = props.input.value;

  const intl = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 6,
  });

  return (
    <Container>
      <FormLabel>Equity Range</FormLabel>
      <Row>
        <FormField>
          <Field
            name={"equity.min"}
            component={EquityInputWithIcon}
            placeholder={"Min. equity"}
            icon={<Icon>%</Icon>}
            parse={(value) => value}
            format={(value) => value}
          />
        </FormField>
        <GrayDash />
        <FormField>
          <Field
            name={"equity.max"}
            component={EquityInputWithIcon}
            placeholder={"Max. equity"}
            icon={<Icon>%</Icon>}
            parse={(value) => value}
            format={(value) => value}
          />
        </FormField>
      </Row>
    </Container>
  );
};

export default JobEquity;
