import React, { useRef } from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";

const Icon = styled.div`
  width: 24px;
  height: 24px;
  background: #f8faff;
  border-radius: 999px;
  margin-right: 8px;

  color: #105cf7;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  flex: 0 0 auto;
`;

const Input = styled.input`
  background: white;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  border: 1px solid transparent;
  outline: none;
  flex: 1 1 auto;
  width: 120px;

  &::placeholder {
    color: #9fa4b3;
  }
`;

const Label = styled.div`
  margin-left: auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;
  flex: 0 0 auto;
  color: #9fa4b3;
  width: 130px;
  display: flex;
`;

const BigContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  padding: 12px;

  width: 100%;
  height: 48px;

  background: #ffffff;
  /* Notch/Neutral/100 */

  border: 1px solid #ededf0;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  &.active {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }

  &::placeholder {
    color: #9fa4b3;
  }
`;

const MonthlyRate = styled.div`
  display: inline-flex;
  flex-direction: row;
  padding: 4px 12px;
  height: 32px;
  width: 140px;
  flex: 1;
  margin-top: 4px;

  background: #fafafb;

  border: 1px solid #ededf0;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #9fa4b3;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    letter-spacing: -0.03em;

    color: #1c274a;
    margin-left: 4px;
  }
`;

type Props = FieldRenderProps<number, any>;

const numberOnChange = (value: string, pastValue: number): number => {
  value = value.replace(",", "");
  const asNumber = Number(value);

  if (Number.isNaN(asNumber)) {
    return pastValue;
  }

  return asNumber > 999999 ? 999999 : asNumber;
};

const monthlyCalc = (value: any) => {
  let asNumber = Number(value);
  if (Number.isNaN(asNumber)) {
    return "";
  }

  asNumber = asNumber / 12;

  return Math.trunc(asNumber).toLocaleString();
};

const TextInputWithIcon: React.FC<Props> = (props) => {
  const ref = useRef<HTMLInputElement>(null);

  let value = props.input.value;

  if (value == "NaN") {
    value = 0;
  }

  if (value == 0) {
    value = "";
  }

  return (
    <BigContainer>
      <Container
        className={ref.current == document.activeElement ? "active" : ""}
      >
        {props.icon}
        <Input
          ref={ref}
          onChange={(value: any) => {
            const result = numberOnChange(
              value.target.value,
              props.input.value
            );
            if (Number.isNaN(result)) {
              props.input.onChange(0);
            } else {
              props.input.onChange(String(result));
            }
          }}
          value={value}
          {...props}
        />
      </Container>
    </BigContainer>
  );
};

export default TextInputWithIcon;
