import React, { useState } from "react";
import styled from "styled-components";
import {
  Card,
  CardTitle,
  SeparationLink,
  Skill,
} from "src/Modules/Customer/Components/TalentProfile/TalentProfileCommon";
import PencilRuler2LineIcon from "remixicon-react/PencilRuler2LineIcon";
import ArrowRightUpFillIcon from "remixicon-react/ArrowRightUpFillIcon";
import ArrowRightUpLineIcon from "remixicon-react/ArrowRightUpLineIcon";
import ProjectImage from "src/Modules/Customer/Components/TalentProfile/ProjectImage";

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #7dd3fc;
  border-radius: 6px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Name = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const Role = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const Description = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;

  margin: 4px 0;

  img {
    width: 172px;
    height: 96px;
    border-radius: 6px;
  }
`;

const SkillLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-top: 8px;
`;

const SkillList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

const Link = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px 4px 16px;
  gap: 4px;

  margin-top: 20px;

  width: 150px;
  height: 32px;

  border: 1px solid #105cf7;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;

  cursor: pointer;
`;

const ShowAll = styled.div`
  width: 100%;
  height: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-top: 20px;

  cursor: pointer;
`;

const ShowAllLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;

  width: 160px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ShowAllLine = styled.div`
  flex: 1;
  height: 1px;
  background: #ededf0;
`;

const isEmpty = (data) => {
  if (data == null) {
    return true;
  }

  if (data == "") {
    return true;
  }

  if (data == undefined) {
    return true;
  }

  return false;
};

const Projects: React.FC = (props) => {
  let [showAll, setShowAll] = useState(false);

  let projects = props.data.Projects;

  let displayedItems = [];

  if (showAll) {
    displayedItems = projects;
  } else {
    displayedItems = projects.slice(0, 3);
  }

  if (projects.length == 0) {
    return null;
  }

  return (
    <Card>
      <CardTitle>Projects</CardTitle>
      <List>
        {displayedItems.map((i: any) => {
          return (
            <>
              <Item>
                <Icon>
                  <PencilRuler2LineIcon size={20} />
                </Icon>
                <Content>
                  <Name>{i.Name}</Name>
                  <Role>{i.Role}</Role>
                  <Description>
                    <ProjectImage
                      src={`https://api.urlbox.io/v1/16Vr95oU2So1utXd/png?url=${i.Url}&click_accept=true&hide_cookie_banners=true&thumb_width=280&wait_until=requestsfinished&max_height=1600&ttl=40000`}
                    />
                    {i.Description}
                  </Description>
                  <SkillLabel>Skills</SkillLabel>
                  <SkillList>
                    {i.Skills.map((i) => {
                      return <Skill>{i}</Skill>;
                    })}
                  </SkillList>
                  {!isEmpty(i.Url) && (
                    <Link href={i.Url} target={"_blank"}>
                      Show Project <ArrowRightUpLineIcon />
                    </Link>
                  )}
                </Content>
              </Item>
              <SeparationLink />
            </>
          );
        })}
      </List>
      {projects.length > 3 && !showAll && (
        <ShowAll onClick={() => setShowAll(true)}>
          <ShowAllLine />
          <ShowAllLabel>Show all project</ShowAllLabel>
          <ShowAllLine />
        </ShowAll>
      )}
    </Card>
  );
};

export default Projects;
