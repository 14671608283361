import React from "react";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CustomerApi } from "src/Modules/Customer/Services/CustomerApi";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import AttachJobToOrganizationAction from "src/Modules/Admin/Components/Pages/Jobs/AttachJobToOrganizationAction";
import { JobStage } from "src/Modules/Core/Types/JobTypes";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";

const Container = styled.div`
  background: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ededf0;
  border-radius: 12px;
  padding: 12px 24px;
`;

const Header = styled.div`
  background: #f4fbfd;
  border-radius: 12px 12px 0px 0px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  min-height: 84px;

  border-bottom: 1px solid #ededf0;

  &:last-child {
    border: none;
  }
`;

const Number = styled.div`
  width: 24px;
  height: 24px;
  background: #1d7c93;
  border-radius: 999px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;

  flex: 1;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 4px;
`;

const Body = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #565e78;
`;

const CompanyName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #565e78;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
`;

const AdminJobExtraCard: React.FC = (props) => {
  let jobId = props.jobId;
  const queryClient = useQueryClient();
  const jobQuery = useQuery(CustomerApi.jobs.get(jobId));

  if (jobQuery.isLoading) {
    return null;
  }

  let job = jobQuery.data?.data;
  let details = job.jobDetails;

  const approveJobMutation = useMutation({
    mutationFn: BackofficeApi.jobs.id(jobId).approve,
    onSuccess: () => {
      queryClient.invalidateQueries(["jobs"]);
    },
  });

  const rejectJobMutation = useMutation({
    mutationFn: BackofficeApi.jobs.id(jobId).reject,
    onSuccess: () => {
      queryClient.invalidateQueries(["jobs"]);
    },
  });

  return (
    <Container>
      <Title>Company</Title>
      {job.organization ? (
        <Row>
          <CompanyLogo size={32} src={job.organization.details.logoUrl} />
          <CompanyName>{job.organization.details.name}</CompanyName>
        </Row>
      ) : (
        <Row>
          <AttachJobToOrganizationAction jobId={job.id} />;
        </Row>
      )}
      <Row />
      <Title>Actions</Title>
      {job.stage == JobStage.InReview && (
        <Row>
          <Button
            onClick={() => approveJobMutation.mutate()}
            disabled={queryClient.isMutating()}
            variant="primary"
          >
            Approve
          </Button>
          <Button
            onClick={() => rejectJobMutation.mutate()}
            disabled={queryClient.isMutating()}
            variant="danger"
          >
            Reject
          </Button>
        </Row>
      )}
    </Container>
  );
};

export default AdminJobExtraCard;
