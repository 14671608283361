import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const Container = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.04em;
  color: #1c274a;
  margin: 0;
  margin-bottom: 8px;
`;

const HomePageGreeting: React.FC = (props) => {
  const authQuery = useQuery({
    queryKey: ["auth"],
    queryFn: () => axios.get("/api/auth/me"),
  });

  let [welcomeGreeting, setWelcomeGreeting] = useState(false);

  useEffect(() => {
    if (
      authQuery.data?.data.flags.includes("ShowCustomerWelcome") &&
      !welcomeGreeting
    ) {
      setWelcomeGreeting(true);
    }
  }, [authQuery]);

  if (authQuery.isLoading) {
    return null;
  }

  let data = authQuery.data?.data;
  let firstName = data.name.slice(0, data.name.indexOf(" "));

  if (welcomeGreeting) {
    return <Container>Welcome to Notch, {firstName}</Container>;
  }

  const now = new Date();
  const hours = now.getHours();
  let greeting = "";

  if (hours < 4) {
    greeting = "Good evening";
  } else if (hours < 11) {
    greeting = "Good morning";
  } else if (hours < 16) {
    greeting = "Good day";
  } else {
    greeting = "Good evening";
  }

  return (
    <Container>
      {greeting}, {firstName}
    </Container>
  );
};

export default HomePageGreeting;
