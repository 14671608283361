import React from "react";
import styled from "styled-components";
import {
  Card,
  CardTitle,
} from "src/Modules/Customer/Components/TalentProfile/TalentProfileCommon";
import skillOptions from "src/Modules/Shared/Components/Temp/skillOptions";

const Label = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  margin-bottom: 12px;
`;

const SkillList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  margin-bottom: 24px;
`;

const Skill = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 32px;
  width: auto;
  background: #f4fbfd;
  border-radius: 6px;
  gap: 8px;
  padding: 0 8px;

  img {
    width: 20px;
    aspect-ratio: 1;
  }

  div.name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #1d7c93;
  }

  div.years {
    height: 24px;
    width: auto;

    padding: 0 8px;
    background: #1d7c93;
    border-radius: 999px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #f4fbfd;
  }
`;

const Skills: React.FC = (props) => {
  let d = props.data;

  let hasPrimarySkills = d.TopSkills.length > 0;
  let hasAdditionalSkills = d.AdditionalSkills.length > 0;

  return (
    <Card>
      <CardTitle>Skills</CardTitle>
      {hasPrimarySkills && (
        <>
          <Label>{!hasAdditionalSkills ? "" : "Primary Skills"}</Label>
          <SkillList>
            {d.TopSkills.map((skill: any) => {
              return (
                <Skill>
                  <img
                    src={`https://app.notchteam.com/images/icons/skills/${skill.Logo}`}
                    onError={(event) => {
                      event.target.parentNode.removeChild(event.target);
                    }}
                  />
                  <div className="name">{skill.Name}</div>
                  <div className="years">{skill.Experience}</div>
                </Skill>
              );
            })}
          </SkillList>
        </>
      )}

      {hasAdditionalSkills && (
        <>
          <Label>{hasPrimarySkills ? "Additional Skills" : ""}</Label>
          <SkillList>
            {d.AdditionalSkills.map((skill: any) => {
              return (
                <Skill>
                  <div className="name">{skill.name}</div>
                  {skill.years != "1y" && (
                    <div className="years">{skill.years}</div>
                  )}
                </Skill>
              );
            })}
          </SkillList>
        </>
      )}
    </Card>
  );
};

export default Skills;
