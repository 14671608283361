import styled from "styled-components";
import PencilRulerLineIcon from "remixicon-react/PencilRulerLineIcon";

export const AddAction = styled.div`
  color: #105cf7;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  margin-left: auto;

  cursor: pointer;
`;

export const FormField = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
`;

export const Label = styled.div`
  color: var(--Notch-Neutral-900, #1c274a);

  /* Notch/Label/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;
`;

export const EditFooterLine = styled.div`
  width: 100%;
  height: 1px;
  background: #ededf0;
  margin: 24px auto;
`;

export const GrayLineSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: #ededf0;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const CancelEditAction = styled.div`
  cursor: pointer;
  color: #9fa4b3;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.42px;
`;

export const DeleteAction = styled.div`
  cursor: pointer;
  color: red;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.42px;
  margin-right: auto;
`;

export const EditAction = (props) => {
  let Container = styled.div`
    margin-left: auto;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -20px;
  `;

  return (
    <Container {...props}>
      <PencilRulerLineIcon color={"gray"} size={14} />
    </Container>
  );
};

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const GrayDotSeparator = styled.div`
  width: 4px;
  aspect-ratio: 1;
  border-radius: 888px;
  height: 4px;
  background: #d2d4db;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  @media (min-width: 720px) {
    flex-direction: row;
  }
`;

export const EntryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
