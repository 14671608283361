import styled from "styled-components";

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 12px;

  display: flex;
  flex-direction: column;

  padding: 24px;

  min-height: 20px;
  width: 100%;
`;

export const CardTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;

  margin-bottom: 24px;
`;

export const CardBody = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

export const Skill = styled.div`
  height: 32px;
  background: #f4fbfd;
  border: 1px solid #fafafb;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #0f414d;
  padding: 0 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SeparationLink = styled.div`
  height: 1px;
  width: 100%;
  background: #f1f5f9;

  &:last-child {
    display: none;
  }
`;
