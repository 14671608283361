import React from "react";
import styled from "styled-components";
import {
  Card,
  CardTitle,
} from "src/Modules/Customer/Components/TalentProfile/TalentProfileCommon";

const Container = styled.div``;

const List = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 24px;
  background: #fafafb;
  border-radius: 6px;
  padding: 0 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #6a7288;
`;

const Interests: React.FC = (props) => {
  let interests = props.data.Interests;

  return (
    <Card>
      <CardTitle>Interests</CardTitle>
      <List>
        {interests.map((i) => {
          return <Item>{i}</Item>;
        })}
      </List>
    </Card>
  );
};

export default Interests;
