import React from "react";
import styled from "styled-components";
import AppLayout from "src/Modules/Shared/Components/Layout/AppLayout";
import { Route, Routes } from "react-router-dom";
import JobView from "src/Modules/Customer/Pages/Jobs/JobView";
import JobEditPage from "src/Modules/Customer/Pages/JobEditPage";
import JobShortlistPage from "src/Modules/Customer/Pages/JobShortlistPage";
import JobsListing from "src/Modules/Customer/Pages/Jobs/JobsListing";
import CustomerHomeCallSchedule from "src/Modules/Shared/Components/Temp/CustomerHomeCallSchedule";
import WidthMeasurerPage from "src/Modules/Customer/Pages/WidthMeasurerPage";
import MembersPage from "src/Modules/Customer/Pages/Account/MembersPage";
import CandidateProfilePage from "src/Modules/Customer/Pages/CandidateProfilePage";
import TimeTest from "src/Modules/Customer/Pages/TimeTest";
import HomePage from "src/Modules/Customer/Pages/Home/HomePage";
import { sidebarLinkListStyle } from "src/Modules/Shared/Components/Layout/sidebar.css";
import SidebarNavLink from "src/Modules/Shared/Components/Layout/SidebarNavLink";
import AccountCircleFillIcon from "remixicon-react/AccountCircleFillIcon";
import LogoutSectionSidebar from "src/Modules/Customer/Components/LogoutSectionSidebar";
import AccountPage from "src/Modules/OrgRep/Account/AccountPage";
import ProfilePage from "src/Modules/OrgRep/Account/ProfilePage";

const Content = () => {
  return (
    <Routes>
      <Route path="/account" element={<AccountPage />}>
        <Route path="profile" element={<ProfilePage />} />
        <Route path="profile2" element={<></>} />
        <Route path="profile3" element={<></>} />
      </Route>
    </Routes>
  );
};

const Navigation = () => {
  return (
    <div className={sidebarLinkListStyle}>
      <SidebarNavLink
        icon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="current"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_117_6883)">
              <path
                d="M16.6666 16.6666C16.6666 16.8877 16.5789 17.0996 16.4226 17.2559C16.2663 17.4122 16.0543 17.5 15.8333 17.5H4.16665C3.94563 17.5 3.73367 17.4122 3.57739 17.2559C3.42111 17.0996 3.33331 16.8877 3.33331 16.6666V9.16664H0.833313L9.43915 1.34331C9.59257 1.20371 9.79255 1.12634 9.99998 1.12634C10.2074 1.12634 10.4074 1.20371 10.5608 1.34331L19.1666 9.16664H16.6666V16.6666ZM6.24998 10.8333C6.24998 11.8279 6.64507 12.7817 7.34833 13.485C8.05159 14.1882 9.00542 14.5833 9.99998 14.5833C10.9945 14.5833 11.9484 14.1882 12.6516 13.485C13.3549 12.7817 13.75 11.8279 13.75 10.8333H12.0833C12.0833 11.3858 11.8638 11.9157 11.4731 12.3064C11.0824 12.6971 10.5525 12.9166 9.99998 12.9166C9.44745 12.9166 8.91754 12.6971 8.52684 12.3064C8.13614 11.9157 7.91665 11.3858 7.91665 10.8333H6.24998Z"
                fill="current"
              />
            </g>
            <defs>
              <clipPath id="clip0_117_6883">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        }
        label={"Home"}
        to={"/"}
      />
      <SidebarNavLink
        icon={<AccountCircleFillIcon size={20} color={"current"} />}
        label={"Account"}
        to={"/account/profile"}
      />
      <LogoutSectionSidebar />
    </div>
  );
};

const OrgRepApp: React.FC = (props) => {
  return <AppLayout content={Content()} navigation={Navigation()} />;
};

export default OrgRepApp;
