import React from "react";
import styled from "styled-components";
import {
  Card,
  CardTitle,
  SeparationLink,
} from "src/Modules/Customer/Components/TalentProfile/TalentProfileCommon";
import GovernmentLineIcon from "remixicon-react/GovernmentLineIcon";
import Text from "src/Modules/Shared/Components/Atom/Text/Text";
import AwardLineIcon from "remixicon-react/AwardLineIcon";
import ArrowRightUpLineIcon from "remixicon-react/ArrowRightUpLineIcon";

const Item = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #7dd3fc;
  border-radius: 6px;
`;

const EducationName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const EducationDegree = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const Years = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #6a7288;
`;

const Link = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px 4px 16px;
  gap: 4px;

  margin-top: 10px;

  width: 170px;
  height: 32px;

  border: 1px solid #105cf7;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;

  cursor: pointer;
`;

const isEmpty = (data) => {
  if (data == null) {
    return true;
  }

  if (data == "") {
    return true;
  }

  if (data == undefined) {
    return true;
  }

  return false;
};

const Certifications: React.FC = (props) => {
  let items = props.data.Certifications;

  if (items.length == 0) {
    return null;
  }

  return (
    <Card>
      <CardTitle>Certifications & Awards</CardTitle>
      <List>
        {items.map((i: any) => {
          return (
            <>
              <Item>
                <Icon>
                  <AwardLineIcon size={20} />
                </Icon>
                <Content>
                  <EducationName>{i.name}</EducationName>
                  <Years>Issued by {i.issuer}</Years>
                  <Years>
                    {i.periodFrom}{" "}
                    {i.periodTo != undefined && <>- {i.periodTo}</>}
                  </Years>
                  {!isEmpty(i.Url) && (
                    <Link href={i.credential_url} target={"_blank"}>
                      Show Credentials <ArrowRightUpLineIcon />
                    </Link>
                  )}
                </Content>
              </Item>
              <SeparationLink />
            </>
          );
        })}
      </List>
    </Card>
  );
};

export default Certifications;
