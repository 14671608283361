import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";
import { PrimaryButtonWide } from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import arrayMutators from "final-form-arrays";
import { Field, Form } from "react-final-form";
import CandidateDraftField from "src/Modules/Admin/Components/Pages/ShortList/Components/CandidateDraftField";
import skillOptions from "src/Modules/Shared/Components/Temp/skillOptions";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import MainSkillsField from "src/Modules/Shared/Components/Temp/MainSkillsField";
import CommonMultipleSkillsSelect from "src/Modules/Shared/Components/Temp/CommonMultipleSkillsSelect";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import { FieldArray } from "react-final-form-arrays";
import AreaInputField from "src/Modules/Shared/Components/Temp/AreaInputField";
import LocationInputField from "src/Modules/Shared/Components/Temp/LocationInputField";
import countries from "src/Modules/Shared/Components/Temp/countries";
import MapPin2FillIcon from "remixicon-react/MapPin2FillIcon";
import CandidateSkills from "src/Modules/Shared/Components/Shortlist/CandidateCard/CandidateSkills";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import MoneyDollarCircleLineIcon from "remixicon-react/MoneyDollarCircleLineIcon";

const borderColor = "#EDEDF0";

const Container = styled.div`
  background: #ffffff;
  border: 1px solid ${borderColor};
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-bottom: 32px;
`;

const UpperPart = styled.div`
  display: flex;
  min-height: 486px;
  flex-direction: column;
  @media screen and (min-width: 1000px) {
    flex-direction: row;
  }
`;

const ProfileCard = styled.div`
  border: 1px solid ${borderColor};
  width: 100%;

  border-radius: 12px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @media screen and (min-width: 1000px) {
    min-width: 320px;
    max-width: 320px;
    margin-bottom: 0;
  }
`;

const ProfilePartAContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ProfilePartBContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileSpacer = styled.div`
  height: auto;
  margin-top: auto;
`;

const SalaryLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 12px;
`;

const Salary = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const SalaryType = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #858b9d;
  margin-left: auto;
  width: 100px;
`;

const ProfileInfoLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const ProfileInfoLineField = styled.div`
  width: 70px;
`;

const InfoLineIcon = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  svg {
  }
`;

const InfoLineText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */

  color: #1c274a;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProfileImageContainer = styled.img`
  border: 1px solid #ffffff;
  border-radius: 999px;
  width: 56px;
  height: 56px;
  margin-right: 20px;
`;

const ProfileNameContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height, or 178% */

  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */

  color: #1c274a;
`;

const Name = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;
const Role = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;
const Place = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const GrayLine = styled.div`
  border-top: 1px solid ${borderColor};
  width: 100%;
  margin: 16px 0;
`;

const NotInterestedButton = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 48px;
  cursor: pointer;
  margin-top: 16px;
`;

const DataCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  max-width: 100ch;
  flex-grow: 1;
  min-width: 100px;

  @media screen and (min-width: 1000px) {
    margin-left: 16px;
  }
`;

const DataCardHeader = styled.div`
  /* Notch/Label/Large */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height, or 178% */

  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */

  color: #1c274a;
`;

const SkillList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin: 12px 0;
  gap: 8px;
`;

const Skill = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  height: 32px;
  background: #f4fbfd;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1d7c93;
  margin-right: 12px;
  margin-bottom: 12px;
`;

const SkillLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const Summary = styled.div`
  width: 100%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;

  color: #1c274a;
  margin-bottom: 16px;
`;

const CriteriaCard = styled.div`
  padding: 16px;
  background: #fafafb;
  border: 1px solid #ededf0;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
`;

const CriteriaTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.06px;
  text-transform: uppercase;
  color: #1c274a;
  margin-bottom: 12px;
`;

const LowerPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Tag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;

  height: 32px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.03em;

  /* Notch / Secondary / Purple / 600 */

  color: #6522f2;

  background: #f1e7fe;
  border-radius: 6px;
`;

const LastUpdated = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #858b9d;
  margin-left: 12px;
`;

const ViewProfileLink = styled.a`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
  margin: 0 12px 0 auto;
`;

const Loading = styled.div`
  font-size: 40px;
  color: rebeccapurple;
`;

const RowLines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const NewLineInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;

  width: 100%;
  height: 24px;

  background: #ffffff;
  /* Notch/Neutral/100 */

  border: 1px solid #ededf0;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  &:focus {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }
`;

const SkillItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  height: 32px;
  background: #f4fbfd;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1d7c93;
`;

const SkillItemName = styled.div``;

const SkillItemYear = styled.div`
  color: var(--notch-secondary-teal-50, #f4fbfd);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;
  border-radius: 999px;
  background: var(--notch-secondary-teal-600, #1d7c93);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  min-width: 32px;

  padding: 0 8px;
`;

const hasEmptyLine = (values: Array<any>) => {
  if (values == undefined) {
    return false;
  }

  for (const [key, value] of Object.entries(values)) {
    if (value == "") {
      return true;
    }
  }

  return false;
};

type Props = {
  code: string;
  data: Object;
};

const FetchingMessage = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 320px;
  background: #e2e8f0;
  color: black;
  position: fixed;
  top: 12px;
  right: 0;
  border-radius: 12px 0 0 12px;
`;

const ShortlistDraftEdit: React.FC<Props> = (props) => {
  let draftOverwrites = props.data.dataOverwrites;
  let candidate = props.data;
  const queryClient = useQueryClient();

  let updateMutation = useMutation({
    mutationFn: BackofficeApi.shortlistCandidates.id(props.data.id)
      .updateCandidate,
    onSuccess: () => {
      queryClient.invalidateQueries(["jobs"]);
    },
  });

  const publishMutation = useMutation({
    mutationFn: BackofficeApi.shortlistCandidates.id(props.data.id).publish,
    onSuccess: () => {
      queryClient.invalidateQueries(["jobs"]);
    },
  });

  if (
    !Array.isArray(draftOverwrites.DraftSkills) ||
    draftOverwrites.DraftSkills.length == 0
  ) {
    let skillsByValue = draftOverwrites.FeaturedSkills.map((skillByOption) => {
      const x = skillOptions.find((i) => {
        return i.label == skillByOption.Name;
      });

      if (x) {
        return x.value;
      }
    });

    draftOverwrites.DraftSkills = skillsByValue;
  }

  if (draftOverwrites === null) {
    return (
      <Container>
        <Loading>Loading profile</Loading>
      </Container>
    );
  }

  return (
    <Form
      mutators={{ ...arrayMutators }}
      onSubmit={(values, form) => {
        updateMutation.mutate(values);
        form.restart();
      }}
      initialValues={draftOverwrites}
      render={({ handleSubmit, values, form, pristine }) => (
        <form onSubmit={handleSubmit}>
          {queryClient.isFetching() > 0 && (
            <FetchingMessage>Updating...</FetchingMessage>
          )}
          <Container>
            <UpperPart>
              <ProfileCard>
                <ProfilePartAContainer>
                  <ProfileImageContainer
                    src={props.data.legacyProfile.profileBase}
                  />
                  <ProfileNameContainer>
                    <Name>{draftOverwrites.Name}</Name>
                    <Role>
                      <Field name={"Title"} component={CandidateDraftField} />
                    </Role>
                    <Place>
                      <Field
                        name={"Country"}
                        component={DefaultSelectField}
                        options={countries}
                      />
                    </Place>
                  </ProfileNameContainer>
                </ProfilePartAContainer>
                <GrayLine />

                <ProfilePartBContainer>
                  <ProfileInfoLine>
                    <InfoLineIcon>
                      <MapPin2FillIcon size={16} />
                    </InfoLineIcon>
                    <InfoLineText>
                      <Field name={"City"} component={CandidateDraftField} />
                    </InfoLineText>
                  </ProfileInfoLine>
                  <ProfileInfoLine>
                    <InfoLineIcon>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.83341 4.16671V1.66671C4.83341 1.44569 4.92121 1.23373 5.07749 1.07745C5.23377 0.921171 5.44573 0.833374 5.66675 0.833374H12.3334C12.5544 0.833374 12.7664 0.921171 12.9227 1.07745C13.079 1.23373 13.1667 1.44569 13.1667 1.66671V4.16671H16.5001C16.7211 4.16671 16.9331 4.2545 17.0893 4.41079C17.2456 4.56707 17.3334 4.77903 17.3334 5.00004V16.6667C17.3334 16.8877 17.2456 17.0997 17.0893 17.256C16.9331 17.4122 16.7211 17.5 16.5001 17.5H1.50008C1.27907 17.5 1.06711 17.4122 0.910826 17.256C0.754545 17.0997 0.666748 16.8877 0.666748 16.6667V5.00004C0.666748 4.77903 0.754545 4.56707 0.910826 4.41079C1.06711 4.2545 1.27907 4.16671 1.50008 4.16671H4.83341ZM15.6667 10.8334H2.33341V15.8334H15.6667V10.8334ZM15.6667 5.83337H2.33341V9.16671H4.83341V7.50004H6.50008V9.16671H11.5001V7.50004H13.1667V9.16671H15.6667V5.83337ZM6.50008 2.50004V4.16671H11.5001V2.50004H6.50008Z"
                          fill="#1C274A"
                        />
                      </svg>
                    </InfoLineIcon>
                    <InfoLineText>
                      <ProfileInfoLineField>
                        <Field
                          name={"YearsEngineeringExperience"}
                          component={CandidateDraftField}
                        />
                      </ProfileInfoLineField>
                      of engineering experience
                    </InfoLineText>
                  </ProfileInfoLine>

                  <ProfileInfoLine>
                    <InfoLineIcon>
                      <MoneyDollarCircleLineIcon size={20} color="#1C274A" />
                    </InfoLineIcon>
                    <InfoLineText>
                      <Field
                        name={"preferredRate"}
                        component={CandidateDraftField}
                      />
                    </InfoLineText>
                  </ProfileInfoLine>

                  <ProfileInfoLine>
                    <InfoLineIcon>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_949_1398)">
                          <path
                            d="M17.5 15.8333H19.1667V17.5H0.833336V15.8333H2.5V3.33333C2.5 3.11232 2.5878 2.90036 2.74408 2.74408C2.90036 2.5878 3.11232 2.5 3.33334 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V15.8333H15.8333V9.16667H14.1667V7.5H16.6667C16.8877 7.5 17.0996 7.5878 17.2559 7.74408C17.4122 7.90036 17.5 8.11232 17.5 8.33333V15.8333ZM4.16667 4.16667V15.8333H10.8333V4.16667H4.16667ZM5.83334 9.16667H9.16667V10.8333H5.83334V9.16667ZM5.83334 5.83333H9.16667V7.5H5.83334V5.83333Z"
                            fill="#1C274A"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_949_1398">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </InfoLineIcon>
                    <InfoLineText>
                      <Field
                        name={"Timezone"}
                        component={CandidateDraftField}
                      />
                    </InfoLineText>
                  </ProfileInfoLine>

                  <ProfileInfoLine>
                    <InfoLineIcon></InfoLineIcon>
                    <InfoLineText>
                      Ready to start
                      <ProfileInfoLineField>
                        <Field
                          name={"ReadyIn"}
                          component={CandidateDraftField}
                        />
                      </ProfileInfoLineField>
                    </InfoLineText>
                  </ProfileInfoLine>
                </ProfilePartBContainer>

                <GrayLine />

                <ProfilePartBContainer>
                  <SalaryLabel>Expected Salary</SalaryLabel>
                  <ProfileInfoLine>
                    <Salary>
                      <Field
                        name={"DirectHireSalary"}
                        component={CandidateDraftField}
                      />
                    </Salary>
                    <SalaryType>Direct Hire</SalaryType>
                  </ProfileInfoLine>

                  <ProfileInfoLine>
                    <Salary>
                      <Field
                        name={"CloudHireSalary"}
                        component={CandidateDraftField}
                      />
                    </Salary>
                    <SalaryType>Cloud Hire</SalaryType>
                  </ProfileInfoLine>
                </ProfilePartBContainer>
                <ProfileSpacer />

                <GrayLine />
                {props.data.status == "Draft" && (
                  <>
                    <Button
                      variant="primary"
                      size="large"
                      disabled={
                        !pristine ||
                        publishMutation.isLoading ||
                        updateMutation.isLoading
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        publishMutation.mutate();
                      }}
                    >
                      {pristine ? "Publish" : "Publish (unsaved changes)"}
                    </Button>
                    <div style={{ height: 12 }} />
                  </>
                )}

                <Button
                  variant="outline"
                  size="large"
                  disabled={
                    updateMutation.isLoading || publishMutation.isLoading
                  }
                >
                  Save
                </Button>
              </ProfileCard>
              <DataCard>
                <DataCardHeader>Skills Match</DataCardHeader>
                {/*
                <SkillList>
                  <Field
                    name="DraftSkills"
                    component={DefaultSelectField}
                    isMulti={true}
                    options={skillOptions}
                  />
                </SkillList>
                */}
                <SkillList>
                  {props.data.dataOverwrites.Skills.map((i) => (
                    <SkillItem>
                      <SkillItemName>{i.name}</SkillItemName>
                      <SkillItemYear>{i.years}</SkillItemYear>
                    </SkillItem>
                  ))}
                </SkillList>
                <DataCardHeader>Summary</DataCardHeader>
                <Field name={"Bio"} component={AreaInputField} />

                <CriteriaCard>
                  <CriteriaTitle>Matching criteria</CriteriaTitle>
                  <FieldArray name={"MatchCriteriaLines"}>
                    {({ fields }) => (
                      <RowLines>
                        {fields.map((name, index) => {
                          return (
                            <>
                              <Field
                                onFocus={(event: FocusEvent) => {
                                  //remove all empty fields but this
                                  values.MatchCriteriaLines.forEach(
                                    (i: any, ii: any) => {
                                      if (i == undefined && index != ii) {
                                        form.mutators.remove(
                                          "MatchCriteriaLines",
                                          ii
                                        );
                                      }
                                    }
                                  );
                                }}
                                id={`MatchCriteriaLines_${index}`}
                                name={`${name}`}
                                component={CandidateDraftField}
                                placeholder={`Requirement #${index + 1}`}
                              />
                            </>
                          );
                        })}
                        {!hasEmptyLine(values.MatchCriteriaLines) &&
                          (values.MatchCriteriaLines?.length ?? 1) < 5 && (
                            <NewLineInput
                              onFocus={(event: FocusEvent) => {
                                //remove all empty fields but this
                                values.MatchCriteriaLines.forEach(
                                  (i: any, ii: any) => {
                                    if (i == undefined) {
                                      form.mutators.remove(
                                        "MatchCriteriaLines",
                                        ii
                                      );
                                    }
                                  }
                                );
                              }}
                              placeholder={`Criteria #${
                                values.MatchCriteriaLines
                                  ? values.MatchCriteriaLines.length + 1
                                  : 1
                              }`}
                              onChange={(event) => {
                                event.target.disabled = true;
                                const index = values.MatchCriteriaLines
                                  ? values.MatchCriteriaLines.length
                                  : 0;
                                form.mutators.insert(
                                  "MatchCriteriaLines",
                                  index,
                                  event.target.value
                                );
                                event.target.value = "";
                                setTimeout(() => {
                                  const el = document.getElementById(
                                    "MatchCriteriaLines_" + index
                                  );
                                  el.focus();
                                  event.target.disabled = false;
                                });
                              }}
                            />
                          )}
                      </RowLines>
                    )}
                  </FieldArray>
                </CriteriaCard>
              </DataCard>
            </UpperPart>
            <GrayLine />
            <LowerPart>
              <ViewProfileLink target="_blank" href={`/talent-profile`}>
                View Full Profile
              </ViewProfileLink>
            </LowerPart>
          </Container>
        </form>
      )}
    />
  );
};

export default ShortlistDraftEdit;
