import React, { useState } from "react";
import styled from "styled-components";
import { Field, Form } from "react-final-form";
import axios from "axios";
import {
  FormGroupFull,
  FormLabel,
} from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import { useQueryClient } from "@tanstack/react-query";
import { NavLink } from "react-router-dom";
import * as yup from "yup";

const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.04em;
  color: #1c274a;
  text-align: left;
  margin-bottom: 32px;
`;

const ForgotPasswordLink = styled.a`
  display: block;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
  margin-left: auto;
  width: 120px;
  cursor: pointer;

  &:hover {
    color: #0644c3;
  }
`;

const SubmitButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  height: 48px;
  width: 100%;

  background: #105cf7;
  border-radius: 6px;

  margin-top: 28px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.03em;

  color: #ffffff;

  &:disabled {
    background: #ededf0;
    color: #9fa4b3;
    cursor: default;
    outline: none;
    border: none;
  }
`;

const InfoText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 24px;
`;

const BackButton = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;

  cursor: pointer;
  margin-bottom: 24px;

  span {
    display: block;
  }
`;

const BackIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

const SignUpTextContainer = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #858b9d;

  margin-top: 16px;

  width: 100%;
  flex-direction: row;
  justify-content: center;

  a {
    padding-left: 4px;
  }
`;

const ResetPassSchema = yup.object({
  email: yup.string().email().required(),
});

type Props = {
  setResetScreen: (value: boolean) => void;
};

const SignInForm: React.FC<Props> = (props) => {
  const queryClient = useQueryClient();
  let [resetRequested, setResetRequested] = useState(false);

  return (
    <Container>
      <BackButton
        onClick={() => {
          props.setResetScreen(false);
        }}
      >
        <BackIcon>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.52337 9.16667H16.6667V10.8333H6.52337L10.9934 15.3033L9.81504 16.4817L3.33337 10L9.81504 3.51834L10.9934 4.69667L6.52337 9.16667Z"
              fill="#105CF7"
            />
          </svg>
        </BackIcon>
        <span>Back</span>
      </BackButton>
      {resetRequested ? (
        <>
          <Header>Reset password</Header>
          <InfoText>
            You will receive a link to reset your password shortly if we find an
            active account with that email. If you can't find it in your inbox,
            click below and we will resend it again.
          </InfoText>
          <SubmitButton type={"submit"}>Resend</SubmitButton>
          <SignUpTextContainer>
            Having issues logging in?{" "}
            <a href="mailto:support@notchteam.com">Contact support</a>.
          </SignUpTextContainer>
        </>
      ) : (
        <>
          <Header>Reset password</Header>
          <InfoText>We will send you a link to reset your password.</InfoText>
          <Form
            onSubmit={(values) => {
              axios
                .post("/api/security/v1/password-reset", values)
                .then((response) => {
                  setResetRequested(true);
                });
            }}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <FormGroupFull>
                  <FormLabel>Email</FormLabel>
                  <Field name={"email"} component={TextInputField} />
                </FormGroupFull>

                <SubmitButton
                  disabled={!ResetPassSchema.isValidSync(values)}
                  type={"submit"}
                >
                  Send
                </SubmitButton>
              </form>
            )}
          />
        </>
      )}
    </Container>
  );
};

export default SignInForm;
