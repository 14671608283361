import React from "react";
import styled from "styled-components";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import {
  PageTitle,
  PageTitleLine,
} from "src/Modules/Customer/Components/CommonCustoemerLib";
import { useQuery } from "@tanstack/react-query";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import { useSearchParams } from "react-router-dom";
import InviteRepAction from "src/Modules/Admin/Pages/OrganizationReps/InviteRepAction";
import { Column } from "react-table";
import AccountProfileAvatar from "src/Modules/Shared/Components/Temp/AccountProfileAvatar";
import AdminTable from "src/Modules/Admin/Components/AdminTable/AdminTable";

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;

  background: white;
  padding: 24px;
  border-radius: 12px;

  width: 100%;
  min-height: 200px;

  margin-bottom: 32px;

  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 12px;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 12px 0 12px auto;
  align-items: center;
`;

const CompanyName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const OrganizationRepsListPage: React.FC = (props) => {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
  });

  const query = useQuery({
    ...BackofficeApi.reps.list(searchParams),
    keepPreviousData: true,
  });

  const Columns: Column[] = [
    {
      Header: "Name & Profile",
      accessor: (row) => {
        return (
          <FlexRow>
            <AccountProfileAvatar
              name={row.firstName}
              profilePictureUrl={row.publicProfilePictureUrl}
            />
            <CompanyName>
              {row.firstName} {row.lastName}
            </CompanyName>
          </FlexRow>
        );
      },
      width: 100,
    },
    {
      Header: "Companies Reppin'",
      accessor: (row) => {
        return <FlexRow>{row.representingOrganizationCount}</FlexRow>;
      },
      width: 100,
    },
  ];

  return (
    <FluidContentContainer>
      <PageTitle>Reps</PageTitle>
      <PageTitleLine />
      <Row>
        <InviteRepAction />
      </Row>
      <Card>
        {!query.isLoading && (
          <>
            <Row>
              <Title>{query.data?.data.totalResults} Reps</Title>
            </Row>
            <AdminTable data={query.data?.data.results} columns={Columns} />
          </>
        )}
      </Card>
    </FluidContentContainer>
  );
};

export default OrganizationRepsListPage;
