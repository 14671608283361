import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import { PageTitleLine } from "src/Modules/Customer/Components/CommonCustoemerLib";
import skillOptions from "src/Modules/Shared/Components/Temp/skillOptions";
import MainAndSideColumnsLayoutB, {
  MainColumn,
  SideColumn,
} from "src/Modules/Shared/Components/Layout/MainAndSideColumnsLayoutB";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import { CircleFlag } from "react-circle-flags";
import { timezoneOptions } from "src/Modules/Shared/Components/Temp/timezones";
import countries from "src/Modules/Shared/Components/Temp/countries";
import RemainingLocations from "src/Modules/Customer/Pages/Jobs/RemainingLocations";
import { useQuery } from "@tanstack/react-query";
import {
  CustomerApi,
  CustomerApiQuery,
} from "src/Modules/Customer/Services/CustomerApi";
import JobViewCompanyDetailsCard from "src/Modules/Customer/Pages/Jobs/JobViewCompanyDetailsCard";
import JobViewLocationList from "src/Modules/Customer/Pages/Jobs/JobViewLocationList";
import { JobDetailsType } from "src/Modules/Core/Types/JobTypes";
import {
  JobDetailsHelper,
  JobDetailsOptions,
} from "src/Modules/Core/Service/JobDetailsService";
import EarthFillIcon from "remixicon-react/EarthFillIcon";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 12px;
  width: 100%;

  @media screen and (min-width: 700px) {
    &.b {
      flex: 3 1 0;
    }

    &.a {
      flex: 1 1 0;
    }
  }
`;

const UpperQuadrant = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProfilePicture = styled.div`
  width: 88px;
  height: 88px;
  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 6px;
`;

const TopInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  flex: 1 1 0;
`;

const JobTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
  position: relative;
  margin: 0;

  @media screen and (min-width: 600px) {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
`;

const RowSalarySkill = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
  }
`;

const SalaryTag = styled.div`
  background: #f8faff;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #09348b;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: fit-content;
  padding: 0 12px;

  margin-right: 12px;

  span.dot {
    margin: 0 8px;
    border-radius: 999px;
    width: 4px;
    height: 4px;
    background: #d2d4db;
  }
`;

const SkillList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 12px;
  gap: 8px;

  &.pad {
    margin-bottom: 12px;
  }

  @media screen and (min-width: 600px) {
    margin-top: 0;
  }
`;

const SkillItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 24px;
  background: #f4fbfd;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1d7c93;

  padding: 4px 8px;

  &.secondary {
    background: #fafafb;
    border-radius: 6px;
    color: #6a7288;
  }
`;

const GrayDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 999px;
  background: #d2d4db;
`;

const CompanyName = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;

  span.name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #1c274a;
  }

  span.dot {
    display: none;

    @media screen and (min-width: 600px) {
      display: flex;
      margin-right: 8px;
      border-radius: 999px;
      width: 4px;
      height: 4px;
      background: #d2d4db;
    }
  }

  span.info {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #1c274a;
  }

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
  }
`;

const MainBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const DirectHireTag = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 4px 12px;

  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #6522f2;
  height: 24px;
  background: #f1e7fe;
  border-radius: 4px;

  @media screen and (min-width: 600px) {
    margin-left: auto;
  }
`;

const SectionTitle = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const SectionBody = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-top: 12px;
  margin-bottom: 16px;
  white-space: break-spaces;

  ul {
    margin-left: 16px;
  }

  li {
    padding-left: 0px;
    word-break: break-word;
  }
`;

const JobDetailsTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #1c274a;
  margin-bottom: 16px;
`;

const Label = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 8px;
`;

const MiniLabel = styled.div`
  ont-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 8px;
`;

const getSelection = (value: any, options: any) => {
  return options.find((i: any) => {
    return i.value == value;
  });
};

const getSkillNameFromCode = (code: string) => {
  return skillOptions.find((i) => {
    return i.value == code;
  });
};

const Line = styled.div`
  border-bottom: 1px solid #ededf0;

  width: 100%;
  margin: 24px 0;
`;

const ShouldShowGrayDot = (value) => {
  if (value == null || value == undefined || value == "") {
    return null;
  } else {
    return <GrayDot />;
  }
};

const BackButton = styled(NavLink)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
  margin-bottom: 24px;
`;

const JobDetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const JobDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const JobDetailsLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const JobDetailsValue = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const mapToLabel = (value, options) => {
  let f = options.find((i) => {
    return i.value == value;
  });

  return f?.label ?? value;
};

const getShortTimezone = (value) => {
  if (!value) {
    return null;
  }

  let i = value.indexOf(")");

  return value.slice(0, i + 1);
};

const getSalaryText = (values: any) => {
  if (values.salary?.min == undefined || values.salary?.max == undefined) {
    return "Remuneration incomplete";
  }

  const result = [];

  result.push(
    <span className="salary">
      $ {Math.floor(values.salary.min / 1000)}K -{" "}
      {Math.floor(values.salary.max / 1000)}K
    </span>
  );

  if (values.equity?.min == undefined || values.equity?.max == undefined) {
    return result;
  }

  result.push(<span className="dot"></span>);
  result.push(
    <span className="equity">
      {values.equity.min}% - {values.equity.max}%
    </span>
  );
  return result;

  return "NO";
};

const CompanyDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FlagList = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  margin-right: 8px;
`;

const FlagItem = styled.div`
  width: 16px;
  height: 16px;
  margin-right: -14%;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const LocationRow = (data) => {
  if (data.hiring_locations == undefined) {
    return null;
  }

  let num = data.hiring_locations.length;
  let slice = data.hiring_locations.slice(0, 3);

  if (num > 3) {
    num = 4;
  }

  let flags = slice.map((i) => {
    return (
      <FlagItem>
        <CircleFlag countryCode={i} height={16} />
      </FlagItem>
    );
  });

  let result = [];

  if (num == 4) {
    flags.push(
      <FlagItem>
        <svg
          className="moreFlag"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_255_36544)">
            <rect width="16" height="16" rx="8" fill="#EDEDF0" />
            <path
              d="M3.33333 6.66699C2.6 6.66699 2 7.26699 2 8.00033C2 8.73366 2.6 9.33366 3.33333 9.33366C4.06667 9.33366 4.66667 8.73366 4.66667 8.00033C4.66667 7.26699 4.06667 6.66699 3.33333 6.66699ZM12.6667 6.66699C11.9333 6.66699 11.3333 7.26699 11.3333 8.00033C11.3333 8.73366 11.9333 9.33366 12.6667 9.33366C13.4 9.33366 14 8.73366 14 8.00033C14 7.26699 13.4 6.66699 12.6667 6.66699ZM8 6.66699C7.26667 6.66699 6.66667 7.26699 6.66667 8.00033C6.66667 8.73366 7.26667 9.33366 8 9.33366C8.73333 9.33366 9.33333 8.73366 9.33333 8.00033C9.33333 7.26699 8.73333 6.66699 8 6.66699Z"
              fill="#B8BCC7"
            />
          </g>
          <defs>
            <clipPath id="clip0_255_36544">
              <rect width="16" height="16" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </FlagItem>
    );
  }

  result.push(<FlagList style={{ width: `${num * 12}px` }}>{flags}</FlagList>);

  let names = slice.map((i) => {
    return mapToLabel(i.toUpperCase(), countries);
  });

  let justOne = names.length == 1;

  names = names.join(", ");

  if (justOne) {
    names += " only";
  }

  result.push(<JobDetailsValue>{names}</JobDetailsValue>);

  if (num == 4) {
    result.push(
      <RemainingLocations
        text={data.hiring_locations
          .slice(3)
          .map((i) => mapToLabel(i.toUpperCase(), countries))
          .join(", ")}
        count={data.hiring_locations.length - 3}
      />
    );
  }

  return result;
};

const NoLocationRequired = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  svg {
    color: #d2d4db;
  }
`;

const isDirectHire = (data) => {
  let isDirectHire = false;

  if (data?.job_type == "Employee") {
    isDirectHire = true;
  } else {
    if (data?.hiring_model == "Direct Hire") {
      isDirectHire = true;
    }
  }

  return isDirectHire;
};

const JobView: React.FC = (props) => {
  const { jobId } = useParams();

  const jobQuery = useQuery(CustomerApi.jobs.get(jobId));

  const organizationQuery = useQuery({
    ...CustomerApiQuery.organization
      .id(jobQuery.data?.data.organization.id)
      .get(),
    enabled: jobQuery.data?.data?.organization?.id != null,
  });

  if (jobQuery.isLoading || organizationQuery.isLoading) {
    return null;
  }

  let job = jobQuery.data?.data;
  let jobDetails = job.jobDetails as JobDetailsType;
  let organization = organizationQuery.data?.data;
  let organizationDetails = organization.details;

  return (
    <FluidContentContainer>
      <BackButton to={`/jobs/${job.id}`}>{"<- Back"}</BackButton>
      <Line />
      <MainAndSideColumnsLayoutB>
        <MainColumn>
          <Card className={"b"}>
            <UpperQuadrant>
              <ProfilePicture>
                <CompanyLogo size={88} src={organization.details.logoUrl} />
              </ProfilePicture>
              <TopInfo>
                <JobTitle>
                  <span>{jobDetails.title}</span>

                  {JobDetailsHelper.isDirectHire(jobDetails) && (
                    <DirectHireTag>Direct hire</DirectHireTag>
                  )}
                </JobTitle>
                <CompanyName>
                  <span className="name">{organizationDetails.name}</span>
                  {ShouldShowGrayDot(organizationDetails.headline)}
                  <span className="info">{organizationDetails.headline}</span>
                </CompanyName>
                <RowSalarySkill>
                  <SalaryTag>{getSalaryText(jobDetails)}</SalaryTag>
                  <SkillList>
                    {jobDetails.requiredSkills?.map((i) => {
                      return (
                        <SkillItem>{getSkillNameFromCode(i).label}</SkillItem>
                      );
                    })}
                  </SkillList>
                </RowSalarySkill>
              </TopInfo>
            </UpperQuadrant>

            <MainBody>
              <SectionTitle>Overview</SectionTitle>
              <SectionBody>
                {jobDetails.overview ?? "No description"}
              </SectionBody>

              <SectionTitle>Responsibilities</SectionTitle>
              <SectionBody>
                {jobDetails.responsibilities?.length > 0 ? (
                  <ul>
                    {jobDetails.responsibilities.map((i: any) => {
                      return <li>{i}</li>;
                    })}
                  </ul>
                ) : (
                  "No description"
                )}
              </SectionBody>

              <SectionTitle>Requirements</SectionTitle>
              <SectionBody>
                {jobDetails.requirements?.length > 0 ? (
                  <ul>
                    {jobDetails.requirements.map((i: any) => {
                      return <li>{i}</li>;
                    })}
                  </ul>
                ) : (
                  "No description"
                )}
              </SectionBody>

              <SectionTitle>Bonus</SectionTitle>
              <SectionBody>
                {jobDetails.bonusPoints?.length > 0 ? (
                  <ul>
                    {jobDetails.bonusPoints.map((i: any) => {
                      return <li>{i}</li>;
                    })}
                  </ul>
                ) : (
                  "No description"
                )}
              </SectionBody>

              <SectionTitle>Benefits</SectionTitle>
              <SectionBody>
                {jobDetails.benefits ?? "No description"}
              </SectionBody>

              <SectionTitle>Additional Details</SectionTitle>
              <SectionBody>
                {jobDetails.additionalDetails ?? "No description"}
              </SectionBody>

              <SectionTitle>Interview Process</SectionTitle>
              <SectionBody>
                {jobDetails.interviewProcess ?? "No description"}
              </SectionBody>
            </MainBody>
          </Card>
        </MainColumn>

        <SideColumn>
          <Card className={"a"}>
            <JobDetailsTitle>Job details</JobDetailsTitle>

            <CompanyDetailsContainer>
              <JobDetailsItem>
                <JobDetailRow>
                  <JobDetailsLabel>Location</JobDetailsLabel>
                  <GrayDot />
                  <JobDetailsValue>Remote</JobDetailsValue>
                </JobDetailRow>
                {jobDetails.noRequiredLocations ? (
                  <NoLocationRequired>
                    {" "}
                    <EarthFillIcon size={18} />
                    <span>Work Anywhere</span>
                  </NoLocationRequired>
                ) : (
                  <JobViewLocationList locations={jobDetails.hiringLocations} />
                )}
              </JobDetailsItem>

              <JobDetailsItem>
                <JobDetailRow>
                  <JobDetailsLabel>Job Type</JobDetailsLabel>
                  {ShouldShowGrayDot(jobDetails.jobType)}
                  <JobDetailsValue>
                    {mapToLabel(jobDetails.jobType, JobDetailsOptions.jobType)}
                  </JobDetailsValue>
                </JobDetailRow>

                <JobDetailRow>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_173_24988)">
                      <path
                        d="M5.83329 4.16683V1.66683C5.83329 1.44582 5.92109 1.23385 6.07737 1.07757C6.23365 0.921293 6.44561 0.833496 6.66663 0.833496H13.3333C13.5543 0.833496 13.7663 0.921293 13.9225 1.07757C14.0788 1.23385 14.1666 1.44582 14.1666 1.66683V4.16683H17.5C17.721 4.16683 17.9329 4.25463 18.0892 4.41091C18.2455 4.56719 18.3333 4.77915 18.3333 5.00016V16.6668C18.3333 16.8878 18.2455 17.0998 18.0892 17.2561C17.9329 17.4124 17.721 17.5002 17.5 17.5002H2.49996C2.27895 17.5002 2.06698 17.4124 1.9107 17.2561C1.75442 17.0998 1.66663 16.8878 1.66663 16.6668V5.00016C1.66663 4.77915 1.75442 4.56719 1.9107 4.41091C2.06698 4.25463 2.27895 4.16683 2.49996 4.16683H5.83329ZM16.6666 10.8335H3.33329V15.8335H16.6666V10.8335ZM16.6666 5.8335H3.33329V9.16683H5.83329V7.50016H7.49996V9.16683H12.5V7.50016H14.1666V9.16683H16.6666V5.8335ZM7.49996 2.50016V4.16683H12.5V2.50016H7.49996Z"
                        fill="#D2D4DB"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_173_24988">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <JobDetailsValue>
                    {mapToLabel(jobDetails.hours, JobDetailsOptions.hours)}
                  </JobDetailsValue>
                </JobDetailRow>
              </JobDetailsItem>

              <JobDetailsItem>
                <JobDetailRow>
                  <JobDetailsLabel>Timezone</JobDetailsLabel>
                  {ShouldShowGrayDot(jobDetails.overlappingTimeTimezone)}

                  <JobDetailsValue>
                    {getShortTimezone(
                      mapToLabel(
                        jobDetails.overlappingTimeTimezone,
                        timezoneOptions
                      )
                    )}
                  </JobDetailsValue>
                </JobDetailRow>

                <JobDetailRow>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_173_24993)">
                      <path
                        d="M9.99996 18.3332C5.39746 18.3332 1.66663 14.6023 1.66663 9.99984C1.66663 5.39734 5.39746 1.6665 9.99996 1.6665C14.6025 1.6665 18.3333 5.39734 18.3333 9.99984C18.3333 14.6023 14.6025 18.3332 9.99996 18.3332ZM9.99996 16.6665C11.7681 16.6665 13.4638 15.9641 14.714 14.7139C15.9642 13.4636 16.6666 11.7679 16.6666 9.99984C16.6666 8.23173 15.9642 6.53603 14.714 5.28579C13.4638 4.03555 11.7681 3.33317 9.99996 3.33317C8.23185 3.33317 6.53616 4.03555 5.28591 5.28579C4.03567 6.53603 3.33329 8.23173 3.33329 9.99984C3.33329 11.7679 4.03567 13.4636 5.28591 14.7139C6.53616 15.9641 8.23185 16.6665 9.99996 16.6665V16.6665ZM10.8333 9.99984H14.1666V11.6665H9.16663V5.83317H10.8333V9.99984Z"
                        fill="#D2D4DB"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_173_24993">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <JobDetailsValue>
                    {JobDetailsHelper.getJobViewTimezoneText(jobDetails)}
                  </JobDetailsValue>
                </JobDetailRow>
              </JobDetailsItem>

              <JobDetailsItem>
                <JobDetailRow>
                  <JobDetailsLabel>Experience</JobDetailsLabel>
                </JobDetailRow>

                <JobDetailRow>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_173_24998)">
                      <path
                        d="M1.66663 3.3275C1.66815 3.10865 1.75571 2.89918 1.91039 2.74435C2.06506 2.58951 2.27444 2.50175 2.49329 2.5H17.5066C17.9633 2.5 18.3333 2.87083 18.3333 3.3275V16.6725C18.3318 16.8914 18.2442 17.1008 18.0895 17.2557C17.9349 17.4105 17.7255 17.4983 17.5066 17.5H2.49329C2.27397 17.4998 2.06371 17.4125 1.9087 17.2573C1.75369 17.1022 1.66663 16.8918 1.66663 16.6725V3.3275ZM3.33329 4.16667V15.8333H16.6666V4.16667H3.33329ZM4.99996 5.83333H9.99996V10.8333H4.99996V5.83333ZM6.66663 7.5V9.16667H8.33329V7.5H6.66663ZM4.99996 12.5H15V14.1667H4.99996V12.5ZM11.6666 5.83333H15V7.5H11.6666V5.83333ZM11.6666 9.16667H15V10.8333H11.6666V9.16667Z"
                        fill="#D2D4DB"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_173_24998">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <JobDetailsValue>
                    {jobDetails.totalExperienceYears}+ years of experience
                  </JobDetailsValue>
                </JobDetailRow>
              </JobDetailsItem>

              <JobDetailsItem>
                <JobDetailRow>
                  <JobDetailsLabel>Sponsorship</JobDetailsLabel>
                </JobDetailRow>

                <JobDetailRow>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_173_25003)">
                      <path
                        d="M16.6667 1.6665C16.8877 1.6665 17.0996 1.7543 17.2559 1.91058C17.4122 2.06686 17.5 2.27882 17.5 2.49984V17.4998C17.5 17.7209 17.4122 17.9328 17.2559 18.0891C17.0996 18.2454 16.8877 18.3332 16.6667 18.3332H3.33333C3.11232 18.3332 2.90036 18.2454 2.74408 18.0891C2.5878 17.9328 2.5 17.7209 2.5 17.4998V2.49984C2.5 2.27882 2.5878 2.06686 2.74408 1.91058C2.90036 1.7543 3.11232 1.6665 3.33333 1.6665H16.6667ZM15.8333 3.33317H4.16667V16.6665H15.8333V3.33317ZM13.3333 13.3332V14.9998H6.66667V13.3332H13.3333ZM10 4.99984C10.8841 4.99984 11.7319 5.35103 12.357 5.97615C12.9821 6.60127 13.3333 7.44912 13.3333 8.33317C13.3333 9.21723 12.9821 10.0651 12.357 10.6902C11.7319 11.3153 10.8841 11.6665 10 11.6665C9.11594 11.6665 8.2681 11.3153 7.64298 10.6902C7.01786 10.0651 6.66667 9.21723 6.66667 8.33317C6.66667 7.44912 7.01786 6.60127 7.64298 5.97615C8.2681 5.35103 9.11594 4.99984 10 4.99984V4.99984ZM10 6.6665C9.55797 6.6665 9.13405 6.8421 8.82149 7.15466C8.50893 7.46722 8.33333 7.89114 8.33333 8.33317C8.33333 8.7752 8.50893 9.19912 8.82149 9.51168C9.13405 9.82424 9.55797 9.99984 10 9.99984C10.442 9.99984 10.866 9.82424 11.1785 9.51168C11.4911 9.19912 11.6667 8.7752 11.6667 8.33317C11.6667 7.89114 11.4911 7.46722 11.1785 7.15466C10.866 6.8421 10.442 6.6665 10 6.6665V6.6665Z"
                        fill="#D2D4DB"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_173_25003">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <JobDetailsValue>
                    {
                      getSelection(jobDetails.visaSponsorship, [
                        { value: "Yes", label: "Yes" },
                        { value: "Not currently", label: "Not currently" },
                        { value: "Will consider", label: "Will consider" },
                      ])?.label
                    }
                  </JobDetailsValue>
                </JobDetailRow>
              </JobDetailsItem>

              <div>
                <Label>Skills</Label>
                <MiniLabel>Required</MiniLabel>
                <SkillList className="pad">
                  {jobDetails.requiredSkills?.map((i) => {
                    return (
                      <SkillItem>
                        {getSelection(i, skillOptions)?.label}
                      </SkillItem>
                    );
                  })}
                </SkillList>
                <MiniLabel>Nice to have</MiniLabel>
                <SkillList>
                  {jobDetails.niceToHaveSkills?.map((i) => {
                    return (
                      <SkillItem className={"secondary"}>
                        {getSelection(i, skillOptions)?.label}
                      </SkillItem>
                    );
                  })}
                </SkillList>
              </div>
            </CompanyDetailsContainer>
          </Card>
          {job.organization && (
            <JobViewCompanyDetailsCard organizationId={job.organization.id} />
          )}
        </SideColumn>
      </MainAndSideColumnsLayoutB>
    </FluidContentContainer>
  );
};

export default JobView;
