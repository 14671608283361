import React, { useState } from "react";
import styled from "styled-components";
import Select, { components, OptionProps } from "react-select";
import { FieldRenderProps } from "react-final-form";

const stylesOption = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    height: 48,
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    color: "red",
    display: "none",
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    display: state.isMulti ? "none" : "default",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 60,
    border: "1px solid #eceff6",
    borderTop: "none",
    zIndex: 1000,

    background: "#FFFFFF",
    boxShadow: "0px 2px 16px rgba(28, 39, 74, 0.06)",
    borderRadius: 6,
  }),
  menuPortal: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 48,
    zIndex: 1,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 48,
    fontFamily: "Inter",
    paddingRight: 12,
    margin: 0,
    color: "#1C274A",
    border: state.isFocused ? "#105cf7 1px solid" : "1px solid #EDEDF0",
    "&:hover": {
      borderColor: "#7aa0ff 1px solid",
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    color: state.isDisabled ? "#999" : "#1C274A",
    letterSpacing: "0",
    fontStyle: "normal",
    fontFamily: "Inter",
    fontWeight: 400,
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 6,
    padding: "2px 8px",
    backgroundColor: "#F4FBFD",
    height: 24,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    color: "#1D7C93",
    backgroundColor: "none",
    width: 18,
    height: 18,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "pink",
      cursor: "pointer",
    },
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Inter",
    color: "#1D7C93",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 20,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    height: 20,
    fontFamily: "Inter",
    fontWeight: 400,
    marginLeft: 0,
    color: "#9FA4B3",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",

    height: 48,
    backgroundColor: state.isSelected
      ? "#F8FAFF"
      : state.isFocused
      ? "#F8FAFF"
      : "white",
    color: state.isSelected
      ? "#1C274A"
      : state.isFocused
      ? "#1C274A"
      : "#1C274A",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
  }),
};

const CustomOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 12px;
  width: 100%;
  position: relative;

  //background: white;

  overflow-y: hidden;

  label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #1c274a;
    margin-bottom: 4px;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: -0.03em;
    color: #9fa4b3;
    word-break: break-word;
  }

  &:hover {
    background: #f0f9ff;
  }
`;

const OptionSelectedCheckmark = styled.div`
  width: 20px;
  height: 20px;
  margin-left: auto;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomOption = (props: OptionProps) => {
  const currentOption = props.options.find((i) => {
    return i.label == props.label;
  });

  return (
    <CustomOptionContainer
      onClick={() => {
        props.selectOption(currentOption);
      }}
    >
      <Text>
        <label>{props.label}</label>
        <p>{currentOption.description}</p>
      </Text>

      <OptionSelectedCheckmark>
        {props.isSelected && (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_149_15692)">
              <path
                d="M9.99996 18.3333C5.39746 18.3333 1.66663 14.6025 1.66663 10C1.66663 5.3975 5.39746 1.66667 9.99996 1.66667C14.6025 1.66667 18.3333 5.3975 18.3333 10C18.3333 14.6025 14.6025 18.3333 9.99996 18.3333ZM9.16913 13.3333L15.0608 7.44083L13.8825 6.2625L9.16913 10.9767L6.81163 8.61917L5.63329 9.7975L9.16913 13.3333Z"
                fill="#105CF7"
              />
            </g>
            <defs>
              <clipPath id="clip0_149_15692">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
      </OptionSelectedCheckmark>
    </CustomOptionContainer>
  );
};

const CustomDropdownIndicator = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12.5001L6.46417 8.96422L7.64334 7.78589L10 10.1434L12.3567 7.78589L13.5358 8.96422L10 12.5001Z"
        fill="#9FA4B3"
      />
    </svg>
  );
};

const Container = styled.div`
  width: 100%;
  height: 48px;
`;

type Props = FieldRenderProps<string, any>;

const HiringModelField: React.FC<Props> = (props) => {
  const empl = props.force?.value == "Employee";

  let selectedOptions = props.options.find((option: any) => {
    return option.value == props.input.value;
  });

  return (
    <Container>
      <Select
        components={{
          DropdownIndicator: CustomDropdownIndicator,
          Option: CustomOption,
        }}
        styles={stylesOption}
        options={props.options}
        placeholder={props.placeholder}
        isDisabled={props.disabled || empl}
        {...props}
        value={selectedOptions}
        onChange={(newValue: any, actionMeta: any) => {
          if (props.isMulti) {
            props.input.onChange(newValue.map((i: any) => i.value));
          } else {
            props.input.onChange(newValue.value);
          }
        }}
      />
    </Container>
  );
};

export default HiringModelField;
