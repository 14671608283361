import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink, Outlet, useParams } from "react-router-dom";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import {
  PageTitle,
  PageTitleLine,
} from "src/Modules/Customer/Components/CommonCustoemerLib";
import axios from "axios";
import ShortlistCandidates from "src/Modules/Admin/Components/Pages/ShortList/ShortlistCandidates";
import ShortlistDraft from "src/Modules/Admin/Components/Pages/ShortList/ShortlistDraft";
import ShortlistPublished from "src/Modules/Admin/Components/Pages/ShortList/ShortlistPublished";
import CompanyLogo from "src/Modules/Customer/Components/CompanyLogo";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import ShortlistEdit from "src/Modules/Admin/Components/Pages/ShortList/ShortlistEdit";
import CompanyJobInfoLine from "src/Modules/Admin/Components/Pages/ShortList/Components/CompanyJobInfoLine";

const Container = styled.div``;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 4px;

  background: #ededf0;
  border-radius: 8px;

  width: 100%;
  height: 40px;

  margin-bottom: 50px;

  cursor: pointer;
`;

const Tab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 8px;
  width: 33%;
  height: 100%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #9fa4b3;

  &.active {
    background: white;
    border-radius: 4px;
    color: #1c274a;
  }
`;

const Counter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;

  width: 24px;
  height: 24px;
  background: #105cf7;
  border-radius: 999px;
`;

const CompanyRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
`;

const CompanyName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.04em;
  color: #1c274a;

  margin-left: 12px;
`;

const FetchingNote = styled.div`
  font-size: 10px;
  color: orangered;
  font-family: Inter;
  display: inline;
  margin-left: auto;
`;

const JobShortList: React.FC = (props) => {
  const { jobId } = useParams();
  const [tab, setTab] = useState("candidates");
  const jobQuery = useQuery(BackofficeApi.jobs.id(jobId).get());

  if (jobQuery.isLoading) {
    return null;
  }

  let job = jobQuery.data?.data;

  let shortlist = job.shortlistedCandidates;

  const draftList = shortlist.filter((i) => i.status == "Draft");
  const publishList = shortlist.filter((i) => i.status != "Draft");

  return (
    <FluidContentContainer>
      <CompanyJobInfoLine job={job} />
      <PageTitleLine />
      <Tabs>
        <Tab
          onClick={() => setTab("candidates")}
          className={tab === "candidates" ? "active" : ""}
        >
          <span>Candidates</span>
          <Counter>{shortlist.length}</Counter>
        </Tab>
        <Tab
          onClick={() => setTab("drafts")}
          className={tab === "drafts" ? "active" : ""}
        >
          <span>Drafts</span>
          <Counter>{draftList.length}</Counter>
        </Tab>
        <Tab
          onClick={() => setTab("edits")}
          className={tab === "edits" ? "active" : ""}
        >
          <span>Edits</span>
        </Tab>
        <Tab
          onClick={() => setTab("published")}
          className={tab === "published" ? "active" : ""}
        >
          <span>Published</span>
          <Counter>{publishList.length}</Counter>
        </Tab>
      </Tabs>
      {tab === "candidates" && <ShortlistCandidates data={job} />}
      {tab === "drafts" && <ShortlistDraft data={draftList} jobData={job} />}
      {tab === "edits" && <ShortlistEdit data={draftList} jobData={job} />}
      {tab === "published" && (
        <ShortlistPublished data={draftList} jobData={job} />
      )}
    </FluidContentContainer>
  );
};

export default JobShortList;
