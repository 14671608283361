import React, { useState } from "react";
import styled from "styled-components";
import Select, {
  components,
  GroupHeadingProps,
  OptionProps,
  ValueContainerProps,
} from "react-select";
import {
  DropdownIndicator,
  Option,
  ValueContainer,
} from "src/Modules/Core/Components/Form/Select/SelectComponents";
import { DefaultSelectStyle } from "src/Modules/Core/Components/Form/Select/DefaultSelectStyle";
import { FieldRenderProps } from "react-final-form";
import { CircleFlag } from "react-circle-flags";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { CrossIcon } from "react-select/dist/declarations/src/components/indicators";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import OtherValues from "src/Modules/Core/Components/Form/Select/CountriesGroupedByRegionSelect/OtherValues";
import { ActionType } from "react-table";

const Container = styled.div`
  width: 100%;
  min-height: 48px;
  height: fit-content;

  .rs__control {
    min-height: 48px;
    height: fit-content;
  }

  .rs__value-container {
    flex-wrap: wrap;
    height: fit-content;
    display: flex;
  }

  div[class$="container"] {
    height: fit-content;
  }

  .rs__container {
    height: fit-content;
    background: red;
    margin: 20px;
  }
`;

type SelectFieldProps = FieldRenderProps<[], any>;

const RemoveRegion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: #1D7C93;
  width: 20px;
  height: 20px;
  
  margin-top: 1px;
 
  svg {
    width: 12px;
    height: 12px;
  }
  
  &:hover {
    color: pink;
    cursor: pointer;,
  },
`;

const CustomValueContainer: React.FC<ValueContainerProps> = (props) => {
  let [values, input] = props.children as any;

  let newValues = ["S", "ss"];

  // Get groups
  let allOptions = props.selectProps.options;

  let groupsMaxLength = {};
  let regionSet = new Set();

  for (let option of allOptions) {
    groupsMaxLength[option["value"]] = option.options.length;
    regionSet.add(option["value"]);
  }

  // Get selected values
  let currentValues = props.selectProps.value;
  if (!Array.isArray(currentValues)) {
    currentValues = [];
  }

  // Check if a region is fully selected

  let counterByRegion = Object.assign({}, groupsMaxLength);

  for (let r of regionSet.entries()) {
    counterByRegion[r[0]] = 0;
  }

  for (let v of currentValues) {
    counterByRegion[v["group"]] = counterByRegion[v["group"]] + 1;
  }

  let multiValuesAsRegions = [];

  for (let r of regionSet.entries()) {
    let group = r[0];

    if (counterByRegion[group] == groupsMaxLength[group]) {
      multiValuesAsRegions.push(group);
    }
  }

  let valuesToDisplay = values;
  let valuesCopy = values;
  if (!Array.isArray(values)) {
    valuesCopy = [];
  }

  let jC = multiValuesAsRegions.length;

  let hoverList = [];

  hoverList = multiValuesAsRegions.slice(5);

  return (
    <components.ValueContainer {...props}>
      {multiValuesAsRegions.map((i, j) => {
        return (
          <RegionValue>
            {i}{" "}
            <RemoveRegion
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                let currentValues = props.selectProps.value;
                let newSet = new Set(currentValues ?? []);

                let currentGroup = allOptions.find((group: unknown) => {
                  return group.value == i;
                });

                let currentGroupOptions = currentGroup.options;

                currentGroupOptions.forEach((option) => {
                  newSet.delete(option);
                });

                props.selectProps.onChange(Array.from(newSet));
              }}
            >
              <svg
                height="14"
                width="14"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className="css-tj5bde-Svg"
              >
                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
              </svg>
            </RemoveRegion>
          </RegionValue>
        );
      })}
      {valuesCopy.map((i, j) => {
        jC++;

        if (multiValuesAsRegions.includes(i.props.data.group)) {
          return null;
        }

        return <components.MultiValue {...i.props} />;
      })}
    </components.ValueContainer>
  );
};

const RegionValue = styled.div`
  background: #f8faff;
  border-radius: 6px;
  height: 28px;
  padding: 2px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: -0.03em;
  color: #09348b;
  gap: 8px;
  margin-right: 8px;
`;

const MoreSelection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  width: auto;
  height: 24px;
  background: #fafafb;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #6a7288;

  position: relative;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 0px 0 16px;
`;

const OptionSelectedCheckmark = styled.div`
  width: 20px;
  height: 20px;
  margin-left: auto;
`;

const CustomOption: React.FC<OptionProps> = (props: OptionProps) => {
  let option = props;

  let isGroupOpen = props.selectProps.openedGroups.get(props.data.group);

  if (!isGroupOpen) {
    return null;
  }

  return (
    <components.Option {...props}>
      <OptionContainer>
        <FlagIcon>
          <CircleFlag countryCode={props.value} height={16} />
        </FlagIcon>
        <Label>{props.label}</Label>
        {props.isSelected && (
          <OptionSelectedCheckmark>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_149_15692)">
                <path
                  d="M9.99996 18.3333C5.39746 18.3333 1.66663 14.6025 1.66663 10C1.66663 5.3975 5.39746 1.66667 9.99996 1.66667C14.6025 1.66667 18.3333 5.3975 18.3333 10C18.3333 14.6025 14.6025 18.3333 9.99996 18.3333ZM9.16913 13.3333L15.0608 7.44083L13.8825 6.2625L9.16913 10.9767L6.81163 8.61917L5.63329 9.7975L9.16913 13.3333Z"
                  fill="#105CF7"
                />
              </g>
              <defs>
                <clipPath id="clip0_149_15692">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </OptionSelectedCheckmark>
        )}
      </OptionContainer>
    </components.Option>
  );
};

const CustomGroupHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding: 0 12px;

  &:hover {
    background: #f8faff;
  }
`;

const Label = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  width: auto;
  flex: 1;
  cursor: pointer;
`;

const GroupArrow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  cursor: pointer;

  &.active {
    transform: rotate(90deg);
  }
`;

const FlagIcon = styled.div`
  width: 16px;
  height: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-right: 8px;

  svg {
  }
`;
const CustomGroupHeading: React.FC<GroupHeadingProps> = (
  props: GroupHeadingProps
) => {
  let areAllOptionsSelect = false;

  let currentGroupLabel = props.children;

  let currentValues = props.selectProps.value;

  if (!Array.isArray(currentValues)) {
    currentValues = [];
  }
  let allOptions = props.selectProps.options;

  let currentGroup = allOptions.find((group: unknown) => {
    return group.value == currentGroupLabel;
  });

  let currentGroupOptions = currentGroup.options;

  let groupOptionsLength = currentGroupOptions.length;

  let currentGroupOptionsSelected = currentGroupOptions.filter(
    (groupOption: any) => {
      let isSelected = currentValues.find((i: any) => {
        return i.value == groupOption.value;
      });

      return isSelected != undefined;
    }
  );

  if (currentGroupOptionsSelected.length === groupOptionsLength) {
    areAllOptionsSelect = true;
  }

  return (
    <CustomGroupHeadingContainer>
      <GroupArrow
        className={
          props.selectProps.openedGroups.get(props.children) ? "active" : ""
        }
        onClick={() => {
          let openedGroups = props.selectProps.openedGroups;
          openedGroups.set(
            props.children,
            !props.selectProps.openedGroups.get(props.children)
          );
          let newMap = new Map(openedGroups);
          props.selectProps.setOpenedGroups(newMap);
        }}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_147_15248)">
            <path
              d="M10.1434 10.0002L7.78589 7.64352L8.96422 6.46436L12.5001 10.0002L8.96422 13.536L7.78589 12.3569L10.1434 10.0002Z"
              fill="#9FA4B3"
            />
          </g>
          <defs>
            <clipPath id="clip0_147_15248">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </GroupArrow>
      <Label
        onClick={() => {
          // Select all options within the current group.

          let newSet = new Set(currentValues ?? []);

          if (areAllOptionsSelect) {
            currentGroupOptions.forEach((option) => {
              newSet.delete(option);
            });
          } else {
            currentGroupOptions.forEach((option) => {
              newSet.add(option);
            });
          }

          props.selectProps.onChange(Array.from(newSet));
        }}
      >
        {props.children}
      </Label>
      {areAllOptionsSelect && (
        <OptionSelectedCheckmark>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_149_15692)">
              <path
                d="M9.99996 18.3333C5.39746 18.3333 1.66663 14.6025 1.66663 10C1.66663 5.3975 5.39746 1.66667 9.99996 1.66667C14.6025 1.66667 18.3333 5.3975 18.3333 10C18.3333 14.6025 14.6025 18.3333 9.99996 18.3333ZM9.16913 13.3333L15.0608 7.44083L13.8825 6.2625L9.16913 10.9767L6.81163 8.61917L5.63329 9.7975L9.16913 13.3333Z"
                fill="#105CF7"
              />
            </g>
            <defs>
              <clipPath id="clip0_149_15692">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </OptionSelectedCheckmark>
      )}
    </CustomGroupHeadingContainer>
  );
};

const CountriesGroupedByRegionSelect: React.FC<SelectFieldProps> = (props) => {
  let info = useQuery({
    queryKey: ["options", "regions"],
    queryFn: () => axios.get("/api/options/regions"),
  });

  let [openedGroups, setOpenedGroups] = useState(new Map());

  if (info.isLoading) {
    return null;
  }

  let options = info.data?.data;

  let selectedOptions = null;

  let groups = options.filter((option) => {
    return option.type == "group";
  });

  groups = groups.map((group): string => group.value);
  let regions = new Set(groups);

  if (openedGroups.size == 0) {
    let newMap = new Map();

    regions.forEach((region) => {
      newMap.set(region, false);
    });

    setOpenedGroups(newMap);
  }

  if (props.isMulti) {
    // Flat
    let set = new Set();

    options.forEach((i) => {
      if (i.options != undefined && i.options.length > 0) {
        i.options.forEach((j) => {
          set.add(j);
        });
      }
    });

    if (Array.isArray(props.input.value)) {
      let flatOptions = Array.from(set);

      selectedOptions = flatOptions.filter((option: any) => {
        let isSelected = props.input.value.find((i) => {
          return i == option.value;
        });

        return isSelected != undefined;
      });
    }
  } else {
    selectedOptions = options.filter((option: any) => {
      return option.value == props.input.value;
    });
  }

  return (
    <Container>
      <Select
        closeMenuOnSelect={false}
        classNamePrefix="rs"
        isMulti={props.isMulti}
        regions={regions}
        menuIsOpen={undefined}
        hideSelectedOptions={false}
        onInputChange={(inputValue, { action }) => {
          if (action == "remove-value") {
          }
        }}
        openedGroups={openedGroups}
        setOpenedGroups={setOpenedGroups}
        components={{
          DropdownIndicator: DropdownIndicator,
          Option: CustomOption,
          ValueContainer: CustomValueContainer,
          GroupHeading: CustomGroupHeading,
        }}
        styles={DefaultSelectStyle}
        placeholder={props.placeholder}
        isDisabled={props.disabled}
        value={selectedOptions}
        onChange={(newValue: any, action: any) => {
          if (props.isMulti) {
            props.input.onChange(newValue.map((i: any) => i.value));
          } else {
            props.input.onChange(newValue.value);
          }
        }}
        {...props}
        options={options}
      />
    </Container>
  );
};

export default CountriesGroupedByRegionSelect;
