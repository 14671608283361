import React, { useState } from "react";
import styled from "styled-components";
import Select, {
  OptionProps,
  components,
  SingleValueProps,
} from "react-select";
import { FieldRenderProps } from "react-final-form";
import { DefaultSelectStyle } from "src/Modules/Core/Components/Form/Select/DefaultSelectStyle";
import {
  DropdownIndicator,
  ValueContainer,
} from "src/Modules/Core/Components/Form/Select/SelectComponents";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";

const Container = styled.div`
  width: 100%;
  z-index: 1000;
`;

const CustomStyles = DefaultSelectStyle;

CustomStyles.container = (provided, state) => ({
  ...provided,
  height: 32,
});

CustomStyles.control = (provided, state) => ({
  ...provided,
  height: 32,
  padding: "0 0 0 12px",
  boxShadow: state.isFocused ? "0px 0px 0px 2px #e6eefe" : "none",
  border: "1px solid #EDEDF0",
});

CustomStyles.valueContainer = (provided, state) => ({
  ...provided,
  padding: "2px 0px",
  height: 32,
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
});

type SelectFieldProps = FieldRenderProps<[], any>;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  border: 3px solid;
  border-radius: 999px;
  box-sizing: border-box;
  margin-right: 12px;
`;

const GreenDot = styled(Dot)`
  background: #00c181;
  border-color: #e2faf2;
`;

const YellowDot = styled(Dot)`
  background: #fbb32b;
  border-color: #fff8e2;
`;

const RedDot = styled(Dot)`
  background: #e32525;
  border-color: #ffeaec;
`;

const SValue = styled.div`
  width: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SingleValue = (props: SingleValueProps) => {
  const value = props.children;

  return (
    <components.SingleValue {...props}>
      <SValue>
        {value == "Open" && <GreenDot />}
        {value == "Closed" && <RedDot />}
        {value == "Paused" && <YellowDot />}
        {value}
      </SValue>
    </components.SingleValue>
  );
};

const Option = (props: OptionProps) => {
  const value = props.data.value;
  return (
    <components.Option {...props}>
      {value == "Open" && <GreenDot />}
      {value == "Closed" && <RedDot />}
      {value == "Paused" && <YellowDot />}
      {props.data.label}
    </components.Option>
  );
};

const JobStatusDropdown: React.FC<SelectFieldProps> = (props) => {
  let selectedOptions = null;

  if (props.isMulti) {
    if (Array.isArray(props.input.value)) {
      selectedOptions = props.options.filter((option: any) => {
        const isSelected = props.input.value.find((i) => {
          return i == option.value;
        });

        return isSelected != undefined;
      });
    }
  } else {
    selectedOptions = props.options.filter((option: any) => {
      return option.value == props.input.value;
    });
  }

  return (
    <Container>
      <Select
        closeMenuOnSelect={!props.isMulti}
        isMulti={props.isMulti}
        hideSelectedOptions={false}
        components={{
          DropdownIndicator: DropdownIndicator,
          Option: Option,
          ValueContainer: ValueContainer,
          SingleValue: SingleValue,
        }}
        styles={CustomStyles}
        options={props.options}
        placeholder={props.placeholder}
        isDisabled={props.disabled}
        isSearchable={false}
        value={selectedOptions}
        onChange={(newValue: any, actionMeta: any) => {
          if (props.isMulti) {
            props.input.onChange(newValue.map((i: any) => i.value));
          } else {
            props.input.onChange(newValue.value);
          }
        }}
        {...props}
      />
    </Container>
  );
};

export default JobStatusDropdown;
