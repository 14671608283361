import React from "react";
import styled from "styled-components";
import { useQueryClient } from "@tanstack/react-query";
import AdminCandidateCard from "src/Modules/Shared/Components/Shortlist/CandidateCard/AdminCandidateCard";

const Card = styled.div`
  display: flex;
  flex-direction: column;

  padding: 24px;

  width: 100%;
  min-height: 200px;

  margin-bottom: 32px;

  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const Action = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 24px;
`;

const ShortlistPublished: React.FC = (props) => {
  const job = props.jobData;
  let data = props.data;
  let queryClient = useQueryClient();

  let shortlist = job.shortlistedCandidates;

  shortlist = shortlist.filter((i) => i.status != "Draft");
  return (
    <div>
      <Card>
        <Title>{shortlist?.length ?? 0} Published</Title>
        {shortlist.map((i) => {
          return (
            <AdminCandidateCard
              key={i.id}
              jobSkills={{
                requiredSkills: job.data?.required_skills,
                niceToHaveSkills: job.data?.nice_to_have_skills,
              }}
              data={i}
            />
          );
        })}
      </Card>
    </div>
  );
};

export default ShortlistPublished;
