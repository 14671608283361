import React from "react";
import styled from "styled-components";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import {
  PageTitle,
  PageTitleLine,
} from "src/Modules/Customer/Components/CommonCustoemerLib";
import JobsOverviewCard from "src/Modules/Admin/Components/Pages/Overview/JobsOverviewCard";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const Container = styled.div``;

const OverviewPage: React.FC = (props) => {
  return (
    <FluidContentContainer>
      <PageTitle>Overview</PageTitle>
      <PageTitleLine />
      <JobsOverviewCard />
    </FluidContentContainer>
  );
};

export default OverviewPage;
