import React from "react";
import styled from "styled-components";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import {
  PageTitle,
  PageTitleLine,
} from "src/Modules/Customer/Components/CommonCustoemerLib";
import { NavLink, Outlet, useParams } from "react-router-dom";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import { useQuery } from "@tanstack/react-query";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";

const Container = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  gap: 0;
`;

const Link = styled(NavLink)`
  padding: 6px 20px;
  margin-bottom: -18px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  z-index: 200;
  color: gray;

  color: var(--notch-neutral-400, #9fa4b3);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;

  &.active {
    border-bottom: 2px solid #105cf7;
    color: #105cf7;
  }
`;

const CompanyName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const OrganizationPage: React.FC = (props) => {
  let { id } = useParams();
  let query = useQuery(BackofficeApi.organizations.get(id).single());

  if (query.isLoading) {
    return null;
  }

  let organization = query.data?.data;

  return (
    <FluidContentContainer>
      <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
        <CompanyLogo size={48} src={organization.details.logoUrl} />

        <CompanyName>{organization.details.name}</CompanyName>
      </div>
      <Container>
        <Link to={`/organizations/${id}/team`}>Team</Link>
        <Link to={`/organizations/${id}/jobs`}>Jobs</Link>
        <Link to={`/organizations/${id}/hires`}>Hires</Link>
      </Container>
      <PageTitleLine />
      <Outlet />
    </FluidContentContainer>
  );
};

export default OrganizationPage;
