export type JobType = {
  stage: JobStage;
  jobDetails: JobDetailsType;
};

export enum JobStage {
  Incomplete = "Incomplete",
  InReview = "In Review",
  Matching = "Matching",
}

export type JobDetailsType = {
  title: string;
  requiredSkills: [];
  niceToHaveSkills: [];
  role: string;
  roleFocus: [];
  totalExperienceYears: string;
  hours: string;
  jobLink: string;
  salary: {
    min: string;
    max: string;
  };
  equity: {
    min: string;
    max: string;
  };
  jobType: string;
  hiringModel: string;
  hiringLocations: string;
  overlappingTimeTimezone: string;
  overlappingTimeTotalHours: string;
  overlappingTimeStart: string;
  overlappingTimeEnd: string;
  overlappingTimeNotRequired: string;
  openingsNumber: string;
  visaSponsorship: string;
  overview: string;
  responsibilities: [];
  requirements: [];
  bonusPoints: [];
  benefits: [];
  additionalDetails: [];
  interviewProcess: [];
};
