import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  width: auto;
  height: 24px;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  color: #6a7288;
  position: relative;
`;

const Hover = styled.div`
  width: 300px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.03em;

  color: #ffffff;
  padding: 8px 12px;
  background: #1c274a;
  border-radius: 6px;

  top: 32px;
  right: 0px;
  position: absolute;

  z-index: 1000;
`;

const RemainingLocations = (props) => {
  let [hover, setHover] = useState<boolean>(false);

  return (
    <Container
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover && <Hover>{props.text}</Hover>}+{props.count} more
    </Container>
  );
};

export default RemainingLocations;
