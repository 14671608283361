import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { fi } from "date-fns/locale";
import ArrowLeftSFillIcon from "remixicon-react/ArrowLeftSFillIcon";
import ArrowRightCircleFillIcon from "remixicon-react/ArrowRightCircleFillIcon";
import ArrowRightSFillIcon from "remixicon-react/ArrowRightSFillIcon";

const Container = styled.div`
  width: auto;
  height: 30px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;

const Button = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  aspect-ratio: 1;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;

  background: white;
  color: #1c274a;
  border: 1px solid #d2d4db;

  &:hover {
    background: #f8faff;
  }

  &.left {
    border-radius: 12px 0 0 12px;
  }

  &.right {
    border-radius: 0 12px 12px 0;
  }

  &.disabled {
    background: #ededf0;
    color: #9fa4b3;
    pointer-events: none;
  }
`;

const PageIndicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 74px;
  height: 100%;
  aspect-ratio: 1;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;

  word-spacing: 1px;

  background: white;
  color: #2b2d42;
  border-top: 1px solid #d2d4db;
  border-bottom: 1px solid #d2d4db;

  user-select: none;
`;

type PaginationControlsProps = {
  searchParams: URLSearchParams;
  totalResults: number;
};

const PaginationControlsSingle: React.FC<PaginationControlsProps> = (props) => {
  const [totalResults, setTotalResults] = useState<number>(0);

  useEffect(() => {
    if (Number.isInteger(props.totalResults)) {
      setTotalResults(props.totalResults);
    }
  }, [props.totalResults]);

  if (totalResults == 0) {
    return null;
  }

  const searchParams = props.searchParams;
  const currentPage = Number(searchParams.get("page"));

  const pagesToLink = new Set();

  const totalPages = Math.ceil(
    totalResults / Number(searchParams.get("limit"))
  );

  const previousPage = new URLSearchParams(searchParams);
  previousPage.set("page", currentPage - 1);

  const nextPage = new URLSearchParams(searchParams);
  nextPage.set("page", currentPage + 1);

  return (
    <Container>
      <Button
        to={{ search: previousPage.toString() }}
        className={currentPage == 1 ? "disabled left" : "left"}
      >
        <ArrowLeftSFillIcon size={18} />
      </Button>
      <PageIndicator>
        {currentPage} of {totalPages}
      </PageIndicator>
      <Button
        to={{ search: nextPage.toString() }}
        className={currentPage == totalPages ? "disabled right" : "right"}
      >
        <ArrowRightSFillIcon size={18} />
      </Button>
    </Container>
  );
};

export default PaginationControlsSingle;
