import React from "react";
import styled from "styled-components";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--notch-neutral-100, #ededf0);
  background: #fff;
  padding: 12px;
  gap: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
`;

const CompanyName = styled.div`
  color: var(--notch-neutral-900, #1c274a);

  /* Notch/Label/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.42px;
`;

const SectionB = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: center;
`;

const PushRight = styled.div`
  margin-left: auto;
`;

const JobTitle = styled.div`
  color: var(--notch-neutral-900, #1c274a);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.54px;
`;

const JobEntry: React.FC = (props) => {
  let data = props.data;

  return (
    <Container>
      <Row>
        <CompanyLogo
          size={48}
          src={data.company.logo ?? data.company.company_logo}
        />
        <SectionB>
          <JobTitle>{data.title}</JobTitle>
          <CompanyName>{data.company.company_name}</CompanyName>
        </SectionB>
      </Row>
      <Row>
        <PushRight />
        <Button variant={"outline"} size={"small"}>
          Hide \ Not Interested
        </Button>
        <Button variant={"primary"} size={"small"}>
          Apply as Recruiter
        </Button>
      </Row>
    </Container>
  );
};

export default JobEntry;
