import React from "react";
import styled from "styled-components";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import MainAndSideColumnsLayoutB, {
  MainColumn,
} from "src/Modules/Shared/Components/Layout/MainAndSideColumnsLayoutB";
import {
  PageTitle,
  PageTitleLine,
} from "src/Modules/Customer/Components/CommonCustoemerLib";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Field, Form } from "react-final-form";
import CurrentUserProfilePicture from "src/Modules/Shared/Components/Temp/CurrentUserProfilePicture";
import Dropzone from "react-dropzone";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import { FormLabel } from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import LocationInputField from "src/Modules/Shared/Components/Temp/LocationInputField";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import { timezoneOptions } from "src/Modules/Shared/Components/Temp/timezones";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import PhoneNumberField from "src/Modules/Shared/Components/Temp/PhoneNumberField";
import TabLinks from "src/Modules/Customer/Components/TabLinks";
import { NavLink, Outlet } from "react-router-dom";
import axiosInstance from "src/Modules/Core/Service/Api";

const Container = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  gap: 0;
`;

const Link = styled(NavLink)`
  padding: 6px 20px;
  margin-bottom: -18px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  z-index: 200;
  color: gray;

  color: var(--notch-neutral-400, #9fa4b3);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;

  &.active {
    border-bottom: 2px solid #105cf7;
    color: #105cf7;
  }
`;

const AccountPage: React.FC = (props) => {
  let queryClient = useQueryClient();

  const authQuery = useQuery({
    queryKey: ["auth"],
    queryFn: () => axiosInstance.get("/api/auth/me"),
    staleTime: Infinity,
    retry: false,
  });

  if (authQuery.isLoading) {
    return null;
  }

  let data = authQuery.data?.data;

  let initialData = data;

  return (
    <FluidContentContainer>
      <PageTitle>Account</PageTitle>
      <Container>
        <Link to={"/account/profile"}>Profile</Link>
        <Link to={"/account/profile2"}>Companies</Link>
        <Link to={"/account/profile3"}>Something else?</Link>
      </Container>
      <PageTitleLine />
      <Outlet />
    </FluidContentContainer>
  );
};

export default AccountPage;
