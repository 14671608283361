import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { s } from "@design/sprink";
import { NavLink } from "react-router-dom";
import { TalentSignUpPageContainerStyle } from "src/Modules/Shared/WipStyles.css";
import TalentFlowSidebar from "src/Modules/Shared/Pages/TalentFlowSidebar";
import TalentApplicationFlowHeader from "src/Modules/Shared/Components/Temp/TalentApplicationFlowHeader";
import TalentApplicationFlowForm from "src/Modules/Shared/Components/Temp/TalentApplicationFlowForm";
import ApplicationFlowAccount from "src/Modules/Shared/Components/Temp/ApplicationFlowAccount";
import CustomerFlowSidebar from "src/Modules/Shared/Pages/CustomerFlowSidebar";
import CustomerApplicationFlowHeader from "src/Modules/Shared/Components/Temp/CustomerApplicationFlowHeader";
import CustomerApplicationFlowForm from "src/Modules/Shared/Components/Temp/CustomerApplicationFlowForm";

const Container = styled.div``;

const Header = s("h1", {
  fontSize: "40",
  color: "gray900",
  fontFamily: "body",
  fontWeight: 600,
  letterSpacing: "-4%",
});

const UnderHeaderText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 24px;
`;

const Label = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #414a67;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 4px;
`;

const LabelStar = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
  margin-left: 2px;
`;

const TalentSignupFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 488px;
  width: 100%;
  padding: 0 16px;
  //border: 2px solid red;
  margin: 0 auto;
  grid-area: form;
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 8px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 6px;
  color: black;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  box-sizing: border-box;

  &:focus {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }
`;

const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const PasswordHideSee = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;

  right: 8px;
  top: 42px;
  z-index: 200;
`;

const PasswordHideSee2 = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;

  right: 8px;
  top: 42px;
  z-index: 200;
`;

const PolicyText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  letter-spacing: -0.03em;
  color: #858b9d;
  margin-bottom: 24px;

  a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.03em;

    /* Notch/Neutral/900 */

    color: #1c274a;
    display: inline;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SubmitButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  gap: 8px;

  width: 100%;
  height: 48px;

  /* Notch/Primary/Blue/600 */

  background: #105cf7;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.03em;

  color: #ffffff;

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      #105cf7;
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      #105cf7;
  }

  &:disabled {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      #105cf7;
    cursor: default;
  }
`;

const TalentSignupLayout = styled.div`
  display: grid;
  overflow-y: auto;
  grid-template-columns: 2% 96% 2%;
  grid-template-rows: 64px 80px 1fr minmax(20px, 1fr);
  grid-template-areas: "header header header" ". progress ." ". form ." ". . .";

  @media screen and (min-width: 660px) {
    grid-template-columns: 1fr 650px 1fr;
    grid-template-rows: 64px 80px 1fr minmax(20px, 1fr);
    grid-template-areas: "header header header" ". progress header" ". form ." ". . .";
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: 1fr 640px 1fr;
    grid-template-rows: 80px minmax(20px, 120px) 1fr minmax(20px, 1fr);
    grid-template-areas: ". progress header" ". . ." ". form ." ". . .";
  }

  @media screen and (min-width: 1700px) {
    grid-template-columns: 1fr 640px 1fr;
    grid-template-rows: 80px minmax(20px, 120px) 1fr minmax(20px, 1fr);
    grid-template-areas: ". progress header" ". . ." ". form ." ". . .";
  }
`;

const TalentSignupCalendlyLayout = styled.div`
  display: grid;
  overflow-y: auto;
  grid-template-columns: 2% 96% 2%;
  grid-template-rows: 64px 80px 1fr minmax(20px, 1fr);
  grid-template-areas: "header header header" ". progress ." ". form ." ". . .";

  @media screen and (min-width: 660px) {
    grid-template-columns: 1fr 650px 1fr;
    grid-template-rows: 64px 80px 1fr minmax(20px, 1fr);
    grid-template-areas: "header header header" ". progress header" ". form ." ". . .";
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: 1fr 640px 1fr;
    grid-template-rows: 80px minmax(20px, 120px) 1fr minmax(20px, 1fr);
    grid-template-areas: ". progress header" ". . ." ". form ." ". . .";
  }

  @media screen and (min-width: 1700px) {
    grid-template-columns: 1fr 640px 1fr;
    grid-template-rows: 80px minmax(20px, 120px) 1fr minmax(20px, 1fr);
    grid-template-areas: ". progress header" ". . ." ". form ." ". . .";
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  padding: 24px 32px;
  display: none;
  flex-direction: row;
  align-items: center;
  font-family: Inter;
  grid-area: header;

  @media screen and (min-width: 1280px) {
    display: flex;
  }
`;

const SignInButton = styled(NavLink)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 24px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: -0.03em;

  border: 1px solid #105cf7;
  border-radius: 6px;
`;

const CustomerFlowPage: React.FC = (props) => {
  let [step, setStep] = useState(2);

  useEffect(() => {
    let el = document.getElementById("af");

    if (el) {
      el.scrollTop = 0;
    }
  }, [step]);

  return (
    <Container>
      <div className={TalentSignUpPageContainerStyle}>
        <CustomerFlowSidebar />
        <TalentSignupLayout id="af">
          <ApplicationFlowAccount />
          <CustomerApplicationFlowHeader step={step} />
          <CustomerApplicationFlowForm step={step} setStep={setStep} />
        </TalentSignupLayout>
      </div>
    </Container>
  );
};

export default CustomerFlowPage;
