import React from "react";
import styled from "styled-components";
import Dropzone from "react-dropzone";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import { Field, FormSpy } from "react-final-form";
import { FormLabel } from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import TextareaField from "src/Modules/Shared/Components/Util/TextareaField";
import LocationInputField from "src/Modules/Shared/Components/Temp/LocationInputField";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import TextInputWithIconAny from "src/Modules/Customer/Components/TextInputWithIconAny";

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 18px;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  flex: 1 1 0;

  &.grow2 {
    flex: 2 1 0;
  }
`;

const InputIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 26px;
  height: 26px;

  margin-right: 8px;
`;

const OrganizationDetailsFormContent: React.FC = ({ form }) => {
  return (
    <>
      <FormField>
        <FormLabel>Company Name</FormLabel>
        <Field
          name={"name"}
          component={TextInputField}
          placeholder="Company name"
        />
      </FormField>

      <FormField>
        <FormLabel>URL</FormLabel>
        <Field
          name={"websiteUrl"}
          component={TextInputField}
          placeholder="Your company URL"
        />
      </FormField>
      <FormField>
        <FormLabel>Short Headline</FormLabel>
        <Field
          name={"headline"}
          component={TextareaField}
          maxLength={65}
          placeholder="Your company headline"
        />
      </FormField>

      <Row>
        <FormField>
          <FormLabel>Location</FormLabel>
          <Field
            name={"hqLocation"}
            component={LocationInputField}
            placeholder="Your company location"
          />
        </FormField>

        <FormField>
          <FormLabel>Remote Status</FormLabel>
          <Field
            name={"remoteStatus"}
            component={DefaultSelectField}
            placeholder="Your remote status"
            options={[
              { value: "Remote", label: "Remote" },
              { value: "Hybrid", label: "Hybrid" },
              { value: "On-site", label: "On-site" },
            ]}
          />
        </FormField>
      </Row>

      <Row>
        <FormField>
          <FormLabel>Company Size</FormLabel>
          <Field
            name={"size"}
            component={DefaultSelectField}
            placeholder="Your company size"
            options={[
              {
                value: "1 - 10",
                label: "1 - 10",
              },
              {
                value: "11 - 50",
                label: "11 - 50",
              },
              {
                value: "51 - 200",
                label: "51 - 200",
              },
              {
                value: "201 - 1000",
                label: "201 - 1000",
              },
              {
                value: "1000+",
                label: "1000+",
              },
            ]}
          />
        </FormField>

        <FormField>
          <FormLabel>Year Founded</FormLabel>
          <Field
            name={"foundedYear"}
            component={TextInputField}
            placeholder="Year founded"
          />
        </FormField>
      </Row>

      <Row>
        <FormField>
          <FormLabel>Total Funding</FormLabel>
          <Field
            name={"fundingTotal"}
            icon={
              <svg
                style={{ marginRight: 8 }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="#F8FAFF" />
                <path
                  d="M11.593 18.2727V5.54545H12.4083V18.2727H11.593ZM14.3373 9.36364C14.2776 8.85985 14.0357 8.46875 13.6114 8.19034C13.1872 7.91193 12.6668 7.77273 12.0503 7.77273C11.5996 7.77273 11.2052 7.84564 10.8671 7.99148C10.5323 8.13731 10.2705 8.33783 10.0816 8.59304C9.89598 8.84825 9.80318 9.13826 9.80318 9.46307C9.80318 9.73485 9.86781 9.96851 9.99707 10.1641C10.1296 10.3563 10.2987 10.517 10.5042 10.6463C10.7097 10.7723 10.9251 10.8767 11.1505 10.9595C11.3759 11.0391 11.583 11.1037 11.7719 11.1534L12.806 11.4318C13.0712 11.5014 13.3662 11.5975 13.691 11.7202C14.0191 11.8428 14.3323 12.0102 14.6306 12.2223C14.9322 12.4311 15.1808 12.6996 15.3763 13.0277C15.5719 13.3558 15.6697 13.7585 15.6697 14.2358C15.6697 14.786 15.5255 15.2831 15.2371 15.7273C14.9521 16.1714 14.5345 16.5244 13.9843 16.7862C13.4374 17.0481 12.7729 17.179 11.9907 17.179C11.2615 17.179 10.6301 17.0613 10.0965 16.826C9.5662 16.5907 9.14859 16.2625 8.84366 15.8416C8.54205 15.4207 8.37136 14.9318 8.33159 14.375H9.60431C9.63746 14.7595 9.76672 15.0777 9.9921 15.3295C10.2208 15.5781 10.5091 15.7637 10.8572 15.8864C11.2085 16.0057 11.5863 16.0653 11.9907 16.0653C12.4613 16.0653 12.8839 15.9891 13.2584 15.8366C13.633 15.6809 13.9296 15.4654 14.1483 15.1903C14.3671 14.9119 14.4765 14.5871 14.4765 14.2159C14.4765 13.8778 14.382 13.6027 14.1931 13.3906C14.0042 13.1785 13.7556 13.0062 13.4474 12.8736C13.1391 12.741 12.806 12.625 12.4481 12.5256L11.1952 12.1676C10.3998 11.9389 9.77003 11.6125 9.30602 11.1882C8.842 10.764 8.61 10.2088 8.61 9.52273C8.61 8.95265 8.76412 8.45549 9.07235 8.03125C9.38391 7.60369 9.80152 7.27225 10.3252 7.03693C10.8522 6.7983 11.4405 6.67898 12.0901 6.67898C12.7464 6.67898 13.3297 6.79664 13.8401 7.03196C14.3505 7.26397 14.7549 7.58215 15.0532 7.98651C15.3548 8.39086 15.5139 8.8499 15.5305 9.36364H14.3373Z"
                  fill="#105CF7"
                />
              </svg>
            }
            component={TextInputWithIconAny}
            placeholder="Total funding"
          />
        </FormField>

        <FormField>
          <FormLabel>Company Stage</FormLabel>
          <Field
            name="fundingStage"
            component={DefaultSelectField}
            isMulti={false}
            placeholder="Select the stage"
            options={[
              { label: "Early stage", value: "Early stage" },
              { label: "Seed stage", value: "Seed stage" },
              { label: "Series A", value: "Series A" },
              { label: "Series B", value: "Series B" },
              { label: "Series C", value: "Series C" },
              { label: "Late stage", value: "Late stage" },
              { label: "Growth stage", value: "Growth stage" },
              {
                label: "IPO / Public company",
                value: "IPO / Public company",
              },
              { label: "Pre-revenue", value: "Pre-revenue" },
              {
                label: "Bootstrapped / No funding",
                value: "Bootstrapped / No funding",
              },
              {
                label: "Accelerator / Incubator",
                value: "Accelerator / Incubator",
              },
            ]}
          />
        </FormField>
      </Row>

      <FormField>
        <FormLabel>Markets</FormLabel>
        <Field
          name="markets"
          component={DefaultSelectField}
          isMulti={true}
          placeholder="Select all the markets"
          options={[
            { label: "Analytics", value: "Analytics" },
            {
              label: "Finance and Accounting",
              value: "Finance and Accounting",
            },
            { label: "Human Resources", value: "Human Resources" },
            { label: "Infrastructure", value: "Infrastructure" },
            { label: "Legal", value: "Legal" },
            { label: "Office Management", value: "Office Management" },
            { label: "Operations", value: "Operations" },
            { label: "Productivity", value: "Productivity" },
            {
              label: "Recruiting and Talent",
              value: "Recruiting and Talent",
            },
            { label: "Retail", value: "Retail" },
            { label: "Sales & Marketing", value: "Sales & Marketing" },
            { label: "Security", value: "Security" },
            {
              label: "Supply Chain & logistics",
              value: "Supply Chain & logistics",
            },
            { label: "Education", value: "Education" },
            {
              label: "Apparel and Cosmetics",
              value: "Apparel and Cosmetics",
            },
            {
              label: "Consumer Electronics",
              value: "Consumer Electronics",
            },
            { label: "Content", value: "Content" },
            { label: "Food and Beverage", value: "Food and Beverage" },
            { label: "Gaming", value: "Gaming" },
            { label: "Home and Personal", value: "Home and Personal" },
            {
              label: "Job and Career Services",
              value: "Job and Career Services",
            },
            { label: "Social Media", value: "Social Media" },
            { label: "Transportation", value: "Transportation" },
            { label: "Travel", value: "Travel" },
            { label: "VR / AR", value: "VR / AR" },
            { label: "Asset Management", value: "Asset Management" },
            { label: "Banking", value: "Banking" },
            { label: "Consumer Finance", value: "Consumer Finance" },
            { label: "Credit & lending", value: "Credit & lending" },
            { label: "Insurance", value: "Insurance" },
            { label: "Payments", value: "Payments" },
            {
              label: "Health and Wellness",
              value: "Health and Wellness",
            },
            { label: "Diagnostics", value: "Diagnostics" },
            { label: "Healthcare", value: "Healthcare" },
            { label: "Construction", value: "Construction" },
            { label: "Real Estate", value: "Real Estate" },
            { label: "Agriculture", value: "Agriculture" },
            { label: "Automotive", value: "Automotive" },
            {
              label: "Aviation and Space",
              value: "Aviation and Space",
            },
            { label: "Climate", value: "Climate" },
            { label: "Drones", value: "Drones" },
            { label: "Energy", value: "Energy" },
            {
              label: "Manufacturing & Robotics",
              value: "Manufacturing & Robotics",
            },
            {
              label: "Sports & Entertainment",
              value: "Sports & Entertainment",
            },
            {
              label: "Crypto / Blockchain",
              value: "Crypto / Blockchain",
            },
            { label: "Big Data", value: "Big Data" },
            { label: "Collaboration", value: "Collaboration" },
            { label: "Developer tools", value: "Developer tools" },
            { label: "Communication", value: "Communication" },
            { label: "On-demand", value: "On-demand" },
            {
              label: "Artificial Intelligence (AI)",
              value: "Artificial Intelligence (AI)",
            },
            { label: "SEO", value: "SEO" },
            {
              label: "Business Intelligence",
              value: "Business Intelligence",
            },
            { label: "Digital media", value: "Digital media" },
          ]}
        />
      </FormField>

      <FormField>
        <FormLabel>Social Links</FormLabel>
        <Field
          name={"socialLinkedinUrl"}
          icon={
            <InputIcon>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.78329 4.16668C5.78306 4.6087 5.60726 5.03254 5.29454 5.34494C4.98182 5.65735 4.55781 5.83273 4.11578 5.83251C3.67376 5.83229 3.24992 5.65648 2.93752 5.34376C2.62511 5.03105 2.44973 4.60704 2.44995 4.16501C2.45017 3.72298 2.62598 3.29915 2.9387 2.98674C3.25141 2.67434 3.67542 2.49896 4.11745 2.49918C4.55948 2.4994 4.98331 2.6752 5.29572 2.98792C5.60812 3.30064 5.78351 3.72465 5.78329 4.16668V4.16668ZM5.83329 7.06668H2.49995V17.5H5.83329V7.06668ZM11.1 7.06668H7.78329V17.5H11.0666V12.025C11.0666 8.97501 15.0416 8.69168 15.0416 12.025V17.5H18.3333V10.8917C18.3333 5.75001 12.45 5.94168 11.0666 8.46668L11.1 7.06668V7.06668Z"
                  fill="#1C274A"
                />
              </svg>
            </InputIcon>
          }
          component={TextInputWithIconAny}
          placeholder="https://linkedin.com/"
        />
      </FormField>

      <FormField>
        <Field
          name={"socialTwitterUrl"}
          icon={
            <InputIcon>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_36_4622)">
                  <path
                    d="M18.4684 4.71333C17.8322 4.99474 17.1575 5.17956 16.4667 5.26167C17.1948 4.82619 17.7398 4.14084 18 3.33333C17.3167 3.74 16.5675 4.025 15.7867 4.17917C15.2622 3.61798 14.567 3.2458 13.8092 3.12049C13.0513 2.99517 12.2733 3.12374 11.5961 3.48621C10.9189 3.84868 10.3804 4.42474 10.0643 5.12483C9.74825 5.82492 9.67234 6.60982 9.84838 7.3575C8.46263 7.28804 7.10699 6.92794 5.86945 6.30055C4.63191 5.67317 3.54015 4.79254 2.66504 3.71583C2.35529 4.24788 2.19251 4.85269 2.19338 5.46833C2.19338 6.67667 2.80838 7.74417 3.74338 8.36917C3.19005 8.35175 2.6489 8.20232 2.16504 7.93333V7.97667C2.16521 8.78142 2.44369 9.56135 2.95325 10.1842C3.46282 10.8071 4.17211 11.2346 4.96088 11.3942C4.44722 11.5334 3.90863 11.5539 3.38588 11.4542C3.60827 12.1469 4.04172 12.7527 4.62554 13.1868C5.20936 13.6209 5.91432 13.8615 6.64171 13.875C5.91878 14.4428 5.09103 14.8625 4.20578 15.1101C3.32053 15.3578 2.39515 15.4285 1.48254 15.3183C3.07563 16.3429 4.93012 16.8868 6.82421 16.885C13.235 16.885 16.7409 11.5742 16.7409 6.96833C16.7409 6.81833 16.7367 6.66667 16.73 6.51833C17.4124 6.02514 18.0014 5.41418 18.4692 4.71417L18.4684 4.71333Z"
                    fill="#1C274A"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_36_4622">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </InputIcon>
          }
          component={TextInputWithIconAny}
          placeholder="https://twitter.com/"
        />
      </FormField>

      <FormField>
        <Field
          name={"socialFacebookUrl"}
          icon={
            <InputIcon>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_36_4628)">
                  <path
                    d="M9.99996 1.66669C5.39746 1.66669 1.66663 5.39752 1.66663 10C1.66663 14.1592 4.71413 17.6067 8.69829 18.2325V12.4084H6.58163V10H8.69829V8.16419C8.69829 6.07585 9.94163 4.92252 11.8458 4.92252C12.7575 4.92252 13.7108 5.08502 13.7108 5.08502V7.13502H12.6608C11.625 7.13502 11.3025 7.77752 11.3025 8.43669V10H13.6133L13.2441 12.4084H11.3025V18.2325C15.2858 17.6075 18.3333 14.1584 18.3333 10C18.3333 5.39752 14.6025 1.66669 9.99996 1.66669Z"
                    fill="#1C274A"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_36_4628">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </InputIcon>
          }
          component={TextInputWithIconAny}
          placeholder="https://facebook.com/"
        />
      </FormField>
    </>
  );
};

export default OrganizationDetailsFormContent;
