import React, { useState } from "react";
import styled from "styled-components";
import PopupOverlay from "src/Modules/Core/Components/Experimental/PopupOverlay";
import CompanyDetailsEditCard from "src/Modules/Customer/Components/CompanyDetailsEditCard";
import { PageTitleLine } from "src/Modules/Customer/Components/CommonCustoemerLib";
import { Field, Form } from "react-final-form";
import OrganizationSearchField from "src/Modules/Admin/Components/Pages/Jobs/OrganizationSearchField";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";

const Container = styled.div``;

const Message = styled.div`
  font-family: Inter;
  font-size: 14px;
  color: gray;
  cursor: pointer;

  &:hover {
    color: #105cf7;
  }
`;

const Card = styled.div`
  width: 760px;
  max-width: 94vw;

  padding: 24px;

  background: white;

  border-radius: 12px;

  position: relative;

  gap: 12px;
`;

export const FormLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 8px;

  display: flex;
  flex-direction: row;
  gap: 8px;

  span.optional {
    margin-left: 4px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.03em;
    color: #b8bcc7;
  }
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  flex: 1 1 0;

  &.grow2 {
    flex: 2 1 0;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;

  width: 100%;

  /* Notch/Neutral/50 */

  background: #fafafb;
  border-radius: 12px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

const CancelButton = styled.button`
  height: 48px;
  padding: 12px 32px;
  background: none;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
`;

const SaveButton = styled.button`
  height: 48px;
  padding: 12px 32px;
  background: #105cf7;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    fill: #ffffff;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: #ededf0;
    color: #9fa4b3;
    cursor: default;
    border: none;
    svg {
      fill: #9fa4b3;
    }
  }
`;

const CloseIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 24px;
  right: 24px;

  cursor: pointer;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 18px;
`;

const AttachJobToOrganizationAction: React.FC = (props) => {
  let [popup, setPopup] = useState(false);
  let queryClient = useQueryClient();
  let organizations = useQuery({
    queryKey: ["options", "organizations"],
    queryFn: () => axios.get("/api/options/organizations"),
  });

  let mutation = useMutation({
    mutationFn: BackofficeApi.jobs.id(props.jobId).attachOrganization,
    onSuccess: (data) => {
      setPopup(false);
      queryClient.invalidateQueries(["jobs"]);
    },
  });

  if (organizations.isLoading) {
    return null;
  }

  let options = organizations.data?.data?.data;

  return (
    <Container>
      <PopupOverlay active={popup} onClose={() => setPopup(false)}>
        {({ onClose }) => (
          <Card>
            <Form
              onSubmit={(values) => {
                mutation.mutate({
                  jobId: props.jobId,
                  organizationId: values.organization,
                });
              }}
              initialValues={{}}
              render={({ handleSubmit, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <CloseIcon
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_36_4546)">
                        <path
                          d="M16.0001 14.8217L20.1251 10.6967L21.3034 11.875L17.1784 16L21.3034 20.125L20.1251 21.3033L16.0001 17.1783L11.8751 21.3033L10.6968 20.125L14.8218 16L10.6968 11.875L11.8751 10.6967L16.0001 14.8217Z"
                          fill="#B8BCC7"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_36_4546">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(6 6)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </CloseIcon>
                  <Row>
                    <Title>Attach a job to a company</Title>
                  </Row>
                  <PageTitleLine />

                  <FormContainer>
                    <FormField>
                      <FormLabel>Organization</FormLabel>
                      <Field
                        name={"organization"}
                        component={DefaultSelectField}
                        options={options}
                      />
                    </FormField>

                    <Row>
                      <ButtonGroup>
                        <CancelButton
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Cancel
                        </CancelButton>
                        <SaveButton type="submit" disabled={mutation.isLoading}>
                          Attach
                        </SaveButton>
                      </ButtonGroup>
                    </Row>
                  </FormContainer>
                </form>
              )}
            />
          </Card>
        )}
      </PopupOverlay>
      <Message onClick={() => setPopup(true)}>No organization attached</Message>
    </Container>
  );
};

export default AttachJobToOrganizationAction;
