import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { broadcastQueryClient } from "@tanstack/query-broadcast-client-experimental";
import * as Sentry from "@sentry/react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      //  staleTime: 5*60*1000,
    },
  },
});
/*
broadcastQueryClient({
  queryClient,
  broadcastChannel: "appBroadcast",
});
*/

if (import.meta.env.MODE != "development") {
  Sentry.init({
    dsn: "https://9cacd875f15b2459a72ac1b4e2949e92@o994491.ingest.sentry.io/4505869005684736",
    integrations: [new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.05, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend: (event) => {
      return event;
    },
  });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <Sentry.ErrorBoundary
          fallback={<p style={{ color: "red" }}>There was an error</p>}
        >
          <App />
        </Sentry.ErrorBoundary>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);
