import React from "react";
import styled from "styled-components";
import { PageTitleLine } from "src/Modules/Customer/Components/CommonCustoemerLib";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import LeftCard from "src/Modules/Customer/Components/TalentProfile/LeftCard";
import Summary from "src/Modules/Customer/Components/TalentProfile/Summary";
import Skills from "src/Modules/Customer/Components/TalentProfile/Skills";
import WorkHistory from "src/Modules/Customer/Components/TalentProfile/WorkHistory";
import Education from "src/Modules/Customer/Components/TalentProfile/Education";
import Projects from "src/Modules/Customer/Components/TalentProfile/Projects";
import Certifications from "src/Modules/Customer/Components/TalentProfile/Certifications";
import Interests from "src/Modules/Customer/Components/TalentProfile/Interests";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";

const Container = styled.div``;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (min-width: 1280px) {
    display: grid;
    grid-template-columns: 320px 32px 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "left gap right";
    align-items: start;
    gap: 0;
  }
`;

const Left = styled.div`
  grid-area: left;
`;

const Right = styled.div`
  grid-area: right;
  display: flex;
  flex-direction: column;

  gap: 32px;
`;

const CandidateProfilePage: React.FC = (props) => {
  let params = useParams();
  let candidateId = params.candidateId;

  const info = useQuery(
    BackofficeApi.shortlistCandidates.id(candidateId).getProfile()
  );

  if (info.isLoading) {
    return null;
  }

  let data = info.data?.data;
  data = data.profile;

  return (
    <FluidContentContainer>
      <PageTitleLine />
      <Layout>
        <Left>
          <LeftCard data={data} />
        </Left>
        <Right>
          <Summary data={data} />
          <Skills data={data} />
          <WorkHistory data={data} />
          <Education data={data} />
          <Projects data={data} />
          <Certifications data={data} />
          <Interests data={data} />
        </Right>
      </Layout>
    </FluidContentContainer>
  );
};

export default CandidateProfilePage;
