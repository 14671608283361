import React from "react";
import styled from "styled-components";
import HomePageGreeting from "src/Modules/Customer/Pages/Home/HomePageGreeting";
import { PageTitleLine } from "src/Modules/Customer/Components/CommonCustoemerLib";
import MainAndSideColumnsLayout, {
  MainColumn,
  SideColumn,
} from "src/Modules/Shared/Components/Layout/MainAndSideColumnsLayout";
import Card from "src/Modules/Recruiter/Pages/Home/Card";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import arrayMutators from "final-form-arrays";
import { Form } from "react-final-form";
import ProfileEditForm from "src/Modules/Recruiter/Pages/Profile/ProfileEditForm";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";

const Container = styled.div``;

const HomeTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #105cf7;
  margin: 0;
`;

const RecruiterStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;
  margin-left: auto;

  border-radius: 8px;

  padding: 5px 18px;

  user-select: none;
`;

const UnverifiedStatus = styled(RecruiterStatus)`
  background: #f5f5f5;
  color: #18181b;
`;

const VerifiedStatus = styled(RecruiterStatus)`
  background: var(--notch-secondary-green-50, #e2faf2);
  color: var(--notch-secondary-green-600, #00c181);
`;

const Title = styled.div`
  color: var(--notch-neutral-900, #1c274a);

  /* Notch/Label/Large */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 177.778% */
  letter-spacing: -0.54px;
`;

const ProfilePage: React.FC = (props) => {
  const queryClient = useQueryClient();
  const profileQuery = useQuery({
    queryKey: ["recruiter", "profile"],
    queryFn: () => axios.get("/api/recruiters/profile"),
  });

  const profileMutation = useMutation({
    mutationFn: (values) => axios.post("/api/recruiters/profile", values),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["recruiter", "profile"]);
    },
  });

  const verifyMutation = useMutation({
    mutationFn: () => axios.post("/api/recruiters/verify"),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["recruiter", "profile"]);
    },
  });

  if (profileQuery.isLoading) {
    return null;
  }

  let data = profileQuery.data?.data;

  return (
    <FluidContentContainer>
      <HomeTitle>Your Recruiter Profile</HomeTitle>
      <PageTitleLine />
      <MainAndSideColumnsLayout>
        <MainColumn>
          <Form
            mutators={{ ...arrayMutators }}
            onSubmit={(values) => {
              profileMutation.mutate(values);
            }}
            initialValues={{
              firstName: data.firstName,
              lastName: data.lastName,
              bio: data.bio,
            }}
            render={({
              handleSubmit,
              values,
              form,
              dirty,
              modified,
              modifiedSinceLastSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  {data.status == "Unverified" && (
                    <UnverifiedStatus>Unverified</UnverifiedStatus>
                  )}
                  {data.status == "Verified" && (
                    <VerifiedStatus>Verified</VerifiedStatus>
                  )}
                  <ProfileEditForm />
                  <Button
                    variant="primary"
                    type="submit"
                    size="medium"
                    disabled={profileMutation.isLoading}
                  >
                    Save
                  </Button>
                </Card>
              </form>
            )}
          />
        </MainColumn>
        <SideColumn>
          <Card>
            {data.status == "Unverified" && (
              <>
                <Title>Verification request</Title>
                <Button
                  variant="primary"
                  size="large"
                  onClick={() => {
                    verifyMutation.mutate();
                  }}
                >
                  Request verification
                </Button>
              </>
            )}
          </Card>
        </SideColumn>
      </MainAndSideColumnsLayout>
    </FluidContentContainer>
  );
};

export default ProfilePage;
