import React, { useState } from "react";
import styled from "styled-components";
import Select, { OptionProps, components } from "react-select";
import { FieldRenderProps } from "react-final-form";
import { DefaultSelectStyle } from "src/Modules/Core/Components/Form/Select/DefaultSelectStyle";
import {
  DropdownIndicator,
  Option,
  ValueContainer,
} from "src/Modules/Core/Components/Form/Select/SelectComponents";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";
import axios from "axios";
import countries from "src/Modules/Shared/Components/Temp/countries";
import AsyncSelect from "react-select/async";
import { CircleFlag } from "react-circle-flags";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";

const Container = styled.div`
  width: 100%;
`;

type SelectFieldProps = FieldRenderProps<[], any>;

let debounce = null;

const CustomOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const Profile = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 999px;
  border: 1px solid #f1f5f9;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    font-weight: 500;
  }

  span {
    font-size: 12px;
    color: #6a7288;
  }
`;

const CustomOption = (props: OptionProps) => {
  return (
    <components.Option {...props}>
      <CustomOptionContainer>
        <Profile src={`${props.data.profileBase}`} />
        <Text>
          <label>{props.label}</label>
          <span>{props.data.email}</span>
        </Text>
      </CustomOptionContainer>
    </components.Option>
  );
};

const loadOptions = (
  inputValue: string,
  callback: (options: any[]) => void
) => {
  const query = {
    q: inputValue,
  };

  if (debounce != undefined) {
    window.clearTimeout(debounce);
  }

  const timeoutHandler = window.setTimeout(() => {
    BackofficeApi.searchCandidates(query.q).then((response) => {
      if (response.data?.status == "error") {
        alert(response.data.message);
        return;
      }

      if (response.data != undefined) {
        const options = response.data.data;
        callback(options);
      } else {
        callback([]);
      }
    });
  }, 700);

  debounce = timeoutHandler;
};

const CandidatesLegacySearch: React.FC<SelectFieldProps> = (props) => {
  return (
    <Container>
      <AsyncSelect
        closeMenuOnSelect={false}
        isMulti={false}
        hideSelectedOptions={false}
        components={{
          DropdownIndicator: DropdownIndicator,
          Option: CustomOption,
          ValueContainer: ValueContainer,
        }}
        styles={DefaultSelectStyle}
        placeholder={props.placeholder}
        isDisabled={props.disabled}
        loadOptions={loadOptions}
        {...props}
        {...props.input}
      />
    </Container>
  );
};

export default CandidatesLegacySearch;
