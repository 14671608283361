import React, { useState } from "react";
import styled from "styled-components";
import FileCopyFillIcon from "remixicon-react/FileCopyFillIcon";
import { vars } from "@design/Themes/defaultTheme.css";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import axios from "axios";
import PopupOverlay from "src/Modules/Core/Components/Experimental/PopupOverlay";
import DeleteBin2LineIcon from "remixicon-react/DeleteBin2LineIcon";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CustomerApi } from "src/Modules/Customer/Services/CustomerApi";
import { de } from "date-fns/locale";

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 200;
  width: 100vw;
  height: 100dvh;
  top: 0;
  left: 0;
  padding: 60px 8px;
  overflow-y: auto;
`;

const Card = styled.div`
  width: 544px;
  max-width: 96vw;

  padding: 24px;

  background: white;

  border-radius: 12px;

  position: relative;

  gap: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Action = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.div`
  width: 48px;
  aspect-ratio: 1;
`;

const Label = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1c274a;
  width: 100%;
`;

const JobTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #105cf7;

  padding: 4px 16px;

  background: #f8faff;
  border-radius: 6px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

type Props = {
  afterDuplicate: (newJob: object) => void;
  data: object;
};

const JobDeleteAction: React.FC<Props> = (props) => {
  const [popup, setPopup] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: CustomerApi.jobs.id(props.data.id).delete,
    onSuccess: () => {
      queryClient.invalidateQueries(["jobs"]);
      setPopup(false);
    },
  });

  return (
    <>
      <PopupOverlay active={popup} onClose={() => setPopup(false)}>
        {({ onClose }) => (
          <Card>
            <Icon>
              <DeleteBin2LineIcon size={42} color={"red"} />
            </Icon>
            <Label>Confirm deletion of the job</Label>
            <JobTitle>{props.data.title}</JobTitle>
            <ButtonGroup>
              <Button
                variant="outline"
                size="fullwidth"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              <Button
                disabled={deleteMutation.isLoading}
                variant="danger"
                size="fullwidth"
                onClick={() => {
                  deleteMutation.mutate();
                }}
              >
                Confirm
              </Button>
            </ButtonGroup>
          </Card>
        )}
      </PopupOverlay>

      <Action
        onClick={(event) => {
          setPopup(true);
        }}
      >
        <DeleteBin2LineIcon size={18} />
      </Action>
    </>
  );
};

export default JobDeleteAction;
