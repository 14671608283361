import React from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { NavLink } from "react-router-dom";
import JobStage from "src/Modules/Admin/Components/AdminTable/JobStage";
import LinksFillIcon from "remixicon-react/LinksFillIcon";
import ErrorWarningFillIcon from "remixicon-react/ErrorWarningFillIcon";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #ededf0;

  display: flex;
  flex-direction: column;

  gap: 12px;

  width: 450px;
  min-height: 200px;

  border-radius: 12px;

  padding: 16px;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const InReviewNavLink = styled(NavLink)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1c274a;
  gap: 4px;

  width: auto;
  padding: 8px 8px;

  border-radius: 6px;

  transition: 0.35s;

  &:hover {
    cursor: pointer;
    background: #f1f5f9;
  }
`;

const JobsOverviewCard: React.FC = (props) => {
  let searchParams = new URLSearchParams({
    page: "1",
    limit: "10",
    stage: "In Review",
  });

  const info = useQuery({
    ...BackofficeApi.jobs.list(searchParams),
    refetchInterval: 60 * 1000,
  });

  if (info.isLoading) {
    return null;
  }

  const totalResults = info.data?.data.totalResults;

  return (
    <Container>
      <Title>Jobs Overview</Title>
      <InReviewNavLink
        to={{
          pathname: "/jobs",
          search: new URLSearchParams({
            page: "1",
            limit: "10",
            stage: "In Review",
          }).toString(),
        }}
      >
        <ErrorWarningFillIcon size={18} color={"#7c3aed"} /> {totalResults} jobs
        are <JobStage stage={"In Review"} />
      </InReviewNavLink>
    </Container>
  );
};

export default JobsOverviewCard;
