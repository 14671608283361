import React from "react";
import styled from "styled-components";
import ErrorWarningFillIcon from "remixicon-react/ErrorWarningFillIcon";

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  display: flex;
  flex-direction: row;

  gap: 8px;
  font-family: Inter;
  font-size: 16px;
  color: #0f172a;
`;

const Body = styled.div``;

const PageNotFound: React.FC = (props) => {
  return (
    <Container>
      <Content>
        <Title>
          <ErrorWarningFillIcon />
          <span>We couldn't find the page</span>
        </Title>
      </Content>
    </Container>
  );
};

export default PageNotFound;
