import React, { useState } from "react";
import styled from "styled-components";
import {
  PageTitle,
  PageTitleLine,
} from "src/Modules/Customer/Components/CommonCustoemerLib";
import axios from "axios";
import { Field, Form } from "react-final-form";
import { FormLabel } from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import SelectField from "src/Modules/Shared/Components/Temp/SelectField";
import TextInputWithIcon from "src/Modules/Customer/Components/Jobs/TextInputWithIcon";
import TextInputWithIconAny from "src/Modules/Customer/Components/TextInputWithIconAny";
import LocationInputField from "src/Modules/Shared/Components/Temp/LocationInputField";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";
import TextareaField from "src/Modules/Shared/Components/Util/TextareaField";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";
import * as ExpField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import FileCopyFillIcon from "remixicon-react/FileCopyFillIcon";
import FileList2LineIcon from "remixicon-react/FileList2LineIcon";
import AddLineIcon from "remixicon-react/AddLineIcon";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import FileList3FillIcon from "remixicon-react/FileList3FillIcon";
import skillOptions from "src/Modules/Shared/Components/Temp/skillOptions";
import { BackofficeApi } from "src/Modules/Admin/Services/BackofficeApi";
import PopupOverlay from "src/Modules/Core/Components/Experimental/PopupOverlay";
import OrganizationEditPopup from "src/Modules/Admin/Pages/Organizations/OrganizationEditPopup";
import CustomerApplicationFlowOptions from "src/Modules/Shared/Components/Temp/CustomerApplicationFlowOptions";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: rgba(0, 0, 0, 0.5);

  position: fixed;
  z-index: 200;

  width: 100vw;
  height: 100dvh;

  top: 0;
  left: 0;

  padding: 60px 8px;

  overflow-y: auto;
`;

const Card = styled.div`
  width: 760px;
  max-width: 96vw;

  padding: 24px;

  background: white;

  border-radius: 12px;

  position: relative;

  gap: 12px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 18px;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  flex: 1 1 0;

  &.grow2 {
    flex: 2 1 0;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;

  width: 100%;

  /* Notch/Neutral/50 */

  background: #fafafb;
  border-radius: 12px;
`;

const InputIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 26px;
  height: 26px;

  margin-right: 8px;
`;

const CancelButton = styled.button`
  height: 48px;
  padding: 12px 32px;
  background: none;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
`;

const SaveButton = styled.button`
  height: 48px;
  padding: 12px 32px;
  background: #105cf7;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    fill: #ffffff;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: #ededf0;
    color: #9fa4b3;
    cursor: default;
    border: none;
    svg {
      fill: #9fa4b3;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

const CloseIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 24px;
  right: 24px;

  cursor: pointer;
`;

const Action = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: 0.3s;

  color: #9fa4b3;

  &:hover {
    color: #4f87f9;
    background: #fafafa;
    border-radius: 5px;
  }
`;

const Value = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: 8px;
  color: #105cf7;
`;

const getSelection = (value: any, options: any) => {
  return options.find((i: any) => {
    return i.value == value;
  });
};

const Section = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: bold;
  margin-top: 12px;
`;

const ViewCompanyDetailsAction: React.FC = (props) => {
  const [popup, setPopup] = useState(false);
  const queryClient = useQueryClient();
  let enabled = false;
  let companyId = props.id;

  let query = useQuery({
    ...BackofficeApi.organizations.get(companyId).single(),
    enabled: popup,
  });

  let ownerId = query.data?.data?.ownerAccount?.id;
  if (ownerId != undefined) {
    enabled = true;
  }

  let ownerQuery = useQuery({
    ...BackofficeApi.accounts.id(ownerId).get(),
    enabled: enabled,
  });

  let survey = query.data?.data.signupSurvey.data;
  let owner = ownerQuery.data?.data;

  return (
    <>
      <PopupOverlay active={popup} onClose={() => setPopup(false)}>
        {({ onClose }) => (
          <>
            {!query.isLoading && (
              <Card
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <CloseIcon
                  onClick={() => {
                    setPopup(false);
                  }}
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_36_4546)">
                      <path
                        d="M16.0001 14.8217L20.1251 10.6967L21.3034 11.875L17.1784 16L21.3034 20.125L20.1251 21.3033L16.0001 17.1783L11.8751 21.3033L10.6968 20.125L14.8218 16L10.6968 11.875L11.8751 10.6967L16.0001 14.8217Z"
                        fill="#B8BCC7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_36_4546">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(6 6)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </CloseIcon>
                <Row>
                  <Title>Application Details</Title>
                </Row>
                <PageTitleLine />
                <FormContainer>
                  <Section>Owner</Section>
                  <Row>
                    <FormField>
                      <FormLabel>Name</FormLabel>
                      <Value>{owner?.fullname}</Value>
                    </FormField>

                    <FormField>
                      <FormLabel>Email</FormLabel>
                      <Value>{owner?.email}</Value>
                    </FormField>

                    <FormField>
                      <FormLabel>Phone</FormLabel>
                      <Value>
                        <span>
                          {owner?.phoneNumber?.prefix}{" "}
                          {owner?.phoneNumber?.number}
                        </span>
                      </Value>
                    </FormField>
                  </Row>

                  <Section>Hiring Details</Section>
                  <Row>
                    <FormField>
                      <FormLabel>Roles</FormLabel>
                      <Value>
                        {survey.roles?.map((i) => {
                          return <span>{i} </span>;
                        })}
                      </Value>
                    </FormField>
                    <FormField>
                      <FormLabel>Skills</FormLabel>
                      <Value>
                        {survey.skills?.map((i) => {
                          return (
                            <span>{getSelection(i, skillOptions)?.label} </span>
                          );
                        })}
                      </Value>
                    </FormField>
                  </Row>

                  <FormField>
                    <FormLabel>Number of developers</FormLabel>
                    <Value>
                      <span>
                        {
                          getSelection(
                            survey.requiredDevelopersNumber,
                            CustomerApplicationFlowOptions.requiredDevelopersNumber
                          )?.label
                        }
                      </span>
                    </Value>
                  </FormField>

                  <Section>Role Requirements</Section>
                  <Row>
                    <FormField>
                      <FormLabel>Job Type</FormLabel>
                      <Value>
                        <span>
                          {
                            getSelection(
                              survey.jobType,
                              CustomerApplicationFlowOptions.jobType
                            )?.label
                          }
                        </span>
                      </Value>
                    </FormField>
                    <FormField>
                      <FormLabel>Hours</FormLabel>
                      <Value>
                        <span>
                          {
                            getSelection(
                              survey.hours,
                              CustomerApplicationFlowOptions.hours
                            )?.label
                          }
                        </span>
                      </Value>
                    </FormField>
                  </Row>

                  <Row>
                    <FormField>
                      <FormLabel>Start Date</FormLabel>
                      <Value>
                        <span>
                          {
                            getSelection(
                              survey.startDate,
                              CustomerApplicationFlowOptions.startDate
                            )?.label
                          }
                        </span>
                      </Value>
                    </FormField>

                    <FormField>
                      <FormLabel>Location Requirement</FormLabel>
                      <Value>
                        <span>
                          {
                            getSelection(
                              survey.locationRequirement,
                              CustomerApplicationFlowOptions.isLocationRequired
                            )?.label
                          }
                        </span>
                        {survey.regions?.map((i) => {
                          return <span>{i}</span>;
                        })}
                      </Value>
                    </FormField>
                  </Row>

                  <FormField>
                    <FormLabel>Looking to hire long-term</FormLabel>
                    <Value>
                      <span>
                        {
                          getSelection(
                            survey.longTerm,
                            CustomerApplicationFlowOptions.longTerm
                          )?.label
                        }
                      </span>
                      <span>
                        {
                          getSelection(
                            survey.expectedWorkPeriod,
                            CustomerApplicationFlowOptions.longTermOption
                          )?.label
                        }
                      </span>
                    </Value>
                  </FormField>

                  <Section>Company Details</Section>
                  <Row>
                    <FormField>
                      <FormLabel>Company Size</FormLabel>
                      <Value>
                        <span>
                          {
                            getSelection(
                              survey.companySize,
                              CustomerApplicationFlowOptions.companySize
                            )?.label
                          }
                        </span>
                      </Value>
                    </FormField>

                    <FormField>
                      <FormLabel>Company HQ</FormLabel>
                      <Value>
                        <span>{survey.hqLocation?.label}</span>
                      </Value>
                    </FormField>
                  </Row>
                </FormContainer>
              </Card>
            )}
          </>
        )}
      </PopupOverlay>
      <Action onClick={() => setPopup(true)}>
        <FileList3FillIcon />
      </Action>
    </>
  );
};

export default ViewCompanyDetailsAction;
